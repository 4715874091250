//#region ng
import {
  Component,
  inject,
  signal
} from '@angular/core';
//#endregion

//#region 3rd
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
//#endregion

//#region models
interface IVm {
  carrinho: ICarrinhoMap;
};
import { ICarrinhoMap } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_maps';
//#endregion

//#region services
import { CarrinhoService } from '../../_shared/_mercadeiro/_ng/_services';
//#endregion

//#region stores
import { AppCarrinhoStore } from '../../stores';
//#endregion

@Component({
  selector: 'app-carrinho-info',
  templateUrl: './carrinho-info.component.html',
  styleUrls: ['./carrinho-info.component.scss']
})
export class AppCarrinhoInfoComponent {
  //#region publics
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region injects
  #carrinhoServ = inject(CarrinhoService);
  #appCarrinhoStore = inject(AppCarrinhoStore);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.vm$.set(
      // this.#appCarrinhoStore.carrinhoStateChanged$
      this.#appCarrinhoStore.carrinhoItensStateChanged$
        .pipe(
          map((carrinho: ICarrinhoMap) => {
            // console.log(carrinho);
            const VM: IVm = {
              // carrinho: fixCarrinho(carrinho, loja),
              carrinho: !!carrinho ? this.#carrinhoServ.fix(carrinho) : null,
            };
            // console.log(VM);
            return VM;
          })
        )
    );
  }
  //#endregion
}
