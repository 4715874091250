//#region ng
import {
  Component,
  EventEmitter,
  Input,
  inject,
  signal,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
//#endregion

//#region models
import { CorFormValidation } from '../../../_shared/_core/_ng/_models/_classes';
import { IConta } from '../../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { FOCUS_TIMEOUT } from '../../../_shared/_core/_misc/_models/consts';
//#endregion

//#region libs
import {
  isFoneCel,
  isFoneFixo
} from '../../../_shared/_libs/_misc/_validations';
//#endregion

//#region services
import { ContasService } from '../../../_shared/_mercadeiro/_ng/_services';
//#endregion

//#region custom validators
class CustomValidator {
  static foneCelValid() {
    return (control: FormControl): ValidationErrors => {
      const FONE_CEL = (control.value || '');
      // console.log(FONE_CEL);
      if (FONE_CEL) {
        // console.log(isFoneCel(FONE_CEL));
        return isFoneCel(FONE_CEL) ? null : { foneCel: true };
      } else {
        return null;
      } // else
    }
  }

  static foneFixoValid() {
    return (control: FormControl): any => {
      const FONE_FIXO = (control.value || '');
      // console.log(FONE_FIXO);
      if (FONE_FIXO) {
        // console.log(isFoneFixo(FONE_FIXO));
        return isFoneFixo(FONE_FIXO) ? null : { foneFixo: true };
      } else {
        return null;
      } // else
    }
  }
}
//#endregion

@Component({
  selector: 'app-pedido-contato-form',
  templateUrl: './pedido-contato-form.component.html',
  styleUrls: ['./pedido-contato-form.component.scss']
})
export class AppPedidoContatoFormComponent {
  //#region inputs
  // isMobile
  @Input() isMobile: boolean = false;
  // conta
  #conta: IConta;
  get conta(): IConta { return this.#conta; }
  @Input({ required: true }) set conta(val: IConta) {
    this.#conta = !!val ? this.#contasServ.fix(val) : null;
    this.pedidoContatoForm.set(
      this.#fb.group({
        contato: this.#fb.group({
          nome: [val?.nome?.nome || '', [Validators.required]],
          fones: this.#fb.group({
            celular: [val?.fones?.celular || '', [Validators.required, CustomValidator.foneCelValid()]],
            fixo: [val?.fones?.fixo || '', [CustomValidator.foneFixoValid()]],
          }),
        })
      })
    );
  }
  //#endregion

  //#region Controls getters
  get nomeRef(): AbstractControl { return this.pedidoContatoForm()?.get('contato.nome'); }
  get foneCelRef(): AbstractControl { return this.pedidoContatoForm()?.get('contato.fones.celular'); }
  get foneFixoRef(): AbstractControl { return this.pedidoContatoForm()?.get('contato.fones.fixo'); }
  //#endregion

  //#region publics
  pedidoContatoForm = signal<FormGroup>(null);
  fv = signal<CorFormValidation>(null);
  //#endregion

  //#region events
  nomeFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>()).asReadonly();
  //#endregion

  //#region injects
  #contasServ = inject(ContasService);
  #fb = inject(FormBuilder);
  //#endregion

  //#region constructor
  constructor() {
    this.fv.set(new CorFormValidation());
  }
  //#endregion

  //#region lifecycles
  ngAfterViewInit() {
    this.focus();
  }
  //#endregion

  //#region functions
  focus() {
    !this.isMobile && setTimeout(
      () => this.nomeFocusEvent$().emit(true),
      FOCUS_TIMEOUT
    );
  }
  //#endregion
}
