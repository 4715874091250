//#region ng
import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
//#endregion

//#region models
import { IProduto } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
//#endregion

//#region libs
import { calcQtdeFracionado } from '../../_shared/_mercadeiro/_misc/_libs';
//#endregion

@Component({
  selector: 'app-produto-btn-comprar',
  templateUrl: './produto-btn-comprar.component.html',
  styleUrls: ['./produto-btn-comprar.component.scss']
})
export class AppProdutoBtnComprarComponent {
  //#region inputs
  // produto
  @Input({ required: true }) produto: IProduto;
  // light
  @Input() light: boolean = false;
  //#endregion

  //#region outputs
  @Output() change$ = new EventEmitter<number>();
  //#endregion

  //#region methods
  async onQtdeProdutoClick(
    produto: IProduto,
    step: number = 1
  ) {
    // console.log(item, qtde);
    if (!!produto) {
      // const QTDE: number = calcQtdeFracionado(produto, step);
      // console.log(qtde);
      // this.qtdeUn = qtde;
      // console.log(this.qtdeUn);
      // this.change$.emit(Number(QTDE.toFixed(3)) || 0);
      this.change$.emit(step);
    } // if
  }
  //#endregion
}
