<div *ngIf="!!horarioForm()">
  <div *ngIf="!!isRetirada; else isEntrega">
    <h4 class="mb-2 text-center">
      Quando você pode retirar seu pedido na loja?
    </h4>

    <div class="row">
      <div class="col-12 col-sm-8 offset-sm-2">
        <div class="alert alert-secondary text-center"
             role="alert">
          Seu pedido estará disponível para retirada {{ tempoEstimadoSeparacaoRetirada()?.caption }} dentro dos horários
          de funcionamento da loja.
        </div>

        <div class="text-center">
          <app-loja-horarios [horarios]="loja()?.horarios?.funcionamento" />
        </div>
      </div>
    </div>

  </div>
  <ng-template #isEntrega>
    <div *ngIf="!!semHorario(); else selHorario">
      <h4 class="mb-2 text-center">
        Quando você vai receber seu pedido?
      </h4>

      <div class="row">
        <div class="col-12 col-sm-6 offset-sm-3">
          <div class="alert alert-secondary text-center"
               role="alert">
            A entrega será realizada pelo próximo entregador disponível, dentro do horário de funcionamento da
            loja.
          </div>

          <div class="text-center">
            <app-loja-horarios [horarios]="loja()?.horarios?.funcionamento" />
          </div>
        </div>
      </div>
    </div>
    <ng-template #selHorario>
      <h4 class="mb-2 text-center">
        Quando você prefere receber seu pedido?
      </h4>

      <p>
        <strong> Horários para entrega </strong>
      </p>

      <ul class="dias">
        <li class="dia pointer no-select"
            [class.active]="diaSel()?.id === d?.id"
            *ngFor="let d of dias(); trackBy:tid_dia"
            (click)="diaSel.set(d)">
          <strong>{{ d?.__legenda }}</strong>
          <small class="o-70">{{ d?.__dia }} / {{ mesesNomeCurto()[d?.__mes ] }}</small>
      </ul>

      <mat-radio-group class="d-flex flex-column"
                       [(ngModel)]="idHoraSel">
        <mat-radio-button [value]="h?.id"
                          [name]="h?.id"
                          class="mb-3"
                          *ngFor="let h of diaSel()?.__horarios; trackBy:tid_hor"
                          (click)="onHoraClick(h)">
          <div [class.strong]="h?.id === idHoraSel">
            <small class="o-50 me-2">Início</small> {{ h?.inicio | min2time }} &nbsp;&bullet;&nbsp;<small
                   class="o-50 me-2">Final</small> {{ h?.final | min2time }}
          </div>
        </mat-radio-button>
      </mat-radio-group>
    </ng-template>
  </ng-template>
</div>

