<ng-container *ngIf="vm$() | async as vm">
  <!-- <p>vm {{ vm | json }}</p> -->

  <div *ngIf="!!vm?.enderecos">
    <div [ngSwitch]="tab()">
      <div *ngSwitchCase="'add'">
        <mat-toolbar class="d-flex justify-content-between">
          <div (click)="tab.set('enderecos')"
               class="pointer">
            <span class="icon-r-chevron-l me-2"></span><span class="o-50"
                  *ngIf="!isMobile">Voltar</span>
          </div>

          Adicionando endereço
        </mat-toolbar>

        <div class="mt-3">
          <cor-mat-manage-endereco [isMobile]="isMobile"
                                   [endereco]="null"
                                   submitCaption="CONFIRMAR"
                                   (submit$)="onAddEndereco($event)" />
        </div>
      </div>

      <div *ngSwitchDefault>
        <ul class="enderecos">
          <li *ngFor="let e of vm?.enderecos; trackBy:tid_end"
              class="d-flex flex-column"
              [class.active]="e?.id === idSel"
              [class.off]="!e?.__atendido">

            <div class="header d-flex justify-content-between">
              <div class="my-auto flex-1">
                <small class="me-auto flex-1">
                  <span class="icon-o-home o-70"
                        *ngIf="e?.apelido === 'casa'"></span>
                  <span class="icon-o-coffee o-70"
                        *ngIf="e?.apelido === 'trabalho'"></span>
                  {{ e?.apelido }}
                </small>
              </div>

              <div>
                <button mat-icon-button
                        matTooltip="Muda endereço favorito"
                        [disabled]="e?.__favorito"
                        (click)="onFavoritarEnderecoClick(e)">
                  <span [ngClass]="{
                  'icon-o-heart-2': !e?.__favorito,
                  'icon-o-heart-full-2': e?.__favorito
                }"></span>
                </button>

                <button mat-icon-button
                        matTooltip="Apaga endereço"
                        [disabled]="e?.__favorito"
                        (click)="onDelEnderecoClick(e)">
                  <span class="icon-o-trashbin"></span>
                </button>
              </div>
            </div>

            <div class="body d-flex flex-1">
              <p class="m-auto p-4 text-center">
                <strong>
                  {{ e?.logradouro }}, {{ e?.nro }} {{ e?.complemento }} <!-- {{ e?.id }} -->
                </strong>
                <br>
                {{ e?.cep | cep }} - {{ e?.bairro }}
                <br>
                {{ e?.localidade }}/{{ e?.uf }}
              </p>
            </div>

            <div class="footer d-flex">
              <div *ngIf="!e?.__atendido; else selecionavel"
                   class="d-flex flex-1">
                <strong class="m-auto danger tint">NÃO ATENDIDO</strong>
              </div>
              <ng-template #selecionavel
                           class="d-flex">
                <button mat-flat-button
                        color="success"
                        class="flex-1"
                        type="button"
                        (click)="onSelEnderecoClick(e)">
                  SELECIONAR
                </button>
              </ng-template>
            </div>
          </li>

          <li class="novo pointer text-center d-flex align-items-center justify-content-center"
              (click)="tab.set('add')">
            <div>
              <span class="icon-r-plus rem-30 o-20 mb-4"></span>
              <br>
              NOVO ENDEREÇO
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-container>
