<div class="modal-container d-flex flex-column">
  <mat-toolbar class="header"
               color="primary">
    <mat-toolbar-row class="d-flex justify-content-between">
      <app-conta-actions (logout$)="onLogoutClick()" />

      <div class="icon-area my-auto">
        <button mat-icon-button
                mat-dialog-close
                (click)="onMenuCloseClick()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-toolbar-row>

    <mat-toolbar-row class="sub">
      <div class="py-2 w-100">
        <app-endereco-info (change$)="onTrocarEnderecoClick()" />
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="no-padding flex-1">
    <ul class="list-group">
      <li class="list-group-item pointer"
          mat-dialog-close
          [routerLink]="[
            '/loja',
            loja?.id,
            !!isRetirada ? 'true' : 'false'
          ]"
          (click)="onMenuCloseClick()"
          *ngIf="!!loja">
        <span class="icon-o-home o-30 me-3"></span>
        Início
      </li>

      <li class="list-group-item bg-whites d-flex pointer"
          [matMenuTriggerFor]="deptosMenu"
          #dmt="matMenuTrigger"
          *ngIf="departamentos">
        <span>
          <span class="icon-o-grid-small o-30 me-3"></span>
          Departamentos
        </span>

        <span class="flex-1"></span>

        <span>
          <span class="o-30"
                [ngClass]="{ 'icon-r-chevron-r': !dmt?.menuOpen, 'icon-r-chevron-u': dmt?.menuOpen }">
          </span>
        </span>
      </li>

      <li class="list-group-item bg-whites d-flex pointer"
          [matMenuTriggerFor]="entregaMenu"
          #emt="matMenuTrigger"
          *ngIf="departamentos">
        <span>
          <span class="o-30 me-3"
                [class.icon-o-shopping-basket]="!!isRetirada"
                [class.icon-o-truck]="!isRetirada">
          </span>
          {{ !!isRetirada ? 'Retirada' : 'Entrega' }}
        </span>

        <span class="flex-1"></span>

        <span>
          <span class="o-30"
                [ngClass]="{ 'icon-r-chevron-r': !emt?.menuOpen, 'icon-r-chevron-u': emt?.menuOpen }">
          </span>
        </span>
      </li>

      <li class="list-group-item bg-whites d-flex pointer"
          [matMenuTriggerFor]="suaContaMenu"
          #scmt="matMenuTrigger"
          *ngIf="conta">
        <span>
          <span class="icon-r-person o-30 me-3"></span>
          Sua conta
        </span>

        <div class="flex-1"></div>

        <span>
          <span class="o-30"
                [ngClass]="{
            'icon-r-chevron-r': !scmt?.menuOpen,
            'icon-r-chevron-u': scmt?.menuOpen
          }">
          </span>
        </span>
      </li>

      <li class="list-group-item pointer"
          routerLink="/procura"
          (click)="onMenuCloseClick()">
        <span class="icon-r-search o-30 me-3"></span>
        Procura
      </li>
    </ul>
  </div>

  <div class="toolbar-footer d-flex">
    <div class="m-auto w-100">
      <mat-toolbar color="primary">
        <div class="d-flex text-center my-auto">
          <div class="m-auto pointer">
            <app-loja-info (change$)="onTrocarLojaClick()">
            </app-loja-info>
          </div>
        </div>
      </mat-toolbar>
    </div>
  </div>
</div>

<!-- Início menus -->
<mat-menu #deptosMenu="matMenu"
          yPosition="below">
  <button mat-menu-item
          routerLink="/departamentos">
    [ VER TODOS ]
  </button>
  <div *ngFor="let d1 of departamentos(); trackBy:tid_dep">
    <div *ngIf="d1._subs.length; else noD2">
      <button mat-menu-item
              [matMenuTriggerFor]="D2menu">
        {{ d1?.nome }}
      </button>
    </div>
    <ng-template #noD2>
      <button mat-menu-item>
        {{ d1?.nome }}
      </button>
    </ng-template>

    <mat-menu #D2menu="matMenu">
      <button mat-menu-item
              routerLink="/departamentos"
              [queryParams]="{ id: d1?.id }">
        [ VER TODOS ]
      </button>
      <div *ngFor="let d2 of d1?._subs; trackBy:tid_dep">
        <div *ngIf="d2._subs.length; else noD3">
          <button mat-menu-item
                  [matMenuTriggerFor]="D3menu">
            {{ d2?.nome }}
          </button>
        </div>
        <ng-template #noD3>
          <button mat-menu-item
                  routerLink="/departamentos"
                  [queryParams]="{ id: d2?.id }">
            {{ d2?.nome }}
          </button>
        </ng-template>

        <mat-menu #D3menu="matMenu">
          <button mat-menu-item
                  routerLink="/departamentos"
                  [queryParams]="{ id: d2?.id }">
            [ VER TODOS ]
          </button>
          <button *ngFor="let d3 of d2?._subs; trackBy:tid_dep"
                  mat-menu-item
                  routerLink="/departamentos"
                  [queryParams]="{ id: d3?.id }">
            {{ d3?.nome }}
          </button>
        </mat-menu>
      </div>
    </mat-menu>
  </div>
</mat-menu>

<mat-menu #entregaMenu="matMenu"
          yPosition="below">
  <button mat-menu-item
          [disabled]="!isRetirada"
          routerDirection="root"
          (click)="onMenuCloseClick()"
          [routerLink]="['/loja', loja?.id, 'false']"
          *ngIf="loja?.entrega?.status?.includes('E')">
    <span class="o-30 me-3 icon-o-truck"></span>
    Entrega <small class="o-80"
           *ngIf="!!loja?.pedidos?.minimo?.entrega">
      ( pedido mínimo {{ loja?.pedidos?.minimo?.entrega | currency: "BRL":"symbol":"1.2-2" }} )
    </small>
  </button>

  <button mat-menu-item
          [disabled]="!!isRetirada"
          routerDirection="root"
          (click)="onMenuCloseClick()"
          [routerLink]="['/loja', loja?.id, 'true']"
          *ngIf="loja?.entrega?.status?.includes('R')">
    <span class="o-30 me-3 icon-o-shopping-basket"></span>
    Retirada <small class="o-80"
           *ngIf="!!loja?.pedidos?.minimo?.retirada">
      ( pedido mínimo {{ loja?.pedidos?.minimo?.retirada | currency: "BRL":"symbol":"1.2-2" }} )
    </small>
  </button>
</mat-menu>

<mat-menu #suaContaMenu="matMenu">
  <button mat-menu-item
          routerLinkActive="active"
          routerDirection="root"
          [routerLink]="rota.url"
          *ngFor="let rota of rotas(); trackBy:tid_rot">
    <span class="icon {{ rota?.icon }} o-50"></span>&nbsp;
    {{ rota?.caption }}
  </button>

  <mat-divider></mat-divider>

  <button mat-menu-item
          (click)="onLogoutClick()">
    <span class="icon icon-r-import o-50"></span>&nbsp;
    Sair da conta
  </button>
</mat-menu>
<!-- Final menus -->