export * from './acesso-negado/acesso-negado.page';
export * from './carrinho-pendente/carrinho-pendente.page';
export * from './departamentos/departamentos.page';
export * from './loja/loja.page';
export * from './lojas/lojas.page';
export * from './onde/onde.page';
export * from './pedido/pedido.page';
export * from './procura/procura.page';
export * from './sua-conta/sua-conta.page';
export * from './not-found/not-found.page';
