<div class="endereco text-center d-flex"
     *ngIf="endereco">
  <!-- <div class="icon-pin o-50 my-auto"></div> -->

  <div class="me-auto">
    <div *ngIf="!!endereco(); else semEndereco">
      <span class="icon-o-home o-50"
            *ngIf="endereco()?.apelido === 'casa'">
      </span>
      <span class="icon-o-coffee o-50"
            *ngIf="endereco()?.apelido === 'trabalho'">
      </span>
      <span class="icon-ob-pin o-50"
            *ngIf="!endereco()?.apelido">
      </span>
      <small class="text-nowrap">
        {{ endereco()?.logradouro }}, {{ endereco()?.nro }} {{ endereco()?.complemento }}
      </small>

      <span *ngIf="showChange">
        <!-- <span class="mx-2">&bullet;</span> -->
        <span class="o-50 underline pointer ms-2"
              (click)="change$?.emit()"
              *ngIf="!!canChange">
          trocar
        </span>
      </span>
    </div>
    <ng-template #semEndereco>
      <span class="d-flex">
        <button mat-button
                class="flex-1"
                (click)="change$?.emit()">
          <span class="icon-ob-pin o-50"></span>&nbsp;
          <span> SELECIONAR SEU ENDEREÇO</span>
        </button>
      </span>
    </ng-template>
  </div>
</div>
