<ng-container *ngIf="vm$() | async as vm">
  <div class="segment my-3">
    <ul class="nav nav-pills justify-content-center"
        *ngIf="vm?.loja?.entrega?.status?.includes('E')">
      <li class="nav-item pointer"
          (click)="isRetiradaAction$().next(false)">
        <a class="nav-link"
           [class.active]="!vm?.isRetirada">
          {{ vm?.captions?.entrega }}
        </a>
      </li>

      <li class="nav-item pointer"
          (click)="isRetiradaAction$().next(true)"
          *ngIf="vm?.loja?.entrega?.status?.includes('R')">
        <a class="nav-link"
           [class.active]="!!vm?.isRetirada">
          {{ vm?.captions?.retirada }}
        </a>
      </li>
    </ul>
  </div>

  <div *ngIf="percLimiteRef?.value < 1; else limiteIsOk">
    <div class="d-flex flex-column">
      <div class="alert alert-light text-center"
           role="alert">
        <span class="rem-16 mt-5">
          Limite mínimo do pedido não atingido.
        </span>

        <div class="w3-light-grey w3-large w3-round-xlarge my-2">
          <div class="w3-container w3-padding w3-round-xlarge w3-red w3-center"
               style="width:{{percLimiteRef?.value * 100}}%">{{ percLimiteRef?.value * 100 | number : '1.2-2' }}%</div>
        </div>

        <button mat-flat-button
                class="btn-voltar"
                (click)="goHome$?.emit()">
          <span class="icon-r-chevron-l o-50 me-2"></span> Continuar comprando
        </button>
      </div>
    </div>
  </div>
  <ng-template #limiteIsOk>
    <div [ngSwitch]="vm?.isRetirada">
      <div *ngSwitchCase="true">
        <div class="p-2">
          <form novalidate
                [formGroup]="retiradaEntregaForm()"
                *ngIf="!!retiradaEntregaForm()">
            <div class="p-2 text-center">
              <strong>{{ vm?.loja?.nome }}</strong>
              <br>
              {{ vm?.loja?.endereco?.bairro }}
              <br>
              <p>
                <span class="o-50">{{ vm?.loja?.endereco?.localidade }} - {{ vm?.loja?.endereco?.uf }}</span>
                <br>
                <span class="o-50">
                  {{ vm?.loja?.endereco?.logradouro }}, {{ vm?.loja?.endereco?.nro }} {{ vm?.loja?.endereco?.complemento
                  }}
                </span>
              </p>

              <img [src]="vm?.loja?.__img?.thumb"
                   [alt]="vm?.loja?.nome"
                   class="thumb my-3 img-fluid"
                   *ngIf="!!vm?.loja?.__img?.status">
              <br />

              <section>
                <div class="form-check text-start">
                  <input class="form-check-input"
                         type="checkbox"
                         id="confirmaRetirada"
                         formControlName="confirmado">
                  <label class="form-check-label"
                         for="confirmaRetirada">
                    Ao continuar você concorda em retirar o pedido nesse endereço.
                  </label>
                </div>
              </section>
            </div>
          </form>
        </div>
      </div>

      <div *ngSwitchDefault>
        <div class="p-2">
          <app-conta-enderecos [idSel]="enderecoRef?.value?.id"
                               [isMobile]="vm?.isMobile"
                               [conta]="vm?.conta"
                               [loja]="vm?.loja"
                               (select$)="appEnderecosStore?.setState($event)" />
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>