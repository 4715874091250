//#region ng
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
  inject,
  signal
} from '@angular/core';
//#endregion

//#region mat
import { MatStepper } from '@angular/material/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
//#endregion

//#region firebase
import { getApp } from 'firebase/app';
import {
  getFunctions,
  httpsCallable
} from 'firebase/functions';
//#endregion

//#region 3rd
import { Subscription } from 'rxjs';
import {
  finalize,
  first,
} from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
//#endregion

//#region models
const STEPS: string[] = ['dadosPessoais', 'contato', 'endereco'];
import { environment } from 'src/environments/environment';
import { IConta } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { ICorFbUserApi } from '../../_shared/_core/_misc/_models/_interfaces/_apis';
// import { IModalRet } from '../../_shared/_core/_misc/_models/_interfaces/_rets';
import { ICompletarCadastroModalRet } from '../../models/interfaces/rets';
//#endregion

//#region services
import { ContasService } from '../../_shared/_mercadeiro/_ng/_services';
import {
  CorFbUserService,
  CorLoaderService,
  CorMessagesService
} from '../../_shared/_core/_ng/_services';
//#endregion

//#region components
import {
  AppContaContatoFormComponent,
  AppContaDadosPessoaisFormComponent
} from '../../components';
import { CorMatManageEnderecoComponent } from '../../_shared/_core/_ng-ui/_mat';
import { FOCUS_TIMEOUT } from 'src/app/_shared/_core/_misc/_models/consts';
//#endregion

@Component({
  selector: 'app-completar-cadastro-modal',
  templateUrl: './completar-cadastro.modal.html',
  styleUrls: ['./completar-cadastro.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppCompletarCadastroModal {
  //#region viewchilds
  @ViewChild(CorMatManageEnderecoComponent) manageEnderecoRef: CorMatManageEnderecoComponent;
  // dadosPessoaisFormRef
  @ViewChild(AppContaDadosPessoaisFormComponent) dadosPessoaisFormRef: AppContaDadosPessoaisFormComponent;
  // contatoFormRef
  @ViewChild(AppContaContatoFormComponent) contatoFormRef: AppContaContatoFormComponent;
  // stepper
  @ViewChild('stepper', { static: true }) stepperRef: MatStepper;
  //#endregion

  //#region inputs
  // caption
  @Input() caption: string;
  // idModal
  @Input() idModal: number;
  // isMobile
  @Input() isMobile: boolean = false;
  // user
  // @Input() user: ICorFbUserApi;
  #user: ICorFbUserApi;
  get user(): ICorFbUserApi { return this.#user; }
  @Input({ required: true }) set user(val: ICorFbUserApi) {
    this.#user = !!val ? this.#userServ.fix(val) : null;
    this.conta.set(this.#contasServ.fix(null, val));
  }
  //#endregion

  //#region publics
  changes = signal<Partial<IConta>>(null);
  conta = signal<IConta>(null);
  dataNasc = signal<any>(null);
  modalRet = signal<ICompletarCadastroModalRet>(null);
  //#endregion

  //#region injects
  #bsModalServ = inject(BsModalService);
  #contasServ = inject(ContasService);
  #loaderServ = inject(CorLoaderService);
  #msgServ = inject(CorMessagesService);
  #snackBar = inject(MatSnackBar);
  #userServ = inject(CorFbUserService);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.#msgServ.send();
  }
  //#endregion

  //#region methods
  onModalCloseClick(data: IConta = null) {
    // console.log(this.idModal);
    this.modalRet.set({ conta: data });
    this.#bsModalServ.hide(this.idModal);
  }

  onStepChanged(e: any) {
    // console.log(e);
    switch (+e?.selectedIndex) {
      case 0:
        this.dadosPessoaisFormRef?.focus();
        break;

      case 1:
        this.contatoFormRef?.focus();
        break;

      case 2:
        this.manageEnderecoRef?.focus();
        break;

      case 3:
        // this.conta = this.#contasServ.fix(this.conta, this.user);
        const CONTA_ID: string = this.user?.uid || '';
        // forms
        const PESSOA_FISICA: boolean = !!this.dadosPessoaisFormRef?.pessoaFisica();
        const DADOS_PESSOAIS_FORM: any = this.dadosPessoaisFormRef?.dadosPessoaisForm();
        const CONTATO_FORM: any = this.contatoFormRef?.contatoForm();
        const ENDERECO_FORM: any = this.manageEnderecoRef?.enderecoFormRef?.enderecoForm();
        // fields
        const NASCIMENTO: any = DADOS_PESSOAIS_FORM?.get('nascimento')?.value;
        // console.log(NASCIMENTO);
        if (!!PESSOA_FISICA) {
          this.dataNasc.set(NASCIMENTO.toDate());
        } // if
        // console.log(this.dataNasc);
        const NASCIMENTO_STR: string = !!NASCIMENTO ? NASCIMENTO.format('YYYY-MM-DD') : '';
        const EMAIL: string = CONTATO_FORM?.get('email')?.value || '';
        this.changes.set(
          {
            cpf: !!PESSOA_FISICA ? DADOS_PESSOAIS_FORM.get('cpf')?.value || '' : '',
            cnpj: !!PESSOA_FISICA ? '' : DADOS_PESSOAIS_FORM?.get('cnpj')?.value || '',
            email: EMAIL,
            endereco: ENDERECO_FORM?.value,
            fones: {
              celular: CONTATO_FORM?.get('fones.celular')?.value || '',
              fixo: CONTATO_FORM?.get('fones.fixo')?.value || '',
              mensagens: CONTATO_FORM?.get('fones.mensagens')?.value || '',
            },
            id: CONTA_ID,
            // nascimento: '19711212',
            nascimento: !!PESSOA_FISICA ? NASCIMENTO_STR : '',
            nome: {
              nome: DADOS_PESSOAIS_FORM?.get('nome.nome')?.value,
              sobrenome: !!PESSOA_FISICA ? DADOS_PESSOAIS_FORM?.get('nome.sobrenome')?.value : '',
            },
            operador: null,
            sexo: !!PESSOA_FISICA ? DADOS_PESSOAIS_FORM?.get('sexo')?.value : '',
            _user: this.conta()?._user
          }
        );
        // console.log(CHANGES);
        break;
    } // switch
  }

  async onConfirmarClick() {
    // console.log(this.user);
    // this.changes.cnpj = '';
    // this.changes.cpf = '';
    // console.log(this.changes);
    const CONTA_ID: string = this.user?.uid || '';
    if (!!this.changes() && !!CONTA_ID) {
      this.#loaderServ.lstart();
      try {
        const functions = getFunctions(getApp(), environment?.firebase?.region);
        const fn = httpsCallable(functions, 'onValidateConta');
        const RET: any = await fn({ data: this.changes() });
        console.log(RET);
        const ERROR: string = RET?.data?.error || '';
        const ERRORS: any = RET?.data?.errors || {};
        delete this.changes().id;
        console.log(this.changes());
        this.dadosPessoaisFormRef?.fv()?.setApiErrors(ERRORS?.dadosPessoais);
        this.contatoFormRef?.fv()?.setApiErrors(ERRORS?.contato);
        this.manageEnderecoRef?.enderecoFormRef?.fv().setApiErrors(ERRORS?.endereco);
        if (!!ERROR) {
          const STEP: number = STEPS?.indexOf(ERROR);
          // console.log(STEP, this.stepperRef);
          if (!!this.stepperRef && STEP >= 0) {
            this.stepperRef.selectedIndex = STEP;
          } // if
          return;
        } // if

        const SUB: Subscription = this.#loaderServ.showUntilCompleted(
          this.#contasServ.update(CONTA_ID, this.changes())
            .pipe(first(), finalize(() => SUB?.unsubscribe()))
        )
          .subscribe(
            () => {
              this.#snackBar.open(`Conta ${this.conta()?.email} criada.`, '');
              this.onModalCloseClick(this.#contasServ.fix(this.changes()));
            }
          );
      } finally {
        this.#loaderServ.lstop();
      } // try-catch
    } // if
  }
  //#endregion
}
