<div class="horarios d-flex flex-column w-100"
     *ngIf="!!horarios">
  <div class="d-flex flex-column">
    <div *ngFor="let dow of [0, 1, 2, 3, 4, 5, 6]; index as j">
      <span class="o-50">{{ diasSemanaCurto()?.[dow] }}</span><br />

      <span *ngIf="!!dows()?.[dow].length; else fechado">
        <span *ngFor="let h of dows()?.[dow]; index as i">
          {{ h.inicio | min2time }} <span class="o-50">às</span> {{ h.final | min2time }}
          <span *ngIf="i < dows()?.[dow].length - 1">&bullet;</span>
        </span>
      </span>
      <ng-template #fechado>
        Fechado
      </ng-template>
      <hr *ngIf="j < 6">
    </div>
  </div>
</div>
