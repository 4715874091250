//#region ng
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
//#endregion

//#region mat
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
// import { MatTooltipModule } from '@angular/material/tooltip';
//#endregion

// //#region modules
import { AppComponentsModule } from '../../components/components.module';
// import { MercadeiroSharedModule } from '../../_shared/_mercadeiro/_shared/mercadeiro-shared.module';
import { MeHtmlComponentsModule } from '../../_shared/_mercadeiro/_ng-ui/_html/me-html-components.module';
//#endregion

//#region pages
import { AppCarrinhoMenuModal } from '..';
import { MeBsComponentsModule } from '../../_shared/_mercadeiro/_ng-ui/_bs/me-bs-components.module';
//#endregion

@NgModule({
  imports: [
    AppComponentsModule,
    CommonModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    // MatTooltipModule,
    MeBsComponentsModule,
    MeHtmlComponentsModule,
    RouterModule
  ],
  declarations: [
    AppCarrinhoMenuModal
  ]
})
export class AppCarrinhoMenuModalModule { }
