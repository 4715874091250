//#region ng
import {
  Component,
  inject,
  signal
} from '@angular/core';
import { Router } from '@angular/router';
//#endregion

//#region 3rd
import {
  Observable,
  combineLatest
} from 'rxjs';
import { map } from 'rxjs/operators';
//#endregion

//#region models
interface IVm {
  isAtTop: boolean;
  isMobile: boolean;
  rota: ISuaContaRota;
};
import { SUA_CONTA_ROTAS } from '../../models/consts';
import { ISuaContaRota } from '../../models/interfaces/misc';
//#endregion

//#region services
import { AppService } from '../../services';
//#endregion

@Component({
  selector: 'app-sua-conta',
  templateUrl: './sua-conta.page.html',
  styleUrls: ['./sua-conta.page.scss']
})
export class SuaContaPage {
  //#region publics
  rotas = signal<ISuaContaRota[]>(SUA_CONTA_ROTAS).asReadonly();
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region suaContaRouteId
  #suaContaRouteId: string;
  get suaContaRouteId(): string { return this.#suaContaRouteId; }
  set suaContaRouteId(val: string) {
    // const OLD_ROUTE: string = this.#suaContaRouteId;
    this.#suaContaRouteId = val;
    // console.log(val);
    !!val && this.#router.navigateByUrl(`/suaConta/${val}`);
  }
  //#endregion

  //#region injects
  #router = inject(Router);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.vm$.set(
      combineLatest([
        AppService.isMobile$,
        AppService.isAtTop$,
        AppService.suaContaRotaIdChanged$,
      ])
        .pipe(
          map(([isMobile, isAtTop, rotaId]) => {
            // console.log(rotaId, this.rotas());
            if (this.suaContaRouteId !== rotaId) { this.suaContaRouteId = rotaId; }

            const VM: IVm = {
              isMobile,
              isAtTop,
              rota: this.rotas()?.find((r: ISuaContaRota) => r?.id === rotaId)
            };
            // console.log(VM);
            return VM;
          }),
        )
    );
  }
  //#endregion

  //#region functions
  tid_rot(index: any, item: any): string { return item?.id || ''; }
  //#endregion

  //#region methods
  onGotoHome() {
    AppService.goHome$.emit();
  }

  onCarrinhoClick() {
    AppService.menuCarrinhoStatus$.next(true);
  }

  onLogoutClick() {
    AppService.logout$.emit('home');
    // AppService.goHome$.emit();
  }
  //#endregion
}
