<div *ngIf="!!produto">
  <!-- {{ produto | json }} -->
  <div *ngIf="!!produto?.estoqueCritico; else estoqueOk">
    <button type="button"
            class="btn btn-link rem-9">
      SEM ESTOQUE
    </button>
  </div>
  <ng-template #estoqueOk>
    <div *ngIf="produto?.__carrinho?.qtde?.val > 0; else comprar">
      <div class="btn btn-qtde round w-100"
           [class.light]="!!light">
        <span class="qtde-icon qtde-icon-left"
              [ngClass]="{'icon-s-cancel-coin': !!produto?.__carrinho?.qtde?.isMin, 'icon-s-minus-coin': !produto?.__carrinho?.qtde?.isMin }"
              (click)="onQtdeProdutoClick(produto, -1); $event?.stopPropagation();">
        </span>

        <span class="qtde rem-12">
          {{ produto?.__carrinho?.qtde?.fixed | number : '1.0-3' }}
          <span *ngIf="produto?.fracionado?._status">
            <!--  && !(produto?.fracionado?.unidade?.fracaoAproxUn) && produto?.__qtdeCarrinho?.tipoQtde === 'Q' -->
            {{ produto?.fracionado?.unidade?._legenda }}
          </span>

          <span class="icon-s-plus-coin qtde-icon qtde-icon-right"
                (click)="onQtdeProdutoClick(produto); $event?.stopPropagation();">
          </span>
        </span>
      </div>
    </div>
    <ng-template #comprar>
      <button type="button"
              class="btn btn-outline-dark btn-comprar round w-100"
              (click)="onQtdeProdutoClick(produto, 1); $event?.stopPropagation();">
        Adicionar
      </button>
    </ng-template>
  </ng-template>
</div>
