<ng-container *ngIf="vm$() | async as vm">
  <div class="page"
       [class.no-footer]="!vm?.isMobile">

    <div class="page-header">
      <div *ngIf="!!vm?.isMobile; else headerDesktop">
        <app-loja-m-header [showReturn]="true"
                           (returnClick$)="onGotoHomeClick()" />
      </div>
      <ng-template #headerDesktop
                   class="desktop-lg">
        <app-loja-header [showSearchPreview]="false" />
      </ng-template>
    </div>

    <div class="page-content d-flex"
         [class.lg]="vm?.isAtTop">

      <div class="m-auto text-center p-4">
        <img src="https://firebasestorage.googleapis.com/v0/b/mercadeiro-896b2.appspot.com/o/misc%2Fstop_hand-octogon-red.svg?alt=media"
             class="img-fluid">

        <div class="page-title danger tint fw-bold my-3">
          ACESSO NEGADO
        </div>

        <div class="page-text o-70">
          <p>
            Essa loja é uma <strong>distribuidora</strong>, e só permite compras para <strong>contas previamente
              credenciadas</strong>.
          </p>

          <p>
            Entre em <strong>contato</strong> com a loja para <strong>habilitar</strong> sua conta.
          </p>
        </div>

        <div class="d-flex flex-column">
          <button type="button"
                  class="btn btn-light btn-lg"
                  (click)="onTrocarLojaClick()">
            TROCAR DE LOJA
          </button>

          <br>

          <button type="button"
                  class="btn btn-light btn-lg"
                  (click)="onGotoHomeClick()">
            CONTINUAR CONSULTANDO ESSA LOJA
          </button>
        </div>
      </div>
    </div>

    <app-loja-footer />
  </div>
</ng-container>
