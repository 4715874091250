//#region ng
import {
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
  signal
} from '@angular/core';
//#endregion

//#region meilisearch
import { MeiliSearch } from 'meilisearch';
//#endregion

//#region 3rd
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  takeUntil,
  tap
} from 'rxjs/operators';
//#endregion

//#region models
import {
  ILoja,
  IProduto
} from '../../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
//#endregion

//#region libs
import { calcMeiliNomeIndice } from '../../../_shared/_mercadeiro/_misc/_libs';
import { onDestroy } from '../../../_shared/_core/_ng/_libs';
//#endregion

//#region services
import {
  LojasService,
  ProdutosService
} from '../../../_shared/_mercadeiro/_ng/_services';
import { AppService } from '../../../services';
//#endregion

//#region stores
import { LojasStore } from '../../../_shared/_mercadeiro/_ng/_stores';
//#endregion

@Component({
  selector: 'app-mobile-search',
  templateUrl: './mobile-search.component.html',
  styleUrls: ['./mobile-search.component.scss']
})
export class AppMobileSearchComponent {
  //#region actions
  destroyAction$: Subject<void> = onDestroy();
  //#endregion

  //#region inputs
  // searchTerm
  #searchTerm: string = '';
  get searchTerm(): string { return this.#searchTerm; }
  @Input() set searchTerm(val: string) {
    // const OLD: string = this.#searchTerm;
    this.#searchTerm = val;
    // if (OLD !== val) {
    this.#refresh(val);
    // this.searchChange$.emit(val);
    // } // if
  }
  //#endregion

  //#region outputs
  @Output() cancel$ = new EventEmitter<void>();
  @Output() searchChange$ = new EventEmitter<string>();
  @Output() clearSearch$ = new EventEmitter<void>();
  //#endregion

  //#region publics
  loja = signal<ILoja>(null);
  //#endregion

  //#region privates
  #meilisearchClient: MeiliSearch;
  #nomeIndice: string;
  //#endregion

  //#region injects
  #lojasServ = inject(LojasService);
  #lojasStore = inject(LojasStore);
  #produtosServ = inject(ProdutosService);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.#lojasStore.lojaStateChanged$
      .pipe(
        tap((loja: ILoja) => {
          // console.log(loja);
          this.loja.set(!!loja ? this.#lojasServ.fix(loja) : null);
          if (!!loja) {
            const { host: HOST, searchKey: API_KEY } = loja?.meilisearch;
            // console.log(HOST, API_KEY);
            this.#meilisearchClient = new MeiliSearch(
              {
                host: HOST || '',
                apiKey: API_KEY || ''
              }
            );
            this.#nomeIndice = calcMeiliNomeIndice(loja?.id || '', 'produtos');
            this.searchChange$.emit(this.searchTerm || '');
            // console.log(this.#meilisearchClient);
          } // if
        }),
        takeUntil(this.destroyAction$),
      )
      .subscribe();

    this.searchChange$
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        takeUntil(this.destroyAction$),
      )
      .subscribe((searchTerm: string) => {
        this.#refresh(searchTerm);
      });
  }
  //#endregion

  //#region functions
  #refresh(searchTerm: string) {
    // console.log(searchTerm, this.#nomeIndice);
    if (
      !!searchTerm
      && !!this.#meilisearchClient
      && !!this.#nomeIndice
    ) {
      this.#meilisearchClient
        .index(this.#nomeIndice)
        .search(
          searchTerm,
          {
            filter: ["ativo._status = true"],
            limit: 1000
          }
        )
        .then(
          (res) => {
            // console.log(res.hits);
            const PRODUTOS: IProduto[] = this.#produtosServ.fixes((res?.hits || []) as Partial<IProduto>[]);
            // console.log(PRODUTOS);
            AppService.searchHitsChanged$.next(PRODUTOS);
          });
    } else {
      AppService.searchHitsChanged$.next([]);
    } // else
  }
  //#endregion
}
