//#region ng
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  effect,
  inject,
  signal,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
//#endregion

//#region models
// import { environment } from 'src/environments/environment';
// import { ICorFbUserApi } from '../../_shared/_core/_misc/_models/_interfaces/_apis';
import { CorFormValidation } from '../../_shared/_core/_ng/_models/_classes';
import { IConta } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { FOCUS_TIMEOUT } from '../../_shared/_core/_misc/_models/consts';
import {
  isFoneCel,
  isFoneFixo
} from '../../_shared/_libs/_misc/_validations';
import { ICorFbUserApi } from '../../_shared/_core/_misc/_models/_interfaces/_apis';
//#endregion

//#region custom validators
class CustomValidator {
  static foneCel() {
    return (control: FormControl): ValidationErrors => {
      const FONE_CEL = (control.value || '');
      // console.log(FONE_CEL);
      if (!!FONE_CEL) {
        // console.log(isFoneCel(FONE_CEL));
        return isFoneCel(FONE_CEL) ? null : { foneCel: true };
      } else {
        return null;
      } // else
    }
  }

  static foneFixo() {
    return (control: FormControl): any => {
      const FONE_FIXO = (control.value || '');
      // console.log(FONE_FIXO);
      if (!!FONE_FIXO) {
        // console.log(isFoneFixo(FONE_FIXO));
        return isFoneFixo(FONE_FIXO) ? null : { foneFixo: true };
      } else {
        return null;
      } // else
    }
  }
}
//#endregion

//#region services
import { ContasService } from '../../_shared/_mercadeiro/_ng/_services';
import { CorFbUserService } from 'src/app/_shared/_core/_ng/_services';
//#endregion

@Component({
  selector: 'app-conta-contato-form',
  templateUrl: './conta-contato-form.component.html',
  styleUrls: ['./conta-contato-form.component.scss']
})
export class AppContaContatoFormComponent {
  //#region inputs
  // isMobile
  @Input() isMobile: boolean = false;
  // conta
  #conta: IConta;
  get conta(): IConta { return this.#conta; }
  @Input({ required: true }) set conta(val: IConta) {
    this.novo.set(!!val);
    // console.log(val);
    this.#conta = val;
    this.foneFixo.set(['phone'].includes(this.conta?._user?.type));
    this.emailFixo.set(!['phone'].includes(this.conta?._user?.type));
    this.#geraForm();
    this.focus();
  };
  // user
  #user: ICorFbUserApi;
  get user(): ICorFbUserApi { return this.#user; }
  @Input() set user(val: ICorFbUserApi) {
    this.#user = !!val ? this.#userServ.fix(val) : null;
    // this.isNomeFixo.set(!['password', 'phone'].includes(this.user?.type));
    this.conta = this.#contasServ.fix(this.conta, this.user);
  };
  // submitCaption
  @Input() submitCaption: string = 'CONFIRMAR';
  // showSubmit
  @Input() showSubmit: boolean;
  // submit$
  @Output() submit$ = new EventEmitter<any>();
  //#endregion

  //#region publics
  contatoForm = signal<FormGroup>(null);
  emailFixo = signal<boolean>(null);
  foneFixo = signal<boolean>(null);
  fv = signal<CorFormValidation>(null);
  novo = signal<boolean>(null);
  //#endregion

  //#region methods
  foneCelularFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>());
  foneFixoFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>());
  //#endregion

  //#region injects
  #fb = inject(FormBuilder);
  #contasServ = inject(ContasService);
  #userServ = inject(CorFbUserService);
  //#endregion

  //#region constructor
  constructor() {
    this.fv.set(new CorFormValidation());
    effect(() => { this.emailFixo(); this.foneFixo(); this.focus() });
  }
  //#endregion

  //#region functions
  #geraForm() {
    this.contatoForm.set(
      this.#fb.group({
        email: this.conta?.email || '',
        fones: this.#fb.group({
          celular: this.conta?.fones?.celular || '',
          fixo: this.conta?.fones?.fixo || '',
          mensagens: this.conta?.fones?.mensagens || '',
        }),
        _user: [this?.user || null, [Validators.required]],
      })
    );
    setTimeout(() => this.focus(), FOCUS_TIMEOUT);
  }
  //#endregion

  //#region Controls getters
  get emailRef(): AbstractControl { return this.contatoForm()?.get('email'); }
  get foneCelRef(): AbstractControl { return this.contatoForm()?.get('fones.celular'); }
  get foneFixoRef(): AbstractControl { return this.contatoForm()?.get('fones.fixo'); }
  get foneMsgsRef(): AbstractControl { return this.contatoForm()?.get('fones.mensagens'); }
  //#endregion

  //#region lifecycles
  ngAfterViewInit() {
    this.focus();
  }
  //#endregion

  //#region functions
  focus() {
    // console.log(this.foneFixo());
    !!this.foneFixo()
      ? this.#foneFixoFocus()
      : this.#foneCelularFocus();
  }

  #foneCelularFocus(): void {
    !this.isMobile && setTimeout(
      () => this.foneCelularFocusEvent$().emit(true),
      FOCUS_TIMEOUT
    );
  }

  #foneFixoFocus(): void {
    !this.isMobile && setTimeout(
      () => this.foneFixoFocusEvent$().emit(true),
      FOCUS_TIMEOUT
    );
  }
  //#endregion
}
