//#region ng
import {
  Component,
  HostListener,
  inject,
  signal
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
//#endregion

//#region firebase
import {
  // Auth,
  getAuth,
  onAuthStateChanged,
  Unsubscribe as AuthUnsubscribe,
} from '@angular/fire/auth';
//#endregion

//#region mat
import { MatSnackBar } from '@angular/material/snack-bar';
//#endregion

//#region 3rd
import * as $ from 'jquery';
import {
  Observable,
  Subject,
  Subscription,
  combineLatest
} from 'rxjs';
import {
  filter,
  finalize,
  first,
  map,
  switchMap,
  takeUntil,
  tap
} from 'rxjs/operators';
import {
  BsModalRef,
  BsModalService
} from 'ngx-bootstrap/modal';
//#endregion

//#region models
interface IVm {
  conta: TNullable<IConta>;
  isAtTop: boolean;
  isLoading: boolean;
  isMobile: boolean;
  isRetirada: boolean;
  loja: ILoja;
};
import { environment } from 'src/environments/environment';
import { TNullable } from './_shared/_core/_misc/_models/_types';
import { ICorFbUserApi } from './_shared/_core/_misc/_models/_interfaces/_apis';
import {
  HOME_ROUTE,
  SCROLL_TOP_OFFSET
} from './models/consts';
import {
  IConta,
  ILoja,
  IProduto
} from './_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { CorFbMessaging } from './_shared/_core/_ng/_models/_classes';
//#endregion

//#region libs
import { onDestroy } from './_shared/_core/_ng/_libs';
import { guid } from './_shared/_libs/_misc/_strings';
//#endregion

//#region services
import { AppService } from './services';
import {
  CorFbAuthService,
  CorLoaderService,
  CorMessagesService
} from './_shared/_core/_ng/_services';
import { ContasService } from './_shared/_mercadeiro/_ng/_services';
//#endregion

//#region stores
import { AppCarrinhoStore } from './stores';
import {
  ContasStore,
  LojasStore
} from './_shared/_mercadeiro/_ng/_stores';
//#endregion

//#region components
import {
  AppCarrinhoMenuModal,
  AppCompletarCadastroModal,
  AppIdentificacaoModal,
  AppLojaMenuModal,
  AppProdutoDetalhesModal
} from './modals';
import { ICarrinhoMap } from './_shared/_mercadeiro/_misc/_models/_interfaces/_maps';
import { ICarrinhoPendenteEvent } from './_shared/_mercadeiro/_misc/_models/_interfaces/_events';
//#endregion

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  //#region actions
  #destroyAction$: Subject<void> = onDestroy();
  #userAction$ = new Subject<TNullable<ICorFbUserApi>>();
  // #contaStateAction = new BehaviorSubject<TNullable<IConta>>(null);
  //#endregion

  //#region hostlisteners
  @HostListener('window:scroll', ['$event'])
  onScrollUpdate(event) { this.#scrollChanged(); };
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const W: number = event?.target?.innerWidth || 0;
    // console.log(W);
    this.#viewportResized(W);
  };
  //#endregion

  //#region publics
  // conta = signal<IConta>(null);
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region privates
  #authUnsub: AuthUnsubscribe;
  #bsIdentificacaoModalRef: BsModalRef;
  #bsCarrinhoModalRef: BsModalRef;
  #bsLojaModalRef: BsModalRef;
  #idModalIdentificacao: number;
  #messaging: CorFbMessaging;
  #vm: IVm;
  //#endregion

  //#region injects
  #appCarrinhoStore = inject(AppCarrinhoStore);
  // #appLojasStore = inject(AppLojasStore);
  #appServ = inject(AppService);
  #authServ = inject(CorFbAuthService);
  #bsModalServ = inject(BsModalService);
  #contasServ = inject(ContasService);
  #contasStore = inject(ContasStore);
  #loaderServ = inject(CorLoaderService);
  #lojasStore = inject(LojasStore);
  #msgServ = inject(CorMessagesService);
  #snackBar = inject(MatSnackBar);
  #router = inject(Router);
  //#endregion

  //#region constructor
  constructor() {
    this.#messaging = new CorFbMessaging(
      environment?.firebase?.config?.vapidKey || '',
      environment?.firebase?.region || ''
    );
  }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    // console.log(environment);
    AppService.goHome$.pipe(takeUntil(this.#destroyAction$)).subscribe(() => this.#goHome());

    AppService.identificacao$.pipe(takeUntil(this.#destroyAction$)).subscribe((p: IProduto) => this.#identificacao(p));

    AppService.logout$.pipe(takeUntil(this.#destroyAction$)).subscribe((goto: string) => this.#logout(goto));

    AppService.menuLojaStatus$.pipe(takeUntil(this.#destroyAction$)).subscribe((status: boolean) => this.#abrirMenuLoja(status));

    AppService.menuCarrinhoStatus$.pipe(takeUntil(this.#destroyAction$)).subscribe((status: boolean) => this.#abrirMenuCarrinho(status));

    AppService.produtoDetalhes$.pipe(takeUntil(this.#destroyAction$)).subscribe(({ produto, showNav }) => this.#produtoDetalhes(produto, showNav ?? true));

    this.#appCarrinhoStore.carrinhoPendenteFound$
      .pipe(
        // tap((carrinhoPendente: ICarrinhoPendenteEvent) => console.log(carrinhoPendente)),
        filter((carrinhoPendente: ICarrinhoPendenteEvent) => !!carrinhoPendente),
        takeUntil(this.#destroyAction$)
      )
      .subscribe((carrinhoPendente: ICarrinhoPendenteEvent) => this.#router.navigateByUrl('/carrinhoPendente'));

    this.#userAction$
      .pipe(
        switchMap(
          (user: ICorFbUserApi) => this.#contasServ.contaFromUid(user?.uid)
            .pipe(
              tap((conta: IConta) => {
                // console.log(conta);                
                this.#bsIdentificacaoModalRef?.hide();
                if (!!user) {
                  !!conta ? this.#verificaConta(conta, user) : this.#completaCadastro(user);
                } else {
                  this.#verificaConta(null, null);
                } // else
              })
            )
        ),
        takeUntil(this.#destroyAction$),
      )
      .subscribe();

    this.vm$.set(
      combineLatest([
        AppService.isAtTop$,
        AppService.isMobile$,
        this.#loaderServ.isLoading$,
        this.#appCarrinhoStore.carrinhoLojaStateChanged$,
        this.#lojasStore.lojaStateChanged$,
        this.#contasStore.contaStateChanged$,
      ])
        .pipe(
          map(
            ([isAtTop, isMobile, isLoading, carrinho, loja, conta]) => {
              // console.log(carrinho);
              // console.log(loja);
              // console.log(conta);
              if (
                !!loja?.distribuidora?.status
                && !!conta
                && !conta?.credenciado?.[loja?.id]
              ) {
                this.#logout();
                this.#router.navigateByUrl('/acessoNegado');
              } // if

              this.#vm = {
                conta,
                isAtTop,
                isMobile,
                isLoading,
                isRetirada: !!carrinho?.__loja?.isRetirada, // !!infoState?.retirada,
                loja
              };
              // console.log(this.#vm);
              return this.#vm;
            }
          )
        )
    );

    !!this.#authUnsub && this.#authUnsub();
    this.#authUnsub = onAuthStateChanged(
      getAuth(),
      (user: any) => {
        // console.log(user);
        this.#userAction$.next(user);
      }
    );

    this.#router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.#destroyAction$),
      )
      .subscribe((event: any) => {
        this.#abrirMenuCarrinho(false);
        this.#abrirMenuLoja(false);
        const URL: string = event?.url || '';
        // console.log(URL);
        const ROUTE: string = URL.split('?')[0] || '';
        // console.log(ROUTE);
        switch (ROUTE) {
          case '/suaConta/seusDados':
            AppService.suaContaRotaIdChanged$.next('seusDados');
            break;

          case '/suaConta/seusContatos':
            AppService.suaContaRotaIdChanged$.next('seusContatos');
            break;

          case '/suaConta/seusEnderecos':
            AppService.suaContaRotaIdChanged$.next('seusEnderecos');
            break;

          case '/suaConta/seusPedidos':
            AppService.suaContaRotaIdChanged$.next('seusPedidos');
            break;

          case '/suaConta/mudarSenha':
            AppService.suaContaRotaIdChanged$.next('mudarSenha');
            break;
        } // switch
      });
  }

  ngAfterViewInit() {
    this.#viewportResized(window.innerWidth);
    this.#scrollChanged();
  }
  //#endregion

  //#region functions
  #scrollChanged() {
    const AT_TOP = !(
      document?.body?.scrollTop > SCROLL_TOP_OFFSET
      || document?.documentElement?.scrollTop > SCROLL_TOP_OFFSET
    );
    AppService.isAtTop$.next(AT_TOP);
  }

  #viewportResized(width: number) {
    // console.log(width);
    const IS_MOBILE: boolean = width < 1024;
    // console.log(width, this.isMobile);
    AppService.isMobile$.next(IS_MOBILE);
    AppService.viewportWidthChanged$.next(width);
  }

  #verificaConta(conta: IConta, user: ICorFbUserApi) {
    const CONTA: IConta = !!conta ? this.#contasServ.fix({ ...conta, id: user?.uid }) : null;
    if (!!conta) {
      const NOME_COMPLETO: string = CONTA?.__nomeCompleto || '';
      try {
        // console.log(UID, NOME_COMPLETO);
        this.#messaging.getTokenConta(user?.uid, NOME_COMPLETO)
          .then((status: boolean) => {
            // console.log(status);
            Notification.requestPermission()
              .then(
                status => { console.log(status); }
              );
          });
        // this.#snackBar.open(`Olá, ${NOME_COMPLETO}.`);
      } catch (error) {
        this.#msgServ.send('Encontrados problemas preparando notificações.');
      } // catch

      // Abre detalhes do produto dependendo da origem do login.
      const PRODUTO_LOGIN: IProduto = this.#appServ.produtoLogin || null;
      // console.log(user, PRODUTO_LOGIN);
      this.#appServ.produtoLogin = null;
      !!user && !!PRODUTO_LOGIN && AppService.produtoDetalhes$.emit({ produto: PRODUTO_LOGIN });
      // this.#bsIdentificacaoModalRef?.hide();
      // this.conta?.set(this.#contasServ.fix(CONTA, user));
      this.#contasStore.setState(this.#contasServ.fix(CONTA, user));
    } else {
      // this.conta.set(null);
      this.#contasStore.setState(null);
    } // else
  }

  #completaCadastro(user: any) {
    // console.log(user);
    const MODAL_ID: number = +guid(10, 10);
    const MODAL_REF: any = this.#bsModalServ.show(
      AppCompletarCadastroModal,
      {
        id: MODAL_ID,
        class: 'modal-dialog-centered modal-lg',
        initialState: {
          idModal: MODAL_ID,
          caption: 'Completando cadastro',
          user,
          isMobile: this.#vm?.isMobile
        }
      });

    const SUB: Subscription = MODAL_REF.onHidden
      .pipe(first(), finalize(() => SUB?.unsubscribe()))
      .subscribe(
        () => {
          const DATA: IConta = MODAL_REF?.content?.modalRet()?.conta || null;
          // console.log(DATA);
          !!DATA ? this.#verificaConta(DATA, user) : this.#logout();
        }
      );
  }

  async #logout(
    goto: string = '',
    silent: boolean = true
  ) {
    // console.log(goto);
    this.#abrirMenuCarrinho(false);
    this.#abrirMenuLoja(false);
    await this.#authServ.logout();
    const CONTA: IConta | null = this.#contasStore?.getState();
    if (!!CONTA) {
      const NOME: string = (CONTA?.nome?.nome) || 'Visitante';
      !silent && this.#snackBar.open(`${NOME} saiu.`);
      // this.conta.set(null);
      this.#contasStore.setState(null);
    } // if

    if (!!goto) {
      goto === 'home' ? AppService.goHome$.emit() : this.#router.navigateByUrl(goto);
    } // if
  }

  #abrirMenuLoja(status: boolean) {
    if (!!status) {
      const MODAL_ID: number = +guid(10, 10);
      // AppService.menuStatus.loja = true;
      this.#bsLojaModalRef = this.#bsModalServ.show(
        AppLojaMenuModal, {
        class: 'modal-left',
        id: MODAL_ID,
        initialState: {
          idModal: MODAL_ID,
          loja: this.#vm?.loja,
          isRetirada: this.#vm?.isRetirada,
          conta: this.#vm.conta, // this.conta()
        },
      });
    } else {
      this.#bsLojaModalRef?.hide();
    } // else
  }

  #goHome() {
    const CARRINHO: ICarrinhoMap = this.#appCarrinhoStore.carrinhoGet();
    const LOJA_ID: string = CARRINHO?.__loja?.id || '';
    // console.log(LOJA_ID);
    !!LOJA_ID
      ? this.#router.navigate(
        [
          '/loja',
          LOJA_ID,
          !!CARRINHO?.__loja?.isRetirada ? 'true' : 'false'
        ]
      )
      : this.#router.navigateByUrl(HOME_ROUTE);
  }

  #abrirMenuCarrinho(status: boolean) {
    if (!!status) {
      const MODAL_ID: number = +guid(10, 10);
      // AppService.menuStatus.loja = true;
      this.#bsCarrinhoModalRef = this.#bsModalServ.show(
        AppCarrinhoMenuModal, {
        class: 'modal-right',
        id: MODAL_ID,
        initialState: {
          idModal: MODAL_ID,
          isMobile: this.#vm?.isMobile,
        },
      });
    } else {
      this.#bsCarrinhoModalRef?.hide();
    } // else
  }

  #identificacao(produto: IProduto = null) {
    this.#appServ.produtoLogin = produto;
    // console.log(produto);
    this.#abrirMenuLoja(false);
    // console.log('#identificacao');
    this.#idModalIdentificacao = +guid(10, 10);
    this.#bsIdentificacaoModalRef = this.#bsModalServ.show(
      AppIdentificacaoModal,
      {
        class: 'modal-dialog-centered',
        id: this.#idModalIdentificacao,
        initialState: {
          emailLoginButtonCaption: 'ENTRAR',
          isMobile: this.#vm?.isMobile,
          idModal: this.#idModalIdentificacao,
        },
      });
  }

  #produtoDetalhes(produto: IProduto, showNav: boolean) {
    // console.log(produto);
    this.#abrirMenuLoja(false);
    // console.log('#identificacao');
    this.#idModalIdentificacao = +guid(10, 10);
    this.#bsCarrinhoModalRef = this.#bsModalServ.show(
      AppProdutoDetalhesModal,
      {
        class: 'modal-dialog-centered modal-lg',
        id: this.#idModalIdentificacao,
        initialState: {
          isMobile: this.#vm?.isMobile,
          idModal: this.#idModalIdentificacao,
          produto,
          showNav
        },
      });
  }
  //#endregion

  //#region methods
  onGotoTopClick() {
    $('html, body').animate({
      scrollTop: $('#home').offset().top - 400
    }, 'slow');
  }
  //#endregion
}
