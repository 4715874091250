//#region ng
import {
  Component,
  inject,
  signal
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
//#endregion

//#region 3rd
import {
  combineLatest,
  Observable,
} from 'rxjs';
import { map } from 'rxjs/operators';
//#endregion

//#region models
interface IVm {
  isAtTop: boolean;
  isMobile: boolean;
  loja: ILoja;
};
import { ILoja } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
//#endregion

//#region services
import { AppService } from '../../services';
//#endregion

//#region stores
import { LojasStore } from '../../_shared/_mercadeiro/_ng/_stores';
//#endregion

@Component({
  selector: 'app-acesso-negado',
  templateUrl: './acesso-negado.page.html',
  styleUrls: ['./acesso-negado.page.scss']
})
export class AcessoNegadoPage {
  //#region publics
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region privates
  #vm: IVm;
  //#endregion

  //#region injects
  #lojasStore = inject(LojasStore);
  #router = inject(Router);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.vm$.set(
      combineLatest([
        AppService.isMobile$,
        AppService.isAtTop$,
        this.#lojasStore.lojaStateChanged$,
      ])
        .pipe(
          map(([isMobile, isAtTop, loja]) => {
            // console.log(banners);
            this.#vm = {
              isMobile,
              isAtTop,
              loja
            };
            // console.log(this.#vm);
            return this.#vm;
          }),
        )
    );
  }
  //#endregion

  //#region methods
  onGotoHomeClick() {
    AppService.goHome$.emit();
  }

  onTrocarLojaClick() {
    // console.log(this.loja);
    this.#router.navigate(
      ['/lojas'],
      {
        queryParams: {
          locId: encodeURI(this.#vm?.loja?._idLocalidade || ''),
          bairro: encodeURI(this.#vm?.loja?.endereco?.bairro || ''),
          redeId: encodeURI(this.#vm?.loja?.rede?.id || ''),
        }
      }
    );
  }
  //#endregion
}
