//#region ng
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
// import { ReactiveFormsModule } from '@angular/forms';
//#endregion

//#region mat
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
//#endregion

// //#region modules
import { AppComponentsModule } from '../../components/components.module';
// import { MercadeiroSharedModule } from '../../_shared/_mercadeiro/_shared/mercadeiro-shared.module';
//#endregion

//#region pages
import { AppLojaMenuModal } from '..';
//#endregion

@NgModule({
  imports: [
    AppComponentsModule,
    CommonModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    // MercadeiroSharedModule,
    RouterModule,
  ],
  declarations: [
    AppLojaMenuModal
  ]
})
export class AppLojaMenuModalModule { }
