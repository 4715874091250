<!-- <ng-container *ngIf="!!conta && !!user"> -->
<!-- <p>conta {{ conta | json }}</p> -->
<!-- <p>contatoForm().value {{ contatoForm()?.value | json }}</p> -->

<form novalidate
      (ngSubmit)="submit$?.emit(contatoForm()?.value)"
      [formGroup]="contatoForm()"
      *ngIf="contatoForm()">
  <div class="row"
       formGroupName="fones">
    <div class="col-12 col-lg-4 pb-2">
      <mat-form-field class="w-100"
                      [class.off]="!!foneFixo()"
                      [appearance]="!!isMobile ? 'fill' : 'outline'">
        <mat-label>Telefone celular</mat-label>
        <input matInput
               type="text"
               formControlName="celular"
               mask="(00) 00000-0000"
               [readonly]="foneFixo()"
               [showMaskTyped]="false"
               [focus]="foneCelularFocusEvent$()"
               onReturn>
        <mat-hint *ngIf="fv()?.error(foneCelRef, 'foneCelular') || foneCelRef?.errors?.['foneCel']">
          <span class="danger">
            {{ fv()?.error(foneCelRef, 'foneCelular') || 'Telefone inválido.' }}
          </span>
        </mat-hint>
      </mat-form-field>
    </div>

    <div class="col-12 col-lg-4 pb-2">
      <mat-form-field class="w-100"
                      [appearance]="!!isMobile ? 'fill' : 'outline'">
        <mat-label>Telefone fixo</mat-label>
        <input type="text"
               matInput
               formControlName="fixo"
               mask="(00) 0000-0000"
               [showMaskTyped]="false"
               [focus]="foneFixoFocusEvent$()"
               onReturn>
        <mat-hint *ngIf="fv()?.lerror(foneFixoRef, 'foneFixo') || foneFixoRef?.errors?.['foneFixo']">
          <span class="danger">
            {{ fv()?.error(foneFixoRef, 'foneFixo') || 'Telefone inválido.' }}
          </span>
        </mat-hint>
      </mat-form-field>
    </div>

    <div class="col-12 col-lg-4 pb-2">
      <mat-form-field class="w-100"
                      [appearance]="!!isMobile ? 'fill' : 'outline'">
        <mat-label>Telefone mensagens</mat-label>
        <input type="text"
               matInput
               formControlName="mensagens"
               mask="(00) 00000-0000"
               [showMaskTyped]="false"
               onReturn>
        <mat-hint *ngIf="fv()?.lerror(foneMsgsRef, 'foneMensagens') || foneMsgsRef?.errors?.['foneCel']">
          <span class="danger">
            {{ fv()?.error(foneMsgsRef, 'foneMensagens') || 'Telefone inválido.' }}
          </span>
        </mat-hint>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col pb-2">
      <mat-form-field class="w-100"
                      [class.off]="!!emailFixo()"
                      [appearance]="!!isMobile ? 'fill' : 'outline'">
        <mat-label>E-mail</mat-label>
        <input matInput
               type="email"
               formControlName="email"
               [readonly]="emailFixo()"
               [value]="emailRef.value">
        <mat-hint *ngIf="fv()?.lerror(emailRef)">
          <span class="danger">
            {{ fv()?.error(emailRef) }}
          </span>
        </mat-hint>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="d-flex mt-4"
           *ngIf="!!showSubmit">
        <button type="submit"
                class="ms-auto pointer"
                mat-raised-button
                color="primary"
                [disabled]="!contatoForm()?.valid">
          {{ submitCaption }}
        </button>
      </div>
    </div>
  </div>
</form>
<!-- </ng-container> -->