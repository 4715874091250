<!-- <p>conta {{ conta | json }}</p> -->
<ng-container *ngIf="vm$() | async as vm">
  <div *ngIf="!!vm?.conta; else entrar">
    <button mat-button
            type="button"
            [matMenuTriggerFor]="suaContaMenu">
      <img [src]="vm?.conta?.__urlFoto"
           class="avatar">
      &nbsp;<span class="o-50">
        Olá,&nbsp;
      </span>
      {{ vm?.conta?.nome?.nome }}
      &nbsp;<span class="icon-r-chevron-d r-10 o-50"> </span>
    </button>
  </div>
  <ng-template #entrar>
    <button mat-button
            type="button"
            class="d-flex identificar-btn"
            (click)="onEntrarClick()">
      <div class="m-auto">
        <span class="icon-r-person o-50"></span>&nbsp;ENTRAR/CADASTRAR
      </div>
    </button>
  </ng-template>

  <!-- Início menus  -->
  <mat-menu #suaContaMenu="matMenu">
    <button mat-menu-item
            type="button"
            routerLinkActive="active"
            routerDirection="root"
            [routerLink]="rota.url"
            *ngFor="let rota of vm?.rotas; trackBy:tid_rot">
      <span class="icon {{ rota?.icon }} o-50"></span>&nbsp;
      {{ rota?.caption }}
    </button>

    <mat-divider></mat-divider>

    <button mat-menu-item
            (click)="logout$?.emit()">
      <span class="icon icon-r-import o-50"></span>&nbsp;
      Sair da conta
    </button>
  </mat-menu>
  <!-- Final menus -->
</ng-container>
