//#region ng
import {
  Component,
  inject,
  signal
} from '@angular/core';
import {
  ActivatedRoute,
  Router
} from '@angular/router';
//#endregion

//#region firebase
import { Firestore } from '@angular/fire/firestore';
//#endregion

//#region mat
import { MatSnackBar } from '@angular/material/snack-bar';
//#endregion

//#region 3rd
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest,
} from 'rxjs';
import {
  filter,
  map,
  tap,
  takeUntil,
  first,
  finalize
} from 'rxjs/operators';
//#endregion

//#region models
interface IVm {
  deptos: IDepartamento[];
  deptoAtivo: IDepartamento;
  deptosInfo: IDeptos;
  // isAtTop: boolean;
  // isMobile: boolean;
  // produtos: IProduto[];
  // loja: ILoja;
};
interface IDeptoInfo {
  id?: string;
  depto?: IDepartamento;
};
interface IDeptos {
  d1: IDeptoInfo;
  d2: IDeptoInfo;
  d3: IDeptoInfo;
};
import { CorAngularFirePaginator } from '../../_shared/_core/_ng/_models/_classes';
import {
  IDepartamento,
  ILoja
} from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { IProduto } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { PRODUTOS_ORD } from '../../_shared/_mercadeiro/_misc/_models/consts';
import {
  ICorPaginatorFilter,
  ICorPaginatorSort
} from '../../_shared/_core/_misc/_models/_interfaces/_misc';
import { PRODUTOS_POR_PAG } from '../../models/consts';
import { ICarrinhoMap } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_maps';
//#endregion

//#region libs
import { fixProdutos } from '../../_shared/_mercadeiro/_misc/_libs';
import {
  compareValues,
  uniqueListBy
} from '../../_shared/_libs/_misc/_arrays';
import { onDestroy } from '../../_shared/_core/_ng/_libs';
//#endregion

//#region services
import { AppService } from '../../services';
import {
  CarrinhoService,
  DepartamentosService,
  LojasService,
  ProdutosService
} from '../../_shared/_mercadeiro/_ng/_services';
//#endregion

//#region stores
import { LojasStore } from '../../_shared/_mercadeiro/_ng/_stores';
import {
  AppCarrinhoStore,
  AppLojasStore
} from '../../stores';
//#endregion

@Component({
  selector: 'app-departamentos',
  templateUrl: './departamentos.page.html',
  styleUrls: ['./departamentos.page.scss']
})
export class DepartamentosPage {
  //#region actions
  #destroyAction$: Subject<void> = onDestroy();
  deptoAtivoAction$ = signal<BehaviorSubject<IDepartamento>>(new BehaviorSubject<IDepartamento>(null));
  deptosInfoAction$ = signal<BehaviorSubject<IDeptos>>(
    new BehaviorSubject<IDeptos>(
      {
        d1: { id: '', depto: null },
        d2: { id: '', depto: null },
        d3: { id: '', depto: null },
      }
    )
  );
  deptosMenuAction$ = signal<BehaviorSubject<IDepartamento[]>>(new BehaviorSubject<IDepartamento[]>(null));
  ordAction$ = signal<BehaviorSubject<ICorPaginatorSort>>(new BehaviorSubject<ICorPaginatorSort>(PRODUTOS_ORD[1]));
  //#endregion

  //#region publics
  isAtTop = signal<boolean>(null);
  isMobile = signal<boolean>(null);
  // deptoAtivo = signal<IDepartamento>(null);
  paginator: CorAngularFirePaginator<IProduto>;
  // produtos = signal<IProduto[]>(null);
  produtos: IProduto[];
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region privates
  #deptosAll: IDepartamento[];
  #produtosUnsub: Subscription;
  #vm: IVm;
  //#endregion

  //#region injects
  #appCarrinhoStore = inject(AppCarrinhoStore);
  #appLojasStore = inject(AppLojasStore);
  #carrinhoServ = inject(CarrinhoService);
  // #carrinhoServ = inject(CarrinhoService);
  #db = inject(Firestore);
  #departamentosServ = inject(DepartamentosService);
  // #lojasServ = inject(LojasService);
  #lojasStore = inject(LojasStore);
  #produtosServ = inject(ProdutosService);
  #route = inject(ActivatedRoute);
  #router = inject(Router);
  #snackBar = inject(MatSnackBar);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    combineLatest([
      AppService.isMobile$,
      AppService.isAtTop$,
    ])
      .pipe(
        tap(([isMobile, isAtTop]) => {
          // console.log(isMobile, isAtTop);
          this.isMobile.set(isMobile);
          this.isAtTop.set(isAtTop);
        }),
        takeUntil(this.#destroyAction$)
      )
      .subscribe();

    this.vm$.set(
      combineLatest([
        this.deptosMenuAction$(),
        this.deptoAtivoAction$(),
        this.#lojasStore.lojaStateChanged$
          .pipe(filter((l: ILoja) => !!l)),
        this.ordAction$(),
        // this.#appCarrinhoStore.carrinhoStateChanged$,
        // this.#appCarrinhoStore.carrinhoItensStateChanged$,
        this.deptosInfoAction$(),
      ])
        .pipe(
          map(([deptos, deptoAtivo, loja, ord, deptosInfo]) => {
            const DEPTOS: IDepartamento[] = this.#departamentosServ.fixes(deptos);
            // console.log(DEPTOS);
            const FILTERS: ICorPaginatorFilter[] = [
              { field: 'ativo._status', op: '==', val: true },
              // { field: 'departamentos.d1.id', op: '==', val: deptosInfo.d1.id },
            ];
            !!deptosInfo.d1.id && FILTERS.push({ field: 'departamentos.d1.id', op: '==', val: deptosInfo.d1.id });
            !!deptosInfo.d2.id && FILTERS.push({ field: 'departamentos.d2.id', op: '==', val: deptosInfo.d2.id });
            !!deptosInfo.d3.id && FILTERS.push({ field: 'departamentos.d3.id', op: '==', val: deptosInfo.d3.id });
            // console.log(JSON.stringify(FILTERS));
            this.paginator = new CorAngularFirePaginator(
              this.#db,
              `${loja?.__idInfo?.lojaPath}/produtos`,
              PRODUTOS_POR_PAG,
              // [{ field: '_criadoEm', direction: 'desc' }],
              [{ field: ord?.field, direction: ord?.direction }],
              FILTERS,
              null,
              false
            );

            if (
              !!deptosInfo.d1.id ||
              !!deptosInfo.d2.id ||
              !!deptosInfo.d3.id
            ) {
              this.#produtosUnsub && this.#produtosUnsub.unsubscribe();
              this.#produtosUnsub = this.paginator.items$
                .subscribe(
                  (produtos: IProduto[]) => {
                    // console.log(produtos);
                    const PRODUTOS: IProduto[] = this.#produtosServ.fixes(produtos)
                      .filter((e: IProduto) => !!e?.__displayInPagination);
                    // console.log(PRODUTOS);

                    const SUB: Subscription = this.#produtosServ.lfixes(
                      uniqueListBy(
                        (this.produtos || []).concat(PRODUTOS)
                          .sort(compareValues(ord?.field || '', ord?.direction)),
                        'id'
                      )
                    )
                      .pipe(
                        first(),
                        finalize(() => SUB?.unsubscribe())
                      )
                      .subscribe(
                        (prods: IProduto[]) => {
                          // console.log(prods);
                          this.produtos = prods;
                          this.#appCarrinhoStore.setState(this.#appCarrinhoStore.carrinhoGet());
                        }
                      );

                    // this.produtos = uniqueListBy(
                    //   (this.produtos || []).concat(PRODUTOS)
                    //     .sort(compareValues(ord?.field || '', ord?.direction)),
                    //   'id'
                    // );
                    /*  this.produtos.set(
                       fixProdutos(
                         uniqueListBy(
                           (this.produtos || []).concat(PRODUTOS)
                             .sort(compareValues(ord?.field || '', ord?.direction)),
                           'id'
                         ),
                         carrinho,
                       )
                     ); */
                  }
                );
            } else {
              this.produtos = [];
            } // else

            this.#vm = {
              deptos: DEPTOS
                .filter((d: IDepartamento) => !!d?.ativo._status)
                .sort(compareValues('nome')),
              deptoAtivo,
              deptosInfo
            };
            // console.log(VM);
            return this.#vm;
          }),
        )
    );

    this.#appCarrinhoStore.carrinhoItensStateChanged$
      .pipe(
        // startWith(this.#appCarrinhoStore.carrinhoGet()),
        takeUntil(this.#destroyAction$)
      )
      .subscribe(
        (carrinho: ICarrinhoMap) =>
          !!this.produtos && fixProdutos(
            this.produtos,
            !!carrinho ? this.#carrinhoServ.fix(carrinho) : null,
          )
      );

    combineLatest([
      this.#route.queryParams,
      this.#appLojasStore.lojaDepartamentosStateChanged$
        .pipe(filter((deptos: IDepartamento[]) => !!(deptos || [])?.length))
    ])
      .pipe(takeUntil(this.#destroyAction$))
      .subscribe(
        ([queries, departamentos]) => {
          // console.log(queries);
          const DEPTO_ID: string = queries?.['id'] || '';
          this.produtos = [];
          // console.log(queries, DEPTO_ID);

          let deptosAll: IDepartamento[] = []; // !!deptos ? this.#departamentosServ.fixes(deptos) : [];
          this.#departamentosServ.fixes(departamentos)?.forEach(
            (d1: IDepartamento) => {
              deptosAll.push(d1);
              // console.log(d1?._subs);
              this.#departamentosServ.fixes(d1?._subs).forEach(
                (d2: IDepartamento) => {
                  // console.log(d2);
                  deptosAll.push(d2);
                  this.#departamentosServ.fixes(d2?._subs).forEach(
                    (d3: IDepartamento) => {
                      // console.log(d3);
                      deptosAll.push(d3);
                    }
                  )
                })
            }
          );
          deptosAll = deptosAll
            .sort(compareValues('nivel'));

          const D1 = deptosAll.filter((d: IDepartamento) => d?.nivel == 1);
          const D2 = this.#calcParents(deptosAll.filter((d: IDepartamento) => d?.nivel == 2), D1);
          const D3 = this.#calcParents(deptosAll.filter((d: IDepartamento) => d?.nivel == 3), D2);
          deptosAll = [...D1, ...D2, ...D3];

          if (!!DEPTO_ID) {
            const DEPTO_SEL: IDepartamento = deptosAll.find((d: IDepartamento) => d?.id === DEPTO_ID);
            // console.log(DEPTO_SEL);
            if (!DEPTO_SEL) {
              this.#snackBar.open('Departamento indicado não é válido.');
              this.#router.navigateByUrl('/departamentos');
            } else {
              // console.log(DEPTO_SEL?.nivel);
              switch (DEPTO_SEL?.nivel) {
                case 3:
                  this.deptosInfoAction$().next(
                    {
                      d1: { id: DEPTO_SEL?.__parent?.__parent?.id || '', depto: DEPTO_SEL?.__parent?.__parent },
                      d2: { id: DEPTO_SEL?.__parent?.id || '', depto: DEPTO_SEL?.__parent },
                      d3: { id: DEPTO_SEL?.id || '', depto: DEPTO_SEL },
                    }
                  );
                  break;

                case 2:
                  // console.log(DEPTO_SEL);
                  this.deptosInfoAction$().next(
                    {
                      d1: { id: DEPTO_SEL?.__parent?.id || '', depto: DEPTO_SEL?.__parent },
                      d2: { id: DEPTO_SEL?.id || '', depto: DEPTO_SEL },
                      d3: { id: '', depto: null },
                    }
                  );
                  break;

                case 1:
                  this.deptosInfoAction$().next(
                    {
                      d1: { id: DEPTO_SEL?.id || '', depto: DEPTO_SEL },
                      d2: { id: '', depto: null },
                      d3: { id: '', depto: null },
                    }
                  );
                  break;
              } // switch
              this.deptoAtivoAction$().next(DEPTO_SEL);
            } // else
          } else {
            const DEPTOS_MENU: IDepartamento[] = [];
            (departamentos || [])
              //   .filter((d: IDepartamento) => !!d?.ativo?.online /* && !get(d, 'oculto') * /);
              .sort(compareValues('nome'))
              .forEach(
                (d: IDepartamento) => {
                  !d?.oculto
                    ? DEPTOS_MENU.push(d)
                    : DEPTOS_MENU.push(
                      ...(d?._subs || [])
                        .map((ds: Partial<IDepartamento>) => {
                          return this.#departamentosServ.fix(
                            {
                              ...ds,
                              __parent: d
                              // __parent: {
                              //   id: d?.id || '',
                              //   nome: d?.nome || ''
                              // }
                            }
                          );
                        })
                    );
                }
              );
            // console.log(DEPTOS_MENU);
            this.deptosMenuAction$().next(DEPTOS_MENU);
            this.deptoAtivoAction$().next(null);
            this.deptosInfoAction$().next(
              {
                d1: { id: '', depto: null },
                d2: { id: '', depto: null },
                d3: { id: '', depto: null },
              }
            );
          } // else
        }
      );
  }

  ngOnDestroy() {
    this.#produtosUnsub?.unsubscribe();
    this.paginator?.unsub();
  }
  //#endregion

  //#region functions
  tid_dep(index: any, item: IDepartamento | Partial<IDepartamento>): string { return item?.id || ''; }

  tid_pro(index: any, item: IProduto): string { return item?.id || ''; }

  #calcParents(
    deptos: IDepartamento[],
    deptosParent: IDepartamento[]
  ): IDepartamento[] {
    return (deptos || [])
      .map(
        (da: IDepartamento) => {
          // console.log(da);
          if (da?.nivel > 1) {
            const CHILD_ID: string = da?.id || '';
            // console.log(CHILD_ID);
            const PARENT: IDepartamento = deptosParent
              .find((dp: IDepartamento) => (dp?._subs)?.map((dps: Partial<IDepartamento>) => dps?.id).includes(CHILD_ID));
            // console.log(PARENT);
            return {
              ...da,
              __parent: PARENT
            };
          } // if

          return da;
        }
      );
  }
  //#endregion

  //#region methods
  onGotoDepto(deptoId: string) {
    this.#router.navigateByUrl(`/departamentos?id=${deptoId}`);
  }

  onGotoHome() {
    AppService.goHome$.emit();
  }

  onProdutoClicked(produto: IProduto) {
    AppService.produtoDetalhes$.next({ produto });
  }

  onCarrinhoClick() {
    AppService.menuCarrinhoStatus$.next(true);
  }
  //#endregion
}
