//#region ng
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  inject,
  signal,
} from '@angular/core';
import { Router } from '@angular/router';
//#endregion

//#region meilisearch
import { MeiliSearch } from 'meilisearch';
//#endregion

//#region 3rd
import {
  from,
  Observable,
  Subject,
  Subscription,
  combineLatest
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  first,
  map,
  startWith,
  switchMap,
  takeUntil,
  tap
} from 'rxjs/operators';
import { cloneDeep } from 'lodash';
//#endregion

//#region models
interface IVm {
  conta: IConta;
  isAtTop: boolean;
  // isMobile: boolean;
  isRetirada: boolean;
  // departamentos: IDepartamento[];
  route: string;
};
import {
  IConta,
  IDepartamento,
  ILoja,
  IMeilisearch,
  IProduto
} from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { ICorViacepApi } from '../../_shared/_core/_misc/_models/_interfaces/_apis';
import { ILojaInfoState } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_states';
import { FOCUS_TIMEOUT } from '../../_shared/_core/_misc/_models/consts';
import { ICarrinhoMap } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_maps';
import { TNullable } from '../../_shared/_core/_misc/_models/_types';
//#endregion

//#region libs
import { calcMeiliNomeIndice } from '../../_shared/_mercadeiro/_misc/_libs';
import { onDestroy } from '../../_shared/_core/_ng/_libs';
import { compareValues } from '../../_shared/_libs/_misc/_arrays';
//#endregion

//#region services
import { AppService } from '../../services';
import {
  CarrinhoService,
  ContasService,
  DepartamentosService,
  LojasService,
  MeilisearchService,
  ProdutosService
} from '../../_shared/_mercadeiro/_ng/_services';
import {
  CorLoaderService, CorMessagesService
} from '../../_shared/_core/_ng/_services';
//#endregion

//#region stores
import {
  ContasStore,
  LojasStore
} from '../../_shared/_mercadeiro/_ng/_stores';
import {
  AppCarrinhoStore,
  AppEnderecosStore,
  AppLojasStore
} from '../../stores';
//#endregion

//#region components
// import { AppSearchPreviewComponent } from '..';
// import { CorMatManageEnderecoModal } from '../../_shared/_ng/_modals';
// import { MatEnderecoFormModal } from '../../_shared/_mat/_modals';
// import { THUMB_NO_IMG } from '../../_shared/_core/_models/consts';
//#endregion

@Component({
  selector: 'app-loja-header',
  templateUrl: './loja-header.component.html',
  styleUrls: ['./loja-header.component.scss']
})
export class AppLojaHeaderComponent {
  //#region actions
  #destroyAction$: Subject<void> = onDestroy();
  //#endregion

  //#region viewchilds
  // @ViewChild(AppSearchPreviewComponent) searchPreviewFormRef: AppSearchPreviewComponent;
  @ViewChild('searchArea') searchRef: ElementRef;
  //#endregion

  //#region inputs
  // search
  #searchTerm: string = '';
  get searchTerm(): string { return this.#searchTerm; }
  @Input() set searchTerm(val: string) {
    const OLD: string = this.#searchTerm;
    // console.log(OLD, val);
    this.#searchTerm = val;
    if (OLD !== val) {
      this.searchChange$.emit(val);
      // this.reSearch();
    } // if
  };
  // showSearchPreview
  @Input() showSearchPreview: boolean = true;
  //#endregion

  //#region outputs
  @Output() searchChange$ = new EventEmitter<string>();
  //#endregion

  //#region publics
  departamentos = signal<IDepartamento[]>(null);
  loja = signal<ILoja>(null);
  preview = signal<IProduto[]>(null);
  previewOverflow = signal<number>(0);
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region privates
  #meilisearchClient: MeiliSearch;
  #nomeIndice: string;
  #vm: IVm;
  //#endregion

  //#region events
  onSearchFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>());
  //#endregion

  //#region injects
  #appCarrinhoStore = inject(AppCarrinhoStore);
  #appEnderecoStore = inject(AppEnderecosStore);
  #appLojasStore = inject(AppLojasStore);
  #contasStore = inject(ContasStore);
  #departamentosServ = inject(DepartamentosService);
  #lojasServ = inject(LojasService);
  #lojasStore = inject(LojasStore);
  #msgServ = inject(CorMessagesService);
  #produtosServ = inject(ProdutosService);
  #router = inject(Router);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.#msgServ.send();
    this.#lojasStore.lojaStateChanged$
      .pipe(
        tap((loja: ILoja) => {
          // console.log(loja);
          !!loja && this.loja.set(null);
        }),
        filter((loja: ILoja) => !!loja),
        switchMap((loja: ILoja) => from(this.#lojasServ.lfix(loja))),
        tap((lojas: [TNullable<ILoja>, TNullable<ILoja>]) => {
          // console.log(lojas);
          const [L1, L2] = lojas;
          delete L1.__img;
          delete L2.rede;
          const LOJA: ILoja = { ...L1, ...L2 };
          // console.log(LOJA);
          this.loja.set(LOJA);
          const { host: HOST, searchKey: API_KEY } = LOJA?.meilisearch;
          // console.log(HOST, API_KEY);
          this.#meilisearchClient = new MeiliSearch(
            {
              host: HOST || '',
              apiKey: API_KEY || ''
            }
          );
          this.#nomeIndice = calcMeiliNomeIndice(LOJA?.id || '', 'produtos');
          this.searchChange$.emit(this.searchTerm);
        }),
        takeUntil(this.#destroyAction$),
      )
      .subscribe();

    this.searchChange$
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        takeUntil(this.#destroyAction$),
      )
      .subscribe((searchTerm: string) => {
        if (
          !!searchTerm
          && !!this.#meilisearchClient
          && !!this.#nomeIndice
        ) {
          // console.log(searchTerm);
          this.#meilisearchClient
            .index(this.#nomeIndice)
            .search(
              searchTerm,
              {
                filter: ["ativo._status = true"],
                limit: 1000
              }
            )
            .then(
              (res) => {
                // console.log(res.hits);
                const PRODUTOS: IProduto[] = this.#produtosServ.fixes((res?.hits || []) as Partial<IProduto>[]);
                // console.log(PRODUTOS);
                AppService.searchHitsChanged$.next(PRODUTOS);
                if (!!this.showSearchPreview) {
                  this.preview.set(PRODUTOS.slice(0, 6));
                } // if
                this.previewOverflow.set((PRODUTOS || []).length - (this.preview() || []).length);
              });
        } else {
          AppService.searchHitsChanged$.next([]);
          this.preview.set([]);
          this.previewOverflow.set(0);
        } // else
      });

    this.#appLojasStore.lojaDepartamentosStateChanged$
      .pipe(takeUntil(this.#destroyAction$))
      .subscribe((departamentos: IDepartamento[]) => {
        // console.log(departamentos);
        this.#departamentosServ.geraMenu(
          departamentos,
          (deptos: IDepartamento[]) => {
            // console.log(deptos);
            this.departamentos.set((deptos || []).sort(compareValues('nome')));
          }
        );
      });

    this.vm$.set(
      combineLatest([
        AppService.isAtTop$,
        // this.#appLojasStore.lojaInfoStateChanged$,
        this.#appCarrinhoStore.carrinhoLojaStateChanged$
          .pipe(filter((carrinho: TNullable<ICarrinhoMap>) => !!carrinho)),
        this.#contasStore.contaStateChanged$
      ])
        .pipe(
          map(([isAtTop, carrinho, conta]) => {
            // console.log(carrinho);
            // console.log(carrinho?.__loja?.isRetirada);
            // console.log(carrinho?.__loja);
            // console.log(conta);
            this.#vm = {
              conta,
              isAtTop,
              // isMobile,
              isRetirada: !!carrinho?.__loja?.isRetirada,// !!lojaInfo?.retirada,
              // departamentos,
              route: this.#router?.url || ''
            };
            // console.log(this.#vm);
            return this.#vm;
          })
        )
    );
  }

  ngAfterViewInit() {
    this.#focus();
  }
  //#endregion

  //#region functions
  #focus() {
    setTimeout(
      () => this.onSearchFocusEvent$().emit(true),
      FOCUS_TIMEOUT
    );
  }

  tid_dep(index: any, item: IDepartamento | Partial<IDepartamento>): string { return item?.id || ''; }
  //#endregion

  //#region methods
  onGotoHome() {
    AppService.goHome$.emit();
  }

  onTrocarLojaClick() {
    // console.log(this.loja);
    // console.log(this.#appEnderecoStore.getState());
    this.#router.navigate(
      ['/lojas'],
      {
        queryParams: {
          locId: encodeURI(this.loja()?._idLocalidade || ''),
          // bairro: encodeURI(this.loja()?.endereco?.bairro || ''),
          bairro: encodeURI(this.#appEnderecoStore.getState()?.bairro || ''),
          redeId: encodeURI(this.loja()?.rede?.id || ''),
        }
      }
    );
  }

  onSearchSubmit() {
    const SEARCH_TERM: string = (this.searchTerm || '').trim();
    // console.log(SEARCH_TERM);
    if (!!SEARCH_TERM) {
      // this.#router.navigate(['/procura', SEARCH]);
      this.#router.navigate(
        ['/procura'],
        { queryParams: { filtro: SEARCH_TERM } }
      );
    } else {
      this.#router.navigate(
        [
          '/loja',
          this.loja()?.id || '',
          !!this.#vm?.isRetirada
        ]
      );
    } // else
  }

  onLimpaProcuraClick() {
    this.searchTerm = '';
    this.#focus();
  }

  onCarrinhoClick() {
    AppService.menuCarrinhoStatus$.next(true);
  }

  onLogoutClick() {
    AppService.logout$.emit('home');
  }

  onMenuLojaOpenClick() {
    AppService.menuLojaStatus$.next(true);
  }

  onTrocarEnderecoClick() {
    // console.log(this.#vm?.conta);
    if (!!this.#vm?.conta) {
      this.#router.navigateByUrl('/suaConta/seusEnderecos');
    } else {
      AppService.logout$.emit('/onde');
    } // else
  }

  onOutsidePreviewClick(e: Event) {
    // console.log('onOutsidePreviewClick', e);
    // console.log(this.searchRef?.nativeElement?.contains(e?.target));
    !this.searchRef?.nativeElement?.contains(e?.target) && this.onLimpaProcuraClick();
  }

  onProdutoClick(produto: IProduto) {
    // console.log(p);
    // this.searchPreviewFormRef.produtos = [];
    this.searchTerm = '';
    AppService.produtoDetalhes$.emit({ produto });
  }
  //#endregion
}
