<div class="w-card produto text-center"
     [class.off]="produto?.estoqueCritico">

  <!-- <p>produto {{ produto | json }}</p> -->
  <!-- <p>produto {{ produto?.__carrinho?.qtde | json }}</p> -->
  <!-- <p>tags() {{ tags() | json }}</p> -->

  <!-- <p>produto?.tags {{ produto?.tags | json }}</p> -->

  <div class="w-card--thumb p-relative">
    <img class="img-fluid pointer"
         [src]="produto?.__img?.thumb"
         (click)="imgClick$?.emit(produto)"
         *ngIf="!!produto?.__img?.thumb">
    <!--  <img class="img-fluid pointer"
         [ngSrc]="produto?.__img?.thumb"
         [width]="256"
         [height]="256"
         (click)="onImgProdutoClick(produto)"
         *ngIf="!!produto?.__img?.thumb"> -->
    <!-- <button mat-icon-button
              class="detalhe bg-light"
              *ngIf="showDetalhesBtn"
              (click)="onProdutoClick(produto)">
        <span class="icon-search o-50"></span>
      </button> -->

    <!-- <span class="label-desc"
            *ngIf="produto?._promocaoAutomatica?.percDesconto">
        -{{ produto?._promocaoAutomatica?.percDesconto | number:'1.0-0' }}%
      </span> -->

    <span class="p-tags d-flex flex-column">
      <small class="badge bg-{{ t?.theme }}"
             *ngFor="let t of produto?.tags">
        {{ t?.caption }}
      </small>
    </span>

    <span class="label-volume"
          *ngIf="!!produto?.__vol">
      <small> {{ produto?.__vol }} </small>
    </span>
  </div>

  <div class="w-card--content">
    <p class="nome m-auto">
      <small>{{ produto?.nome }}</small>
      <small class="o-50"
             *ngIf="produto?._base?.embalagem">
        &nbsp;<!-- &bullet;&nbsp; -->{{ produto?._base?.embalagem }}
      </small> <!-- {{ produto?.__relevancia }} -->
    </p>

    <!-- <p><small>{{ produto?.barcode }}</small></p> -->

    <!-- <div class="promocoes">
    <me-promocoes-produto [promocoes]="produto?.__promocoes"></me-promocoes-produto>
  </div> -->
  </div>

  <me-bs-produto-promocoes [produto]="produto" />

  <div class="w-card--footer">
    <div class="preco mt-auto">
      <me-html-produto-preco [produto]="produto" />
    </div>

    <!-- <div *ngIf="produto?.__disponivel; else produtoIndisponivel"> -->
    <div class="botao-comprar mt-auto"
         *ngIf="showQtdeBtn">
      <app-produto-btn-comprar [produto]="produto"
                               (change$)="onQtdeProdutoChanged(produto, $event)" />
      <!-- <ng-template #produtoIndisponivel>
    INDISPONÍVEL
  </ng-template> -->
    </div>
  </div>
</div>