//#region ng
import {
  Injectable,
  inject
} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router
} from '@angular/router';
//#endregion

//#region libs
import { calcLojaIdInfo } from '../_shared/_mercadeiro/_misc/_libs';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class IdLojaGuard implements CanActivate {
  //#region injects
  #router = inject(Router);
  //#endregion

  //#region methods
  canActivate(route: ActivatedRouteSnapshot): boolean {
    // http://localhost:4200/#/loja/varejao-modelo--mg--uberaba--merces--rua-antonio-borges-de-araujo/true
    const ID_LOJA: string = route.paramMap.get('id') || '';
    const RETIRADA: string = route.paramMap.get('retirada') || '';
    // console.log(ID_LOJA, RETIRADA);
    const OK: boolean = !!calcLojaIdInfo(ID_LOJA) && ['true', 'false'].includes(RETIRADA);
    !OK && this.#router.navigateByUrl('/onde');
    return OK;
  }
  //#endregion
}
