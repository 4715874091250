//#region ng
import {
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
  signal,
} from '@angular/core';
//#endregion

//#region mat
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
//#endregion

//#region firebase
// import { getApp } from 'firebase/app';
// import {
//   getFunctions,
//   httpsCallable,
// } from 'firebase/functions';
import {
  doc,
  Firestore,
  onSnapshot,
  Unsubscribe as FbUnsubscribe,
  collection,
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import {
  BehaviorSubject,
  Observable,
  Subscription,
  combineLatest
} from 'rxjs';
import {
  filter,
  finalize,
  first,
  map,
  startWith
} from 'rxjs/operators';
//#endregion

//#region models
interface IVm {
  conta: IConta;
  enderecos: ICorViacepApi[];
  loja: ILoja;
};
type TContaEnderecosTab = 'enderecos' | 'add';
import {
  IConta,
  ILoja
} from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { ODOCS_TAG } from '../../_shared/_core/_misc/_models/consts';
import { ICorViacepApi } from '../../_shared/_core/_misc/_models/_interfaces/_apis';
//#endregion

//#region libs
import { fixEndereco } from '../../_shared/_mercadeiro/_misc/_libs';
import { compareValues } from '../../_shared/_libs/_misc/_arrays';
//#endregion

//#region stores
import { ContasStore } from '../../_shared/_mercadeiro/_ng/_stores';
//#endregion

//#region services
import {
  ContaEnderecosService,
  ContasService,
  LojasService
} from '../../_shared/_mercadeiro/_ng/_services';
import {
  CorCepService,
  CorLoaderService
} from '../../_shared/_core/_ng/_services';
//#endregion

//#region components
import { CorMatConfirmDialog } from '../../_shared/_core/_ng-ui/_mat';
//#endregion

@Component({
  selector: 'app-conta-enderecos',
  templateUrl: './conta-enderecos.component.html',
  styleUrls: ['./conta-enderecos.component.scss']
})
export class AppContaEnderecosComponent {
  //#region actions
  #contaAction$ = new BehaviorSubject<IConta>(null);
  #enderecosAction$ = new BehaviorSubject<ICorViacepApi[]>(null);
  #lojaAction$ = new BehaviorSubject<ILoja>(null);
  //#endregion

  //#region inputs
  // idSel
  @Input() idSel: string = '';
  // isMobile
  @Input() isMobile: boolean = false;
  // loja
  #loja: ILoja;
  get loja(): ILoja { return this.#loja; }
  @Input() set loja(val: ILoja) {
    this.#loja = !!val ? this.#lojasServ.fix(val) : null;
    // this._verificaEnderecos();
    this.#lojaAction$.next(this.loja);
  }
  // conta
  #conta: IConta;
  get conta(): IConta { return this.#conta; }
  @Input({ required: true }) set conta(val: IConta) {
    this.#conta = !!val ? this.#contasServ.fix(val) : null;
    // console.log(this.conta);
    if (!!val) {
      const SUB: Subscription = this.#contaEnderecosServ.docs(val?.id)
        .pipe(first(), finalize(() => SUB?.unsubscribe()))
        .subscribe(
          (enderecos: ICorViacepApi[]) => {
            // console.log({ enderecos });
            this.#contaAction$.next(this.conta);
            this.#enderecosAction$.next(this.#cepServ.fixes(enderecos));
          }
        );

      const PATH: string = `/_contas/${val?.id}/conta-enderecos`;
      // console.log(PATH);
      !!this.#contasUnsub && this.#contasUnsub();
      this.#contasUnsub = onSnapshot(
        collection(this.#db, PATH),
        snaps => {
          const ENDERECOS: ICorViacepApi[] = [];
          // console.log(snaps?.docs);
          snaps?.docs?.forEach(snap => snap?.id !== ODOCS_TAG && ENDERECOS.push({ ...snap?.data(), id: snap?.id }));
          this.#enderecosAction$.next(
            this.#cepServ.fixes(
              // uniqueListBy(ENDERECOS, 'id')
              ENDERECOS
                .sort(compareValues('_criadoEm', 'desc'))
            )
          );
        }
      );
    } // if
  }
  //#endregion

  //#region outputs
  @Output() select$ = new EventEmitter<ICorViacepApi>();
  //#endregion

  //#region publics
  // enderecos = signal<ICorViacepApi[]>(null);
  tab = signal<TContaEnderecosTab>('enderecos');
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region privates
  #contasUnsub: FbUnsubscribe;
  #vm: IVm;
  //#endregion

  //#region injects
  #cepServ = inject(CorCepService);
  #contasServ = inject(ContasService);
  #contasStore = inject(ContasStore);
  #contaEnderecosServ = inject(ContaEnderecosService);
  #db = inject(Firestore);
  #loaderServ = inject(CorLoaderService);
  #lojasServ = inject(LojasService);
  // #lojasStore = inject(LojasStore);
  #modal = inject(MatDialog);
  #snackBar = inject(MatSnackBar);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.vm$.set(
      combineLatest([
        this.#contaAction$.pipe(startWith(null)),
        this.#enderecosAction$.pipe(startWith(null)),
        this.#lojaAction$.pipe(startWith(null)),
      ])
        .pipe(
          map(
            (([conta, enderecos, loja]) => {
              // console.log({ conta, enderecos, loja });
              if (
                !!conta
                && !!loja
                && !!enderecos
              ) {
                // const DISTRIBUIDORA_STATUS: boolean = !!loja?.distribuidora?.status;
                // const DISTRIBUIDORA_TIPO_ROTA: TDistribuidoraRota = loja?.distribuidora?.tipoRota;
                // const CONTA_FAVORITO_ID: string = conta?._idEnderecoFav || '';
                // console.log({ CONTA_FAVORITO_ID });
                // console.log(DISTRIBUIDORA_STATUS, DISTRIBUIDORA_TIPO_ROTA);
                /* if (
                  !!DISTRIBUIDORA_STATUS
                  && DISTRIBUIDORA_TIPO_ROTA === 'loc'
                ) {
                  const DISTRIBUIDORA_LOCALIDADES_IDS: string[] = (loja?.distribuidora?.localidades || [])
                    .map((l: any) => l?.id);
                  // console.log(DISTRIBUIDORA_LOCALIDADES_IDS);
                  enderecos = this.#cepServ.fixes(enderecos || [])
                    .map((e: ICorViacepApi) => {
                      // console.log(e);
                      const LOCALIDADE_ID: string = localidadeId(e);
                      // console.log(LOCALIDADE_ID);
                      const ENDERECO_ID: string = e?.id || '';
                      // const BAIRRO: string = e?.bairro || '';
                      // const LOCALIDADE: string = e?.bairro || '';
                      return {
                        ...e,
                        __favorito: ENDERECO_ID === CONTA_FAVORITO_ID,
                        __atendido: DISTRIBUIDORA_LOCALIDADES_IDS.includes(LOCALIDADE_ID)
                      };
                    });
                } else {
                  // const CONTA_BAIRRO: string = this.conta?.endereco?.bairro || '';
                  const LOJA_BAIRROS: string[] = loja?.entrega?._bairros || [];
                  enderecos = this.#cepServ.fixes(enderecos || [])
                    .map((e: ICorViacepApi) => {
                      const LOCALIDADE_ID: string = localidadeId(e);
                      const ENDERECO_ID: string = e?.id || '';
                      const BAIRRO: string = e?.bairro || '';
                      return {
                        ...e,
                        __favorito: ENDERECO_ID === CONTA_FAVORITO_ID,
                        __atendido: !!loja
                          ? (LOJA_BAIRROS.includes(BAIRRO) || LOJA_BAIRROS.includes('*'))
                          && LOCALIDADE_ID === localidadeId(loja?.endereco)
                          : true
                      };
                    });
                } // else */
              } // if

              this.#vm = {
                conta,
                enderecos: (enderecos || []).map((e: ICorViacepApi) => fixEndereco(e, loja, conta)),
                loja,
              };
              return this.#vm;
            })
          )
        )
    );
  }

  ngOnDestroy(): void {
    this.#contasUnsub && this.#contasUnsub();
  }
  //#endregion

  //#region functions
  tid_end(index: any, item: ICorViacepApi): string { return item?.id || ''; }
  //#endregion

  //#region methods
  onFavoritarEnderecoClick(e: ICorViacepApi) {
    if (!!e) {
      const DIALOG_EXISTS = this.#modal.getDialogById('confirm-favoritar');
      if (!DIALOG_EXISTS) {
        const DIALOG_REF: any = this.#modal.open(
          CorMatConfirmDialog,
          {
            id: 'confirm-favoritar',
            panelClass: 'no-border-dialog-container',
            data: {
              title: `${e?.logradouro}, ${e?.nro} ${e?.complemento}`.trim(),
              cancelCaption: 'Não',
              confirmCaption: 'Sim',
              msg: `Deseja tornar esse endereço favorito ?`,
            }
          }
        );

        const SUB: Subscription = DIALOG_REF
          .afterClosed()
          .pipe(first(), finalize(() => SUB?.unsubscribe()))
          .pipe(filter((val: any) => !!val))
          .subscribe(async (resp: any) => {
            const CONTA_ID: string = this.conta?.id || '';
            // console.log(resp, CONTA_ID, ENDERECO_ID);
            if (
              !!CONTA_ID
              && !!e?.id
            ) {
              const CHANGES: Partial<IConta> = { _idEnderecoFav: e?.id };
              // console.log({ CHANGES });
              const SUB: Subscription = this.#loaderServ.showUntilCompleted(
                this.#contasServ.update(CONTA_ID, CHANGES)
                  .pipe(first(), finalize(() => SUB?.unsubscribe()))
              )
                .subscribe(() => {
                  this.#contasStore.setState({ ...this.conta, ...CHANGES });
                  this.#contaAction$.next({ ...this.conta, ...CHANGES });
                  this.#snackBar.open('Endereço favorito modificado.');
                });
            } // if
          });
      } // if
    } // if
  }

  onDelEnderecoClick(e: ICorViacepApi) {
    // console.log(e);
    if (!!e) {
      const DIALOG_EXISTS = this.#modal.getDialogById('confirm-del');
      if (!DIALOG_EXISTS) {
        const DIALOG_REF: any = this.#modal.open(
          CorMatConfirmDialog,
          {
            id: 'confirm-del',
            panelClass: 'no-border-dialog-container',
            data: {
              title: `${e?.logradouro}, ${e?.nro} ${e?.complemento}`.trim(),
              cancelCaption: 'Não',
              confirmCaption: 'Sim',
              msg: `Deseja remover esse endereço ?`,
            }
          }
        );

        const SUB: Subscription = DIALOG_REF
          .afterClosed()
          .pipe(first(), finalize(() => SUB?.unsubscribe()))
          .pipe(filter((val: any) => !!val))
          .subscribe(async (resp: boolean) => {
            // console.log({ resp });
            // console.log(this.conta?.id, e?.id);
            if (
              !!this.conta?.id
              && !!e?.id
            ) {
              const SUB: Subscription = this.#loaderServ.showUntilCompleted(
                this.#contaEnderecosServ.delete(this.conta?.id, e?.id)
                  .pipe(first(), finalize(() => SUB?.unsubscribe()))
              )
                .subscribe(() => {
                  this.#snackBar.open('Endereço removido.');
                });
            } // if
          });
      } // if
    } // if
  }

  onSelEnderecoClick(e: ICorViacepApi) {
    !!e && this.select$.emit(e);
  }

  onAddEndereco(e: ICorViacepApi) {
    // console.log(e);
    const CONTA_ID: string = this.conta?.id || '';
    // console.log(CONTA_ID);
    if (!!CONTA_ID) {
      // this.#loaderServ.lstart();
      const SUB: Subscription = this.#loaderServ.showUntilCompleted(
        this.#contaEnderecosServ.add(CONTA_ID, e)
          .pipe(first(), finalize(() => SUB?.unsubscribe()))
      )
        .subscribe(() => {
          this.tab.set('enderecos');
          this.#snackBar.open('Endereço adicionado.');
        });
    } // if
  }
  //#endregion
}
