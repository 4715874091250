//#region ng
import {
  Component,
  computed,
  inject,
  Signal,
  signal
} from '@angular/core';
import {
  ActivatedRoute,
  Params,
} from '@angular/router';
//#endregion

//#region 3rd
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  Subject,
  Subscription,
} from 'rxjs';
import {
  finalize,
  first,
  map,
  takeUntil
} from 'rxjs/operators';
//#endregion

//#region models
interface IVm {
  isAtTop: boolean;
  isMobile: boolean;
  loja: ILoja;
};
import { IProduto } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { PRODUTOS_POR_PAG } from '../../models/consts';
// const PRODUTOS_POR_PAG = 2;
import { ICorPaginatorSort } from '../../_shared/_core/_misc/_models/_interfaces/_misc';
import { PRODUTOS_ORD } from '../../_shared/_mercadeiro/_misc/_models/consts';
import { ILoja } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { ICarrinhoMap } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_maps';
//#endregion

//#region libs
import { compareValues } from '../../_shared/_libs/_misc/_arrays';
import { fixProdutos } from '../../_shared/_mercadeiro/_misc/_libs';
import { onDestroy } from '../../_shared/_core/_ng/_libs';
//#endregion

//#region services
import { AppService } from '../../services';
import {
  CarrinhoService,
  ProdutosService
} from '../../_shared/_mercadeiro/_ng/_services';
//#endregion

//#region stores
import { LojasStore } from '../../_shared/_mercadeiro/_ng/_stores';
import { AppCarrinhoStore } from '../../stores';
//#endregion

@Component({
  selector: 'app-procura',
  templateUrl: './procura.page.html',
  styleUrls: ['./procura.page.scss']
})
export class ProcuraPage {
  //#region actions
  #destroyAction$: Subject<void> = onDestroy();
  ordAction$ = signal<BehaviorSubject<ICorPaginatorSort>>(new BehaviorSubject<ICorPaginatorSort>(PRODUTOS_ORD[0])).asReadonly();
  prodsPorPagAction$ = signal<BehaviorSubject<number>>(new BehaviorSubject<number>(PRODUTOS_POR_PAG)).asReadonly();
  //#endregion

  //#region publics
  searchTerm = signal<string>('');
  hits = signal<IProduto[]>(null);
  // produtos = computed(() => (this.hits() || []).slice(0, this.produtosPorPag()));
  produtos: IProduto[];
  itensRestantes: Signal<number> = computed(() => (this.hits() || [])?.length - (this.produtos || [])?.length);
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region privates
  #produtosPorPag: number = PRODUTOS_POR_PAG;
  //#endregion

  //#region injects
  #appCarrinhoStore = inject(AppCarrinhoStore);
  #carrinhoServ = inject(CarrinhoService);
  #lojasStore = inject(LojasStore);
  #produtosServ = inject(ProdutosService);
  #route = inject(ActivatedRoute);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.#route.queryParams
      .pipe(takeUntil(this.#destroyAction$))
      .subscribe((query: Params) => this.searchTerm.set(query?.['filtro'] || ''));

    this.#appCarrinhoStore.carrinhoItensStateChanged$
      .pipe(
        takeUntil(this.#destroyAction$)
      )
      .subscribe(
        (carrinho: ICarrinhoMap) => {
          // console.log(carrinho);
          !!this.produtos && fixProdutos(
            this.produtos,
            !!carrinho ? this.#carrinhoServ.fix(carrinho) : null,
          );
        }
      );

    combineLatest([
      AppService.searchHitsChanged$,
      this.ordAction$(),
      this.prodsPorPagAction$(),
    ])
      .pipe(takeUntil(this.#destroyAction$))
      .subscribe(([hits, ord, prodsPorPag]) => {
        // console.log(ord);
        let i = 1;
        const HITS: IProduto[] = (hits || [])
          .map((p: IProduto) => {
            return {
              ...p,
              __relevancia: i++
            }
          })
          .sort(
            compareValues(
              ord?.field,
              ord?.direction,
            )
          );
        /* this.produtos = fixProdutos(
          (hits || [])
            .map((p: IProduto) => {
              return {
                ...p,
                __relevancia: i++
              }
            })
            .sort(
              compareValues(
                ord?.field,
                ord?.direction,
              )
            ),
          carrinho,
        ); */
        this.hits.set(HITS);

        const SUB: Subscription = this.#produtosServ.lfixes(
          HITS.slice(0, prodsPorPag)
        )
          .pipe(
            first(),
            finalize(() => SUB?.unsubscribe())
          )
          .subscribe(
            (prods: IProduto[]) => {
              // console.log(prods);
              this.produtos = prods;
              // this.produtosAction$().next(prods);
              this.#appCarrinhoStore.setState(this.#appCarrinhoStore.carrinhoGet());
            }
          );

        this.#appCarrinhoStore.setState(this.#appCarrinhoStore.carrinhoGet());
      });

    this.vm$.set(
      combineLatest([
        AppService.isMobile$,
        AppService.isAtTop$,
        this.#lojasStore.lojaStateChanged$,
      ])
        .pipe(
          map(([isMobile, isAtTop, loja]) => {
            // console.log(banners);
            const VM: IVm = {
              isMobile,
              isAtTop,
              loja
            };
            // console.log(VM);
            return VM;
          }),
        )
    );
  }
  //#endregion

  //#region functions
  tid_pro(index: any, item: IProduto): string { return item?.id || ''; }
  //#endregion

  //#region methods
  onGotoHome() {
    AppService.goHome$.emit();
  }

  onCarrinhoClick() {
    AppService.menuCarrinhoStatus$.next(true);
  }

  onProdutoClick(produto: IProduto) {
    AppService.produtoDetalhes$.next({ produto });
  }

  onCarregarMaisClick() {
    this.#produtosPorPag = this.#produtosPorPag += PRODUTOS_POR_PAG;
    this.prodsPorPagAction$().next(this.#produtosPorPag);
    // this.produtosPorPag.update((prev: number) => prev += PRODUTOS_POR_PAG);
  }
  //#endregion

  /*
  //#region publics
  carrinho: ICarrinhoMap;
  //#endregion

  //#region lifecycles
  ngOnInit(): void {
    this._carrinhoStore.carrinhoStateChanged$
      .subscribe((carrinho: ICarrinhoMap | null) => {
        // console.log(carrinho);
        if (!!carrinho) {
          this.carrinho = this._carrinhoServ.fix(carrinho);
          this._reorder();
          /* this.produtos = * /
          // AppService.menuCarrinhoStatus$.next(true);
        } // if
      }),
  //#region methods

  onSearchSubmit() {
    const SEARCH: string = (this.searchTerm || '').trim();
    // console.log(SEARCH);
    if (!!SEARCH) {
      // this.#router.navigate(['/procura', SEARCH]);
      this.#router.navigate(
        ['/procura'],
        {
          queryParams: {
            filtro: SEARCH,
            ord: this.ord?.id || ''
          }
        }
      );
    } else {
      this._goHome();
    } // else
  }
  //#endregion
  */
}
