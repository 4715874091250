<!-- <p>pedido() {{ pedido() | json }}</p> -->
<!-- <p>pedido()?.carrinho?.__solicitado?.total?.liq {{ pedido()?.carrinho?.__solicitado?.total?.liq }}</p> -->
<!-- <p>pedido()?.carrinho?.__loja {{ pedido()?.carrinho?.__loja | json }}</p> -->
<!-- <p>pedido()?.carrinho?.__loja?.faturamento?.servicos {{ pedido()?.carrinho?.__loja?.faturamento?.servicos | json }}</p> -->
<!-- <p>pedido()?.carrinho?.__loja?.taxaEntrega {{ pedido()?.carrinho?.__loja?.taxaEntrega | json }}</p> -->
<!-- <p>pedido() {{ pedido() | json }}</p> -->

<form novalidate
      [formGroup]="resumoForm()"
      *ngIf="!!resumoForm() && !!pedido()">

  <ul class="list-group">
    <li class="list-group-item d-flex">
      <div class="span my-auto">
        Subtotal
      </div>

      <span class="my-auto ms-auto">
        {{ pedido()?.carrinho?.__solicitado?.total?.prod | currency: "BRL":"symbol":"1.2-2" }}
      </span>
    </li>

    <li class="list-group-item d-flex">
      <div class="span my-auto">
        Serviços
      </div>

      <span class="my-auto ms-auto">
        {{ pedido()?.carrinho?.__loja?.faturamento?.servicos?.__valCliente | currency: "BRL":"symbol":"1.2-2" }}
      </span>
    </li>

    <li class="list-group-item d-flex">
      <div class="span my-auto">
        Entrega
      </div>

      <span class="my-auto ms-auto d-flex flex-column text-end">
        <div *ngIf="!!pedido()?.carrinho?.__loja?.taxaEntrega?.desc">
          <small class="o-60 line-thru">{{ pedido()?.carrinho?.__loja?.taxaEntrega?.val | currency:
            "BRL":"symbol":"1.2-2" }}</small>
        </div>

        <div>
          {{ pedido()?.carrinho?.__loja?.taxaEntrega?._liquido | currency: "BRL":"symbol":"1.2-2" }}
        </div>
      </span>
    </li>

    <li class="list-group-item d-flex">
      <div class="span my-auto">
        <strong>Total a pagar</strong>
      </div>

      <span class="my-auto ms-auto rem-11">
        {{ pedido()?.carrinho?.__solicitado?.total?.liq | currency: "BRL":"symbol":"1.2-2" }}
      </span>
    </li>

    <li class="list-group-item d-flex flex-column"
        *ngIf="pedido()?.formaPgto?.id === 'din'">
      <div class="d-flex">
        <div class="span my-auto o-50">
          <small>Dinheiro</small>
        </div>

        <small class="my-auto ms-auto o-50">
          {{ pedido()?.formaPgto?.__trocoPara | currency: "BRL":"symbol":"1.2-2" }}
        </small>
      </div>

      <div class="d-flex">
        <div class="span my-auto">
          <small>Troco</small>
        </div>

        <small class="my-auto ms-auto">
          {{ pedido()?.formaPgto?.__trocoPara - pedido()?.carrinho?.__solicitado?.total?.liq| currency:
          "BRL":"symbol":"1.2-2" }}
        </small>
      </div>
    </li>

    <li class="list-group-item d-flex"
        formGroupName="docNota"
        *ngIf="!!isCpf()">
      <mat-checkbox formControlName="status">
        Quero cpf na nota.
      </mat-checkbox>
    </li>

    <li class="list-group-item d-flex">
      <mat-form-field class="w-100"
                      [appearance]="!!isMobile ? 'fill' : 'outline'">
        <mat-label>Obs do pedido</mat-label>
        <textarea matInput
                  formControlName="obs">
                    </textarea>
      </mat-form-field>
    </li>

    <li class="list-group-item text-center o-70">
      Os produtos estão sujeitos à disponibilidade de estoque no momento da separação e a quantidade solicitada será
      levemente diferente para produtos fracionados.
    </li>
  </ul>

  <div class="d-flex mt-3"
       *ngIf="!!isPedidoOk">
    <button mat-flat-button
            color="primary"
            class="flex-1"
            (click)="submit$?.emit()">
      CONCLUIR O PEDIDO
    </button>
  </div>
</form>