<form novalidate
      [formGroup]="pedidoContatoForm()"
      *ngIf="!!pedidoContatoForm() && !!conta">
  <div class="row"
       formGroupName="contato">
    <div class="col-12 col-lg-4">
      <mat-form-field class="w-100"
                      [appearance]="!!isMobile ? 'fill' : 'outline'">
        <mat-label> Nome </mat-label>
        <input matInput
               type="text"
               formControlName="nome"
               [focus]="nomeFocusEvent$()"
               onReturn>
        <mat-hint *ngIf="fv()?.error(nomeRef)">
          <span class="danger">
            {{ fv()?.error(nomeRef) }}
          </span>
        </mat-hint>
      </mat-form-field>
    </div>

    <div class="col-12 col-lg-4"
         formGroupName="fones">
      <mat-form-field class="w-100"
                      [appearance]="!!isMobile ? 'fill' : 'outline'">
        <mat-label>Telefone celular</mat-label>
        <input matInput
               type="text"
               formControlName="celular"
               mask="(00) 00000-0000"
               [showMaskTyped]="false"
               onReturn>
        <mat-hint *ngIf="fv()?.error(foneCelRef) || foneCelRef?.errors?.['foneCel']">
          <span class="danger">
            {{ fv()?.error(foneCelRef) || 'Telefone inválido.' }}
          </span>
        </mat-hint>
      </mat-form-field>
    </div>

    <div class="col-12 col-lg-4"
         formGroupName="fones">
      <mat-form-field class="w-100"
                      [appearance]="!!isMobile ? 'fill' : 'outline'">
        <mat-label>Telefone fixo</mat-label>
        <input type="text"
               matInput
               formControlName="fixo"
               mask="(00) 0000-0000"
               [showMaskTyped]="false"
               onReturn>
        <mat-hint *ngIf="fv()?.lerror(foneFixoRef) || foneFixoRef?.errors?.['foneFixo']">
          <span class="danger">
            {{ fv()?.error(foneFixoRef) || 'Telefone inválido.' }}
          </span>
        </mat-hint>
      </mat-form-field>
    </div>
  </div>
</form>
