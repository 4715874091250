<ng-container *ngIf="vm$() | async as vm">
  <div class="d-flex justify-content-between rem-14">
    <div class="my-auto">
      <span class="icon-b-shopping-cart p-rem">
        <span *ngIf="!!vm?.carrinho?.__solicitado?.qtde?.itens"
              class="itens-tag">
          {{ vm?.carrinho?.__solicitado?.qtde?.itens }}
        </span>
      </span>
    </div>

    <div class="my-auto">
      {{ vm?.carrinho?.__solicitado?.total?.liq | currency: "BRL":"symbol":"1.2-2" }}
    </div>
  </div>
</ng-container>
