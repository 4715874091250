//#region ng
import {
  Injectable,
  inject
} from '@angular/core';
//#endregion

//#region 3rd
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest,
} from 'rxjs';
import {
  finalize,
  first,
} from 'rxjs/operators';
import {
  cloneDeep,
  get,
  set
} from 'lodash';
//#endregion

//#region models
import {
  IDepartamento,
  IFormaPgto,
  ILoja,
  IProduto
} from '../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import {
  ICarrinhoMap,
  IItemCarrinhoMap
} from '../_shared/_mercadeiro/_misc/_models/_interfaces/_maps';
import {
  KEY_CARRINHO,
  THEME
} from '../models/consts';
import { TNullable } from '../_shared/_core/_misc/_models/_types';
import { ILojaIdInfoRet } from '../_shared/_mercadeiro/_misc/_models/_interfaces/_rets';
//#endregion

//#region libs
import {
  calcLojaIdInfo,
  calcQtdeFracionado,
  isQtdeMin,
  mapCarrinhoInfo
} from '../_shared/_mercadeiro/_misc/_libs';
import { nextId } from '../_shared/_libs/_misc/_numbers';
//#endregion

//#region services
import {
  CarrinhoService,
  DepartamentosService,
  FormasPgtoService,
  LojasService,
  ProdutosService
} from '../_shared/_mercadeiro/_ng/_services';
import {
  CorLoaderService,
  CorStorageService
} from '../_shared/_core/_ng/_services';
import { ItensCarrinhoService } from '../_shared/_mercadeiro/_ng/_services';
//#endregion

//#region stores
import { AppLojasStore } from '.';
import { LojasStore } from '../_shared/_mercadeiro/_ng/_stores';
import { compareValues } from '../_shared/_libs/_misc/_arrays';
import { IFormasPgtoGrupos } from '../_shared/_mercadeiro/_misc/_models/_interfaces/_misc';
import { ICarrinhoPendenteEvent } from '../_shared/_mercadeiro/_misc/_models/_interfaces/_events';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class AppCarrinhoStore {
  //#region carrinhoState
  #carrinhoState: TNullable<Partial<ICarrinhoMap>>;
  getState(): TNullable<Partial<ICarrinhoMap>> { return !!this.#carrinhoState ? this.#carrinhoServ.fix(this.#carrinhoState) : null; }
  setState(val: TNullable<Partial<ICarrinhoMap>>) {
    const NEW_LOJA_ID: string = val?.__loja?.id || '';
    const NEW_LOJA_IS_RETIRADA: boolean = !!val?.__loja?.isRetirada;
    // console.log(JSON.stringify(val));    
    // console.log(val?.__loja?.isRetirada, NEW_LOJA_ID);
    if (!!val && !!NEW_LOJA_ID) {
      // console.log(this.#carrinhoState);
      // console.log(val);
      const OLD_LOJA_ID = this.#carrinhoState?.__loja?.id || '';
      const OLD_CARRINHO_QTDE_ITENS: number = (this.#carrinhoState?.__solicitado?.itens || []).length;
      // console.log('OLD_LOJA_ID', OLD_LOJA_ID);
      // console.log('NEW_LOJA_ID', NEW_LOJA_ID);
      const NEW_CARRINHO: ICarrinhoMap = this.#carrinhoServ.fix(
        {
          ...val,
          id: val?.id || nextId().toString(),
          __loja: {
            ...val?.__loja,
            id: NEW_LOJA_ID,
            isRetirada: NEW_LOJA_IS_RETIRADA
          },
          _separado: mapCarrinhoInfo(this.#carrinhoServ.fix(val), true),
          __solicitado: mapCarrinhoInfo(this.#carrinhoServ.fix(val), false)
        }
      );
      // console.log('NEW_CARRINHO', NEW_CARRINHO);
      // console.log(OLD_LOJA_ID, NEW_LOJA_ID);
      if (OLD_LOJA_ID !== NEW_LOJA_ID) {
        const LOJA_INFO: TNullable<ILojaIdInfoRet> = calcLojaIdInfo(NEW_LOJA_ID);
        // console.log(LOJA_INFO);
        const LOJA_PATH: string = LOJA_INFO?.lojaPath || '';
        // console.log(NEW_LOJA_ID, RETIRADA);
        const LOJA$: Observable<ILoja> = this.#lojasServ.doc(NEW_LOJA_ID);
        const DEPTOS$: Observable<Partial<IDepartamento>[]> = this.#departamentosServ.docs(LOJA_PATH);
        const FORMAS$: Observable<IFormaPgto[]> = this.#formasPgtoServ.docs();
        const SUB: Subscription = this.#loaderServ.showUntilCompleted(
          combineLatest([LOJA$, DEPTOS$, FORMAS$])
            .pipe(first(), finalize(() => SUB?.unsubscribe()))
        )
          .subscribe(
            ([loja, deptos, formas]) => {
              // console.log(NEW_LOJA_IS_RETIRADA);              
              // NEW_CARRINHO.__loja.nome = loja?.nome || '';
              const LOJA_TO: ILoja = this.#lojasServ.fix(loja);
              NEW_CARRINHO.__loja = {
                faturamento: LOJA_TO?.faturamento || null,
                id: LOJA_TO?.id || '',
                integradora: LOJA_TO?.integradora || null,
                isRetirada: NEW_LOJA_IS_RETIRADA,
                nome: LOJA_TO?.nome || '',
                parceiro: LOJA_TO?.parceiro || null,
                pedidos: LOJA_TO?.pedidos || null,
              };
              // console.log(NEW_CARRINHO);

              if (!!OLD_CARRINHO_QTDE_ITENS) {
                const CARRINHO_PENDENTE: ICarrinhoPendenteEvent = {
                  to: {
                    idLoja: loja?.id || '',
                    nomeLoja: loja?.nome || '',
                    isRetirada: NEW_LOJA_IS_RETIRADA,
                  }
                };
                // console.log(CARRINHO_PENDENTE);
                this.#carrinhoPendenteSubject$.next(CARRINHO_PENDENTE);
              } else {
                // carrinho = {
                //   ...this.#carrinhoServ.fix({ id: nextId().toString() }),
                //   ...carrinho
                // };
                // console.log('this.#carrinhoLojaStateSubject$ !==');
                this.#carrinhoLojaStateSubject$.next(NEW_CARRINHO);
                // console.log('this.#carrinhoItensStateSubject$ !==');
                this.#carrinhoItensStateSubject$.next(NEW_CARRINHO);
                // console.log('this.#carrinhoStateSubject$ !==');
                // this.#carrinhoStateSubject$.next(NEW_CARRINHO);
                this.#carrinhoState = this.#carrinhoServ.fix(NEW_CARRINHO);
                this.carrinhoSet(this.#carrinhoServ.fix(NEW_CARRINHO));
                this.#lojasStore.setState(loja, THEME);
                // const LOJA_INFO: ILojaInfoState = {
                //   id: NEW_LOJA_ID,
                //   retirada: IS_RETIRADA
                // };
                // this.lojaInfoSet(LOJA_INFO);
                // this.#lojaInfoStateSubject$.next(LOJA_INFO);

                // console.log(loja, deptos);
                // console.log(formas);
                const LOJA: ILoja = !!loja ? this.#lojasServ.fix(loja) : null;
                // console.log(IS_RETIRADA);            
                const ENTREGA_RETIRADA = !!NEW_LOJA_IS_RETIRADA ? 'retirada' : 'entrega';
                set(NEW_CARRINHO, '__loja.nome', LOJA?.nome || '');
                set(NEW_CARRINHO, '__loja.faturamento', LOJA?.faturamento || null);
                set(NEW_CARRINHO, '__loja.id', LOJA?.id || '');
                set(NEW_CARRINHO, '__loja.integradora', LOJA?.integradora || null);
                set(NEW_CARRINHO, '__loja.isRetirada', NEW_LOJA_IS_RETIRADA);
                set(NEW_CARRINHO, '__loja.parceiro', LOJA?.parceiro || null);
                set(NEW_CARRINHO, '__loja.pedidos', LOJA?.pedidos || null);
                this.#carrinhoLojaStateSubject$.next(NEW_CARRINHO);
                this.carrinhoSet(this.#carrinhoServ.fix(NEW_CARRINHO));
                this.#appLojaStore.setLojaDepartamentosState(
                  this.#departamentosServ.fixes(deptos as Partial<IDepartamento>[])
                    .sort(compareValues('nome'))
                );
                const FORMAS: IFormasPgtoGrupos = {
                  o: (get(LOJA, `formas.${ENTREGA_RETIRADA}.din`) || [])
                    .concat((get(LOJA, `formas.${ENTREGA_RETIRADA}.pix`) || []))
                    .concat((get(LOJA, `formas.${ENTREGA_RETIRADA}.tic`) || []))
                    .map((lojaId: string) => {
                      const ITEM: Partial<IFormaPgto> = (formas || [])
                        // .find((f: Partial<IFormaPgto>) => lojaId === f?.id);
                        .find((f: Partial<IFormaPgto>) => {
                          // console.log(lojaId, f?.id);
                          return lojaId === f?.id;
                        });
                      // console.log(ITEM);
                      if (!!ITEM) {
                        return this.#formasPgtoServ.fix(ITEM);
                      } // if
                      return null;
                    }),
                  c: (get(LOJA, `formas.${ENTREGA_RETIRADA}.cre`) || [])
                    .map((lojaId: string) => {
                      const ITEM: Partial<IFormaPgto> = (formas || [])
                        .find((f: Partial<IFormaPgto>) => lojaId === f?.id);
                      // console.log(ITEM);
                      if (!!ITEM) {
                        return this.#formasPgtoServ.fix(ITEM);
                      } // if
                      return null;
                    }),
                  d: (get(LOJA, `formas.${ENTREGA_RETIRADA}.deb`) || [])
                    .map((lojaId: string) => {
                      const ITEM: Partial<IFormaPgto> = (formas || [])
                        .find((f: Partial<IFormaPgto>) => lojaId === f?.id);
                      // console.log(ITEM);
                      if (!!ITEM) {
                        return this.#formasPgtoServ.fix(ITEM);
                      } // if
                      return null;
                    }),
                };
                this.#appLojaStore.setLojaFormasGruposState(
                  {
                    o: (FORMAS?.o || []).filter((f: IFormaPgto) => !!f),
                    c: (FORMAS?.c || []).filter((f: IFormaPgto) => !!f),
                    d: (FORMAS?.d || []).filter((f: IFormaPgto) => !!f),
                  }
                );
                this.#lojasStore.setState(LOJA, THEME);
              } // else
            }
          );
      } else {
        // console.log(NEW_CARRINHO);
        // console.log('this.#carrinhoStateSubject$ ===');
        // this.#carrinhoStateSubject$.next(NEW_CARRINHO);
        // console.log('this.#carrinhoItensStateSubject$ ===');
        this.#carrinhoLojaStateSubject$.next(NEW_CARRINHO);
        this.#carrinhoItensStateSubject$.next(NEW_CARRINHO);
        this.#carrinhoState = this.#carrinhoServ.fix(NEW_CARRINHO);
        this.carrinhoSet(this.#carrinhoServ.fix(NEW_CARRINHO));
        // this.#carrinhoStateSubject$.next(this.#carrinhoState);
      } // else
    } else {
      this.#carrinhoState = null;
      this.#carrinhoLojaStateSubject$.next(null);
      this.#carrinhoItensStateSubject$.next(null);
      // this.#carrinhoStateSubject$.next(null);
      this.#lojasStore.setState(null, THEME);
      // this.carrinhoRemove();
    } // else
  }
  //#endregion

  //#region events
  // carrinhoStateChanged$
  // #carrinhoStateSubject$ = new BehaviorSubject<TNullable<ICarrinhoMap>>(null);
  // carrinhoStateChanged$: Observable<TNullable<ICarrinhoMap>> = this.#carrinhoStateSubject$?.asObservable();
  // carrinhoLojaStateChanged$
  #carrinhoLojaStateSubject$ = new BehaviorSubject<TNullable<ICarrinhoMap>>(null);
  carrinhoLojaStateChanged$: Observable<TNullable<ICarrinhoMap>> = this.#carrinhoLojaStateSubject$?.asObservable();
  // carrinhoItensStateChanged$
  #carrinhoItensStateSubject$ = new BehaviorSubject<TNullable<ICarrinhoMap>>(null);
  carrinhoItensStateChanged$: Observable<TNullable<ICarrinhoMap>> = this.#carrinhoItensStateSubject$?.asObservable();
  // carrinhoItemAdded$
  //  #carrinhoItemAddedSubject$: Subject<IItemCarrinhoMap> = new Subject<IItemCarrinhoMap>();
  // carrinhoItemAdded$: Observable<IItemCarrinhoMap> = this.#carrinhoItemAddedSubject$?.asObservable();
  // carrinhoItemRemoved$
  // private #carrinhoItemRemovedSubject$: Subject<IItemCarrinhoMap> = new Subject<IItemCarrinhoMap>();
  // carrinhoItemRemoved$: Observable<IItemCarrinhoMap> = this.#carrinhoItemRemovedSubject$?.asObservable();
  // carrinhoItemModified$
  // private #carrinhoItemModifiedSubject$: Subject<IItemCarrinhoMap> = new Subject<IItemCarrinhoMap>();
  // carrinhoItemModified$: Observable<IItemCarrinhoMap> = this.#carrinhoItemModifiedSubject$?.asObservable();
  // carrinhoItemChanged$
  // private #carrinhoItemSubject$: Subject<IItemCarrinhoMap> = new Subject<IItemCarrinhoMap>();
  // carrinhoItemChanged$: Observable<IItemCarrinhoMap> = this.#carrinhoItemSubject$?.asObservable();
  // carrinhoPendenteFound$
  #carrinhoPendenteSubject$ = new BehaviorSubject<ICarrinhoPendenteEvent>(null);
  carrinhoPendenteFound$: Observable<ICarrinhoPendenteEvent> = this.#carrinhoPendenteSubject$?.asObservable();
  //#endregion

  //#region injects
  #appLojaStore = inject(AppLojasStore);
  #carrinhoServ = inject(CarrinhoService);
  #departamentosServ = inject(DepartamentosService);
  #formasPgtoServ = inject(FormasPgtoService);
  #itensCarrinhoServ = inject(ItensCarrinhoService);
  #loaderServ = inject(CorLoaderService);
  #lojasServ = inject(LojasService);
  #lojasStore = inject(LojasStore);
  #storageServ = inject(CorStorageService);
  #produtosServ = inject(ProdutosService);
  //#endregion

  //#region constructor
  constructor() {
    const CARRINHO: TNullable<ICarrinhoMap> = this.carrinhoGet();
    // console.log(CARRINHO);
    this.setState(CARRINHO);
  }
  //#endregion

  //#region methods
  setQtde(
    produto: IProduto,
    step: number
  ): void {
    const CARRINHO: TNullable<ICarrinhoMap> = !!this.getState()
      ? cloneDeep(this.getState())
      : null;
    const SOLICITADO_ITENS: IItemCarrinhoMap[] = CARRINHO?.__solicitado?.itens || [];
    const STEP: number = Number(step.toFixed(3)) || 0;
    // console.log(STEP, produto?.id, CARRINHO?.__solicitado, SOLICITADO_ITENS?.length);
    if (
      !!CARRINHO
      && !!produto
      && !!STEP
    ) {
      //   // console.log(!!carrinho, !!produto, STEP);
      const PRODUTO: IProduto = this.#produtosServ.fix(produto);
      // console.log(PRODUTO?.id);
      // console.log(get(PRODUTO, '__carrinho'));
      // console.log(PRODUTO);
      // console.log(SOLICITADO_ITENS);
      let item: IItemCarrinhoMap | undefined = SOLICITADO_ITENS
        .find((i: IItemCarrinhoMap) => i?.produto?.id === PRODUTO?.id);
      // console.log(item);
      if (!!item) { // Ajusta e usa ocorrência encontrada.
        // const STEP: number = ITEM.tipoQtde === 'F' ? parseFloat(step.toFixed(3)) || 0 :;
        const NEW_QTDE: number = (item?.__solicitado?.qtde?.val || 0) + STEP;
        // ITEM.__solicitado.qtde = Number(ITEM.__solicitado.qtde.toFixed(3)) || 0;
        // console.log(NEW_QTDE);
        if (NEW_QTDE > 0) {
          set(item, '__solicitado.qtde.fixed', calcQtdeFracionado(produto, NEW_QTDE));
          set(item, '__solicitado.qtde.val', NEW_QTDE);
          set(item, '__solicitado.qtde.isMin', isQtdeMin(produto, NEW_QTDE));
          // this.#carrinhoItemModifiedSubject$.next(ITEM);
          // this.#carrinhoItemSubject$.next(ITEM);
        } else {
          // CARRINHO.itens = (get(CARRINHO, 'itens') || []).filter((i: IItemCarrinhoMap) => get(i, 'produto.id') !== get(ITEM, 'produto.id'));
          // console.log(CARRINHO.itens.length);
          set(
            CARRINHO,
            '__solicitado.itens',
            (CARRINHO?.__solicitado?.itens || []).filter((i: IItemCarrinhoMap) => i?.id !== item?.id)
          );
          // console.log(CARRINHO.itens.length);
          // this?.#carrinhoItemRemovedSubject$?.next(ITEM);
          // this?.#carrinhoItemSubject$?.next(ITEM);
        } // if
        // console.log(item);        
      } else {
        const ITEM: TNullable<IItemCarrinhoMap> = this.#itensCarrinhoServ?.produto2Item(PRODUTO, step);
        // console.log(ITEM);
        if (!!ITEM) {
          (CARRINHO?.__solicitado?.itens || []).push(ITEM);
          // this?.#carrinhoItemAddedSubject$?.next(ITEM);
          // this?.#carrinhoItemSubject$?.next(ITEM);
        } // if
        // console.log(ITEM);
      } // else
      // console.log(CARRINHO);
      this.setState(this.#carrinhoServ.fix(CARRINHO));
    } // if
  }
  //#endregion

  //#region storage KEY_CARRINHO
  carrinhoGet(): ICarrinhoMap {
    const VAL: any = this.#storageServ.ls_get(KEY_CARRINHO);
    return !!VAL && typeof VAL === 'string' ? JSON.parse(VAL) : null;
  }

  carrinhoSet(carrinho: ICarrinhoMap): ICarrinhoMap {
    if (!!carrinho) {
      this.#storageServ.ls_set(
        KEY_CARRINHO,
        JSON.stringify(carrinho)
      );
      return this.carrinhoGet();
    } else {
      return this.carrinhoRemove();
    } // else
  }

  carrinhoRemove(): ICarrinhoMap {
    this.#storageServ.ls_remove(KEY_CARRINHO);
    return this.carrinhoGet();
  }
  //#endregion
}
