//#region ng
import {
  Component,
  EventEmitter,
  Input,
  inject,
  signal,
} from '@angular/core';
//#endregion

//#region 3rd
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest
} from 'rxjs';
import {
  finalize,
  first,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { OwlOptions } from 'ngx-owl-carousel-o';
//#endregion

//#region models
import {
  ILoja,
  IProduto
} from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
//#endregion

//#region libs
// import { fixProdutos } from '../../_shared/_mercadeiro/_misc/_libs';
import { onDestroy } from '../../_shared/_core/_ng/_libs';
//#endregion

//#region models
interface IVm {
  carrinho: ICarrinhoMap;
  itemCarrinho: IItemCarrinhoMap;
  produtoSel: IProduto;
  // similares: IProduto[];
};
import { FOCUS_TIMEOUT } from '../../_shared/_core/_misc/_models/consts';
import {
  ICarrinhoMap,
  IItemCarrinhoMap
} from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_maps';
//#endregion

//#region services
import { ProdutosService } from '../../_shared/_mercadeiro/_ng/_services';
import {
  CorLoaderService,
  CorMessagesService
} from '../../_shared/_core/_ng/_services';
import { AppService } from '../../services';
//#endregion

//#region stores
import {
  ContasStore,
  LojasStore
} from '../../_shared/_mercadeiro/_ng/_stores';
import { AppCarrinhoStore } from '../../stores';
import { TNullable } from 'src/app/_shared/_core/_misc/_models/_types';
import { fixProdutos } from 'src/app/_shared/_mercadeiro/_misc/_libs';
//#endregion

@Component({
  selector: 'app-produto-detalhes-modal',
  templateUrl: './produto-detalhes.modal.html',
  styleUrls: ['./produto-detalhes.modal.scss']
})
export class AppProdutoDetalhesModal {
  //#region actions
  #destroyAction$: Subject<void> = onDestroy();
  produtoSelAction$ = signal<BehaviorSubject<IProduto>>(new BehaviorSubject<IProduto>(null));
  //#endregion

  //#region inputs
  // showNav
  @Input() showNav: boolean;
  // isMobile
  @Input() isMobile: boolean = false;
  // idModal
  @Input() idModal: number;
  // idModal
  #produto: IProduto;
  get produto(): IProduto { return this.#produto; }
  @Input({ required: true }) set produto(val: IProduto) {
    this.#produto = !!val ? this.#produtosServ.fix(val) : null;
    // console.log(val);
    // this.produtoSelAction$().next(this.produto);
    // this.produtoSel.set(this.produto);

    this.#lojasStore.lojaStateChanged$
      .pipe(takeUntil(this.#destroyAction$))
      .subscribe((loja: ILoja) => {
        // console.log(loja);
        const LOJA_PATH: string = loja?.__idInfo?.lojaPath || '';
        const NCM: string = this.produto?.ncm || '';
        this.#similares = [];
        if (!!LOJA_PATH && !!NCM) {
          const SUB: Subscription = this.#loaderServ.showUntilCompleted(
            this.#produtosServ.produtosNcm(LOJA_PATH, NCM)
              .pipe(
                switchMap((docs: IProduto[]) => this.#produtosServ?.lfixes(docs|| [])),
                first(),
                finalize(() => SUB?.unsubscribe())
              )
          )
            .subscribe(
              (similares: IProduto[]) => {
                // console.log(similares);
                this.#similares = this.#produtosServ.fixes(similares);
                this.produtoSelAction$().next(this.produto);
              }
            );
        } else {
          this.produtoSelAction$().next(this.produto);
        } // else
      });
  }
  //#endregion

  //#region publics
  similares: IProduto[];
  similaresOptions = signal<OwlOptions>(
    {
      // autoHeight: true,
      // autoplay: true,
      // autoplayTimeout: 3000,
      autoWidth: true,
      // center: false,
      // mouseDrag: true,
      // touchDrag: true,
      // pullDrag: true,
      dots: false,
      loop: false,
      mergeFit: false,
      navSpeed: 700,
      navText: ['<i class="icon-b-arrow-l"></i>', '<i class="icon-b-arrow-r"></i>'],
      responsive: {
        0: {
          items: 2.5
        },
        400: {
          items: 3.5
        },
        740: {
          items: 4.5
        },
        940: {
          items: 5.5
        },
      },
      nav: true
    }
  );
  obs: string;
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region obs
  // #obs: string;
  // get obs(): string { return this.#obs; }
  // set obs(val: string) {
  //   const OLD_VAL: string = this.#obs;
  //   this.#obs = (val || '').trim();
  //   if (
  //     val === null
  //     && !!this.#vm?.carrinho
  //   ) {
  //     const CARRINHO: ICarrinhoMap = {
  //       ...this.#vm?.carrinho,
  //       __solicitado: {
  //         itens: (this.#vm?.carrinho?.__solicitado?.itens || [])
  //           .map(
  //             (i: IItemCarrinhoMap) => {
  //               // console.log(i?.produto?.id, this.#vm?.produtoSel?.id);
  //               if (i?.produto?.id === this.#vm?.produtoSel?.id) {
  //                 return {
  //                   ...i,
  //                   obs: OLD_VAL
  //                 };
  //               } // if
  //               return i;
  //             }
  //           )
  //       }
  //     };
  //     // console.log(CARRINHO);
  //     this.#appCarrinhoStore.carrinhoSet(CARRINHO);
  //   } // if
  // }
  //#endregion

  //#region privates
  #similares: IProduto[];
  #vm: IVm;
  //#endregion

  //#region events
  obsFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>());
  //#endregion

  //#region injects
  #appCarrinhoStore = inject(AppCarrinhoStore);
  #appServ = inject(AppService);
  #bsModalServ = inject(BsModalService);
  // #carrinhoServ = inject(CarrinhoService);
  #contasStore = inject(ContasStore);
  #produtosServ = inject(ProdutosService);
  #lojasStore = inject(LojasStore);
  #msgServ = inject(CorMessagesService);
  #loaderServ = inject(CorLoaderService);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.#msgServ.send();
    this.vm$.set(
      combineLatest([
        // this.#appCarrinhoStore.carrinhoStateChanged$,
        this.#appCarrinhoStore.carrinhoItensStateChanged$,
        this.produtoSelAction$(),
      ])
        .pipe(
          map(
            ([carrinho, produtoSel]) => {
              // console.log(produtoSel);
              // console.log(carrinho);

              // const PRODUTO_SEL: IProduto = fixProdutos([produtoSel], carrinho)?.[0];
              fixProdutos([produtoSel], carrinho)?.[0];
              // console.log(PRODUTO_SEL);

              this.similares = (this.#similares || [])
                .filter((p: IProduto) => p?.id !== produtoSel?.id);
              fixProdutos(this.similares, carrinho);
              // console.log(SIMILARES);
              // !!SIMILARES?.length && console.log(JSON.stringify(SIMILARES[0]?.__carrinho));

              let itemCarrinho: TNullable<Partial<IProduto>> = ((carrinho?.__solicitado?.itens || [])
                .find((i: IItemCarrinhoMap) => i?.produto?.id === produtoSel?.id)) || null;
              // itemCarrinho = !!itemCarrinho ? fixProdutos([this.#produtosServ.fix(itemCarrinho)], carrinho)?.[0] : null;
              // const itemCarrinho = (carrinho?.__solicitado?.itens || [])
              //   .find((i: IItemCarrinhoMap) => i?.produto?.id === PRODUTO_SEL?.id);
              // if (!!itemCarrinho) { this.obs = itemCarrinho?.obs || ''; }
              // console.log(itemCarrinho);

              this.#vm = {
                carrinho,
                produtoSel,
                itemCarrinho: itemCarrinho,
                // similares
              };
              // console.log(this.#vm);
              return this.#vm;
            }
          )
        )
    );
  }

  ngAfterViewInit() {
    this.#focus();
  }
  //#endregion

  //#region functions
  #focus() {
    !this.isMobile && setTimeout(
      () => { this.obsFocusEvent$().emit(true); },
      FOCUS_TIMEOUT
    );
  }
  //#endregion

  //#region methods
  onProdutoSelClick(p: IProduto | null) {
    // console.log(this.#vm?.itemCarrinho);
    // console.log(this.#vm?.carrinho?.__solicitado?.itens);
    // const CARRINHO: ICarrinhoMap = {
    //   ...this.#vm?.carrinho,
    //   __solicitado: {
    //     itens: (this.#vm?.carrinho?.__solicitado?.itens || [])
    //       .map(
    //         (i: IItemCarrinhoMap) => {
    //           // console.log(i?.produto?.id, this.#vm?.produtoSel?.id);
    //           if (i?.produto?.id === this.#vm?.produtoSel?.id) {
    //             return {
    //               ...i,
    //               obs: this.obs
    //             };
    //           } // if
    //           return i;
    //         }
    //       )
    //   }
    // };
    // console.log(CARRINHO);
    // this.#appCarrinhoStore.carrinhoSet(CARRINHO);
    this.produtoSelAction$().next(p);
  }

  onModalCloseClick(data: any = null) {
    this.onProdutoSelClick(null);
    this.#bsModalServ.hide(this.idModal);
  }

  onQtdeProdutoChanged(p: IProduto, step: number) {
    // console.log(p, step, !!this._contasServ.getState());
    if (!!this.#contasStore.getState()) {
      // p && this._carrinhoServ.setState(this._carrinhoServ.carrinhoQtdeChange(p, step));
      !!p && this.#appCarrinhoStore.setQtde(p, step);
      // console.log(this._carrinhoServ.getState());
    } else {
      this.#appServ.produtoLogin = p;
      // this.#produtosServ.produtoLogin = p;
      AppService.identificacao$.emit(p);
    } // else
  }

  onVerCarrinhoClick() {
    this.onModalCloseClick();
    AppService.menuCarrinhoStatus$.next(true);
  }
  //#endregion
}
