<div class="modal-body p-relative">
  <mat-toolbar class="header"
               color="primary">
    <mat-toolbar-row class="d-flex justify-content-between">
      <h3>Selecionando endereço</h3>

      <button mat-icon-button
              (click)="onModalCloseClick()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="container-fluid p-4">
    <app-conta-enderecos [isMobile]="isMobile"
                         [idSel]="idEndSel"
                         [conta]="conta"
                         (select$)="onModalCloseClick($event)" />
  </div>
</div>
