//#region ng
import {
  Component,
  Input,
} from '@angular/core';
//#endregion

//#region models
import { IFormaPgto } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
//#endregion

@Component({
  selector: 'app-formas-pgto-list',
  templateUrl: './formas-pgto-list.component.html',
  styleUrls: ['./formas-pgto-list.component.scss']
})
export class AppFormasPgtoListComponent {
  //#region inputs
  @Input({ required: true }) formas: IFormaPgto[];
  //#endregion

  //#region functions
  tid_for(index: any, item: IFormaPgto): string { return item?.id || ''; }
  //#endregion
}
