//#region ng
import {
  Injectable,
  inject
} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
} from '@angular/router';
//#endregion

//#region models
import { ICarrinhoMap } from '../_shared/_mercadeiro/_misc/_models/_interfaces/_maps';
import { TNullable } from '../_shared/_core/_misc/_models/_types';
//#endregion

//#region services
import { AppService } from '../services';
//#endregion

//#region stores
import { AppCarrinhoStore } from '../stores';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class CarrinhoLojaGuard implements CanActivate {
  //#region injects
  #appCarrinhoStore = inject(AppCarrinhoStore);
  // #appLojasStore = inject(AppLojasStore);
  // #router = inject(Router);
  //#endregion

  //#region methods
  canActivate(route: ActivatedRouteSnapshot): boolean {
    const CARRINHO: TNullable<ICarrinhoMap> = this.#appCarrinhoStore.carrinhoGet();
    const NEW_LOJA_ID: string = CARRINHO?.__loja?.id || '';
    const OK: boolean = !!NEW_LOJA_ID;
    !OK && AppService.goHome$.emit();
    return OK;
  }
  //#endregion
}
