//#region ng
import {
  Component,
  inject,
  signal
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//#endregion

//#region 3rd
import { Subscription } from 'rxjs';
//#endregion

//#region services
import { AppService } from '../../services';
//#endregion

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.page.html',
  styleUrls: ['./not-found.page.scss']
})
export class NotFoundPage {
  //#region publics
  url = signal<string>('');
  //#endregion

  //#region privates
  #subs: Subscription[] = [];
  //#endregion

  //#region injects
  #route = inject(ActivatedRoute);
  //#endregion

  //#region lifecycles
  ngOnInit(): void {
    this.#subs.push(
      this.#route
        .queryParams
        .subscribe((queries: any) => {
          console.log(queries);
          this.url.set(decodeURI(queries?.url || ''));
        }),
    );
  }

  ngOnDestroy(): void {
    this.#subs.forEach((s: Subscription) => s?.unsubscribe());
  }
  //#endregion

  //#region methods
  onGotoHome() {
    AppService.goHome$.emit();
  }
  //#endregion
}
