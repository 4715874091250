<div class="modal-body p-relative">
  <mat-toolbar class="header"
               color="primary">
    <mat-toolbar-row class="d-flex justify-content-between">
      <div class="my-auto">
        <h3>Completando endereço</h3>
      </div>

      <div class="my-auto">
        <button mat-icon-button
                (click)="onModalCloseClick()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="container-fluid p-4">
    <cor-mat-manage-endereco [isMobile]="isMobile"
                             [endereco]="endereco"
                             submitCaption="CONFIRMAR"
                             (submit$)="onModalCloseClick($event)" />
  </div>
</div>
