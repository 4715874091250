<swal #loaderSwal
      title="Por favor, aguarde!"
      text="Estamos analisando seu pedido."
      [showConfirmButton]="false"
      [showCancelButton]="false"
      (didOpen)="onSwalLoaderDidOpen()">
</swal>

<swal #concluirLoaderSwal
      title="Por favor, aguarde!"
      text="Estamos concluindo seu pedido."
      [showConfirmButton]="false"
      [showCancelButton]="false"
      (didOpen)="onSwalLoaderDidOpen()">
</swal>

<ng-container *ngIf="vm$() | async as vm">
  <div class="header"
       *ngIf="!!vm?.loja && !!vm?.conta">
    <div *ngIf="!!vm?.isMobile; else headerDesktop">
      <app-loja-m-header [showReturn]="true"
                         [showMenu]="false"
                         (returnClick$)="onGoHomeClick()" />
    </div>
    <ng-template #headerDesktop>
      <div class="header--desktop">
        <div class="container">
          <div class="d-flex mb-3 py-3">
            <div class="my-auto">
              <img class="pointer img-fluid m-auto"
                   [src]="vm?.loja?.rede?.__logo">
            </div>

            <div class="ms-3 my-auto">
              <strong>{{ vm?.loja?.nome }}</strong>
              <div>
                {{ vm?.loja?.endereco?.bairro }}&nbsp;&bullet;&nbsp;
                <span class="o-50">
                  {{ vm?.loja?.endereco?.logradouro }}, {{ vm?.loja?.endereco?.nro }}
                  {{ vm?.loja?.endereco?.complemento }}
                </span>
              </div>
            </div>

            <div class="ms-auto my-auto">
              <button mat-flat-button
                      class="btn-voltar"
                      (click)="onGoHomeClick()">
                <span class="icon-r-chevron-l o-50 me-2"></span> Continuar comprando
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>

  <!-- <p>vm {{ vm | json }}</p> -->
  <!-- <p>vm?.descTaxaEntrega {{ vm?.descTaxaEntrega | json }}</p> -->
  <!-- <p>vm?.taxaEntrega {{ vm?.taxaEntrega | json }}</p> -->
  <!-- <p>isPedidoOk() {{ isPedidoOk() }}</p> -->
  <!-- <p>isPedidoOk() {{ isPedidoOk() }}</p> -->
  <!-- <p>vm?.taxaEntrega {{ vm?.taxaEntrega | json }}</p> -->

  <div class="container"
       *ngIf="!!vm?.loja && !!vm?.carrinho && !!vm?.conta">

    <div class="d-flex">
      <div class="flex-2">
        <mat-stepper [linear]="true"
                     [orientation]="!!vm?.isMobile ? 'vertical' : 'horizontal'"
                     #stepper
                     (selectionChange)="onStepChanged($event)">
          <mat-step [stepControl]="contatoFormRef?.pedidoContatoForm()">
            <ng-template matStepLabel>Contato</ng-template>

            <h5 class="mb-2 text-center">
              Confirme informações de contato desse pedido.
            </h5>

            <app-pedido-contato-form [isMobile]="vm?.isMobile"
                                     [conta]="vm?.conta" />

            <div class="d-flex my-2">
              <div class="ms-auto">
                <button type="button"
                        mat-flat-button
                        class="pointer ms-2"
                        color="primary"
                        (click)="onVerificaContato()">
                  <span>Próximo</span>
                  <mat-icon class="o-50">chevron_right</mat-icon>
                </button>
              </div>
            </div>
          </mat-step>

          <mat-step [class.me-1]="!!vm?.isMobile"
                    [stepControl]="retiradaEntregaFormRef?.retiradaEntregaForm()">
            <ng-template matStepLabel>{{ !!vm?.isRetirada ? 'Retirada' : 'Entrega' }} </ng-template>
            <h5 class="mb-2 text-center">
              Como você quer receber seu pedido?
            </h5>

            <div class="alert alert-warning text-center"
                 role="alert"
                 *ngIf="!!vm?.descTaxaEntrega?.pendente">
              <strong>{{ vm?.descTaxaEntrega?.perc }}%</strong> desconto na taxa de entrega para pedidos acima de
              <strong>{{ vm?.descTaxaEntrega?.minTotalCarrinho | currency: "BRL":"symbol":"1.2-2" }}</strong>.
            </div>

            <app-pedido-retirada-entrega [isRetirada]="vm?.isRetirada"
                                         (isRetirada$)="onSetRetiradaClick($event)"
                                         (goHome$)="onGoHomeClick()" />
            <!-- (enderecoSel$)="onEnderecoSelected($event)" -->

            <div class="d-flex my-2">
              <div class="me-auto">
                <button type="button"
                        mat-flat-button
                        class="pointer"
                        color="light"
                        matStepperPrevious>
                  <mat-icon class="o-50">chevron_left</mat-icon>
                  <span> Anterior </span>
                </button>
              </div>

              <div class="ms-auto">
                <button type="button"
                        mat-flat-button
                        class="pointer ms-2"
                        color="primary"
                        (click)="onVerificaRetiradaEntrega()">
                  <span>Próximo </span>
                  <mat-icon class="o-50">chevron_right</mat-icon>
                </button>
              </div>
            </div>
          </mat-step>

          <mat-step [stepControl]="horarioFormRef?.horarioForm()">
            <ng-template matStepLabel>Horário</ng-template>

            <app-pedido-horario [isMobile]="vm?.isMobile"
                                [isRetirada]="vm?.isRetirada" />

            <div class="d-flex my-2">
              <div class="me-auto">
                <button type="button"
                        mat-flat-button
                        class="pointer"
                        color="light"
                        matStepperPrevious>
                  <mat-icon class="o-50">chevron_left</mat-icon>
                  <span> Anterior </span>
                </button>
              </div>

              <div class="ms-auto">
                <button type="button"
                        mat-flat-button
                        class="pointer ms-2"
                        color="primary"
                        (click)="onVerificaHorario()">
                  <span>Próximo </span>
                  <mat-icon class="o-50">chevron_right</mat-icon>
                </button>
              </div>
            </div>
          </mat-step>

          <mat-step>
            <ng-template matStepLabel>Pagamento</ng-template>

            <div class="text-center">
              <h5>
                Como você prefere pagar seu pedido?
              </h5>
            </div>

            <app-pedido-pagamento [isRetirada]="vm?.isRetirada"
                                  [changes]="vm?.changes"
                                  (formaChange$)="onVerificaPgto()" />

            <div class="d-flex my-2">
              <div class="me-auto">
                <button type="button"
                        mat-flat-button
                        class="pointer"
                        color="light"
                        matStepperPrevious>
                  <mat-icon class="o-50">chevron_left</mat-icon>
                  <span> Anterior </span>
                </button>
              </div>

              <div class="ms-auto">
                <button type="button"
                        mat-flat-button
                        class="pointer ms-2"
                        color="primary"
                        (click)="onVerificaPgto()">
                  <span>Próximo </span>
                  <mat-icon class="o-50">chevron_right</mat-icon>
                </button>
              </div>
            </div>
          </mat-step>

          <mat-step>
            <ng-template matStepLabel>Confirmação</ng-template>
            <h5 class="mb-2">
              Verifique seu pedido
            </h5>

            <ul class="list-group mb-2">
              <li class="list-group-item d-flex">
                <div class="my-auto">
                  <div *ngIf="!vm?.isMobile; else voltarContato">
                    <span class="icon-o-chat-empty o-50 me-2"></span>
                  </div>
                  <ng-template #voltarContato>
                    <button mat-icon-button
                            color="light"
                            (click)="onGotoStep(0)">
                      <mat-icon class="o-50">chevron_left</mat-icon>
                    </button>
                  </ng-template>
                </div>

                <div class="my-auto">
                  <span class="o-50">
                    {{ vm?.changes?._info?.contato?.legenda }}
                  </span><br>
                  <small>
                    {{ vm?.changes?._info?.contato?.val }}
                  </small>
                </div>

                <div class="ms-auto my-auto"
                     *ngIf="!vm?.isMobile">
                  <button type="button"
                          mat-flat-button
                          class="pointer"
                          color="light"
                          (click)="onGotoStep(0)">
                    <mat-icon class="o-50">chevron_left</mat-icon>
                    <span> Mudar </span>
                  </button>
                </div>
              </li>

              <li class="list-group-item d-flex">
                <div class="my-auto">
                  <div *ngIf="!vm?.isMobile; else voltarRetiradaEntrega">
                    <span class="icon-ob-pin o-50 me-2"></span>
                  </div>
                  <ng-template #voltarRetiradaEntrega>
                    <button mat-icon-button
                            color="light"
                            (click)="onGotoStep(1)">
                      <mat-icon class="o-50">chevron_left</mat-icon>
                    </button>
                  </ng-template>
                </div>

                <div class="my-auto">
                  <span class="o-50">
                    {{ vm?.changes?._info?.retiradaEntrega?.legenda }}
                  </span><br>
                  <small>
                    {{ vm?.changes?._info?.retiradaEntrega?.val }}
                  </small>
                </div>

                <div class="ms-auto my-auto"
                     *ngIf="!vm?.isMobile">
                  <button type="button"
                          mat-flat-button
                          class="pointer"
                          color="light"
                          (click)="onGotoStep(1)">
                    <mat-icon class="o-50">chevron_left</mat-icon>
                    <span> Mudar </span>
                  </button>
                </div>
              </li>

              <li class="list-group-item d-flex">
                <div class="my-auto">
                  <div *ngIf="!vm?.isMobile; else voltarHorario">
                    <span class="icon-o-clock-2 o-50 me-2"></span>
                  </div>
                  <ng-template #voltarHorario>
                    <button mat-icon-button
                            color="light"
                            (click)="onGotoStep(2)">
                      <mat-icon class="o-50">chevron_left</mat-icon>
                    </button>
                  </ng-template>
                </div>

                <div class="my-auto">
                  <span class="o-50">
                    {{ vm?.changes?._info?.horario?.legenda }}
                  </span><br>
                  <small>
                    {{ vm?.changes?._info?.horario?.val }}
                  </small>
                </div>

                <div class="ms-auto my-auto"
                     *ngIf="!vm?.isMobile">
                  <button type="button"
                          mat-flat-button
                          class="pointer"
                          color="light"
                          (click)="onGotoStep(2)">
                    <mat-icon class="o-50">chevron_left</mat-icon>
                    <span> Mudar </span>
                  </button>
                </div>
              </li>

              <li class="list-group-item d-flex">
                <div class="my-auto">
                  <div *ngIf="!vm?.isMobile; else voltarformaPgto">
                    <span class="icon-b-dolar o-50 me-2"></span>
                  </div>
                  <ng-template #voltarformaPgto>
                    <button mat-icon-button
                            color="light"
                            (click)="onGotoStep(3)">
                      <mat-icon class="o-50">chevron_left</mat-icon>
                    </button>
                  </ng-template>
                </div>

                <div class="my-auto">
                  <span class="o-50">
                    {{ vm?.changes?._info?.formaPgto?.legenda }}
                  </span><br>
                  <small>
                    {{ vm?.changes?._info?.formaPgto?.val }}
                  </small>
                </div>

                <div class="ms-auto my-auto"
                     *ngIf="!vm?.isMobile">
                  <button type="button"
                          mat-flat-button
                          class="pointer"
                          color="light"
                          (click)="onGotoStep(3)">
                    <mat-icon class="o-50">chevron_left</mat-icon>
                    <span> Mudar </span>
                  </button>
                </div>
              </li>
            </ul>

            <div class="d-flex mt-3">
              <h5 class="my-auto">
                Seus produtos
              </h5>

              <div class="ms-auto my-auto">
                <button type="button"
                        mat-flat-button
                        class="pointer"
                        color="light"
                        (click)="onGoHomeClick()">
                  <mat-icon class="o-50">chevron_left</mat-icon>
                  <span> Modificar <span *ngIf="!vm?.isMobile">pedido</span> </span>
                </button>
              </div>
            </div>

            <me-html-pedido-itens [pedido]="vm?.changes"
                                  [isMobile]="vm?.isMobile" />

            <div class="mb-2"
                 *ngIf="!!vm?.isMobile">
              <app-pedido-resumo [cpfCnpj]="vm?.cpfCnpj"
                                 [changes]="vm?.changes"
                                 [isPedidoOk]="isPedidoOk()"
                                 (submit$)="onSubmit()" />
            </div>
          </mat-step>
        </mat-stepper>
      </div>

      <div class="flex-1 ms-2 p-3"
           *ngIf="!vm?.isMobile">
        <app-pedido-resumo [cpfCnpj]="vm?.cpfCnpj"
                           [changes]="vm?.changes"
                           [isPedidoOk]="isPedidoOk()"
                           (submit$)="onSubmit()" />
      </div>
    </div>
  </div>

</ng-container>