//#region ng
import {
  Component,
  Input,
  inject,
  signal,
} from '@angular/core';
//#endregion

//#region 3rd
import { BsModalService } from 'ngx-bootstrap/modal';
//#endregion

//#region models
import { TNullable } from '../../_shared/_core/_misc/_models/_types';
import { IConta } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { ICorViacepApi } from '../../_shared/_core/_misc/_models/_interfaces/_apis';
//#endregion

//#region services
import { CorCepService } from '../../_shared/_core/_ng/_services';
//#endregion

@Component({
  selector: 'seleciona-endereco-modal',
  templateUrl: './seleciona-endereco.modal.html',
  styleUrls: ['./seleciona-endereco.modal.scss']
})
export class SelecionaEnderecoModal {
  //#region inputs
  // isMobile
  @Input() isMobile: boolean = false;
  // idModal
  @Input({ required: true }) idModal: number;
  // idEndSel
  @Input() idEndSel: string = '';
  // conta
  @Input() conta: IConta;
  //#endregion

  //#region publics
  data = signal<ICorViacepApi>(null);
  //#endregion

  //#region injects
  _bsModalServ = inject(BsModalService);
  _cepServ = inject(CorCepService);
  //#endregion

  //#region methods
  onModalCloseClick(data: TNullable<ICorViacepApi> = null) {
    // data = { cep: '38600100' };
    this.data.set(!!data ? this._cepServ.fix(data) : null);
    this._bsModalServ.hide(this.idModal);
  }
}
