//#region ng
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
//#endregion

//#region mat
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
// import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
//#endregion

//#region 3rd
// import { SwiperModule } from 'swiper/angular';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MarkdownModule } from 'ngx-markdown';
//#endregion

//#region modules
import { MeMatComponentsModule } from '../../_shared/_mercadeiro/_ng-ui/_mat/me-mat-components.module';
import { CorMatComponentsModule } from '../../_shared/_core/_ng-ui/_mat/cor-mat-components.module';
import { AppComponentsModule } from '../../components/components.module';
import { MeHtmlComponentsModule } from '../../_shared/_mercadeiro/_ng-ui/_html/me-html-components.module';
import { CorDirectivesModule } from '../../_shared/_core/_ng/_directives/cor-directives.module';
import { MePipesModule } from '../../_shared/_mercadeiro/_ng/_pipes/me-pipes.module';
import { MeBsComponentsModule } from '../../_shared/_mercadeiro/_ng-ui/_bs/me-bs-components.module';
//#endregion

//#region components
import { AppProdutoDetalhesModal } from '..';
//#endregion

@NgModule({
  imports: [
    AppComponentsModule,
    CarouselModule,
    CorDirectivesModule,
    CommonModule,
    // CorBsComponentsModule,
    CorMatComponentsModule,
    // CorPipesModule,
    FormsModule,
    MarkdownModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    // MatStepperModule,
    MatToolbarModule,
    MeBsComponentsModule,
    MeHtmlComponentsModule,
    MeMatComponentsModule,
    MePipesModule,
    // SwiperModule
  ],
  declarations: [
    AppProdutoDetalhesModal,
  ],
  exports: [
    AppProdutoDetalhesModal,
  ],
})
export class AppProdutoDetalhesModalModule { }
