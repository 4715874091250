<ng-container *ngIf="vm$() | async as vm">
  <div class="modal-header">
    <mat-toolbar color="primary">
      <mat-toolbar-row class="d-flex">
        <div class="flex-1 d-flex pt-3">
          <app-carrinho></app-carrinho>
        </div>

        <div class="icon-area my-auto">
          <button mat-icon-button
                  mat-dialog-close
                  (click)="onMenuCloseClick()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>

  <div class="modal-body flex-1 d-flex flex-column preview"
       *ngIf="!!vm?.carrinho"
       [class.carrinho-vazio]="!vm?.carrinho?.__solicitado?.itens?.length">
    <div *ngIf="!!vm?.carrinho?.__solicitado?.itens?.length">
      <ul class="list-group">
        <li *ngFor="let i of vm?.carrinho?.__solicitado?.itens; trackBy:tid_ite"
            class="list-group-item d-flex flex-column p-relative">
          <div class="d-flex">
            <div class="thumb pointer">
              <img [src]="i?.produto?.__img?.thumb"
                   class="img-fluid"
                   (click)="onProdutoClick(i?.produto)">
            </div>

            <div class="flex-1 d-flex flex-column ms-2 info">
              <div>
                <small>{{ i?.produto?.nome }}</small>
                <small class="o-50"
                       *ngIf="!!i?.produto?._base?.embalagem">
                  ( {{ i?.produto?._base?.embalagem }} )
                </small>
              </div>

              <div *ngIf="!!i?.obs">
                <small class="o-50 italic mt-1">
                  Obs: {{ i?.obs }}
                </small>
              </div>

              <div class="mt-2">
                <me-bs-produto-promocoes [produto]="i?.produto" />
              </div>
            </div>
          </div>

          <div class="d-flex mt-1">
            <div class="qtde flex-1">
              <div class="my-auto">
                <app-item-btn-comprar [item]="i"
                                      [light]="true"
                                      (change$)="onQtdeItemChanged(i, $event)" />
              </div>
            </div>

            <div class="preco flex-1 d-flex">
              <div class="m-auto">
                <me-html-item-carrinho-preco [item]="i"
                                             [isInline]="false" />
              </div>
            </div>

            <div class="icon-area my-auto">
              <button mat-icon-button
                      [matMenuTriggerFor]="itemCarrinhoMenu"
                      (click)="itemSel.set( i)">
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
          </div>
        </li>
      </ul>

      <div class="d-flex">
        <div class="flex-1 me-3">
          <button mat-stroked-button
                  class="w-100 m-2"
                  (click)="onLimparCarrinhoClick()"
                  [disabled]="!vm?.carrinho?.__solicitado?.qtde?.itens">
            LIMPAR CARRINHO
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="!vm?.carrinho?.__solicitado?.itens?.length"
         class="d-flex flex-1">
      <div class="m-auto d-flex flex-column text-center">
        <span class="icon-b-shopping-cart o-20 rem-70"></span>
        <strong class="msg o-20">CARRINHO VAZIO</strong>
      </div>
    </div>
  </div>

  <div class="modal-footer"
       *ngIf="!!vm?.carrinho?.__solicitado?.itens?.length">
    <mat-toolbar color="primary">
      <mat-toolbar-row class="d-flex">
        <button mat-raised-button
                class="btn flex-1 bg-primary tint"
                routerLink="/pedido"
                *ngIf="vm?.carrinho?.__limites?.[vm?.entregaRetiradaCaption]?.perc >= 1; else minimoNaoAtingido">
          <span class="o-70 my-auto">
            FINALIZAR
          </span>
          <strong class="mx-1 my-auto">
            {{ vm?.carrinho?.__solicitado?.total?.liq | currency: "BRL":"symbol":"1.2-2" }}
          </strong>
          <span class="icon-r-chevron-r o-50 my-auto"></span>
        </button>
        <ng-template #minimoNaoAtingido>
          <small class="o-80 m-auto">
            Mínimo de <strong>{{ vm?.carrinho?.__limites?.[vm?.entregaRetiradaCaption]?.val | currency:
              "BRL":"symbol":"1.2-2" }}</strong> não atingido.
          </small>
        </ng-template>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>

  <!-- Início menus -->
  <mat-menu #itemCarrinhoMenu="matMenu">
    <button mat-menu-item
            (click)="onProdutoClick()">
      <span class="icon icon-r-search o-50"></span>&nbsp;
      Detalhes do produto
    </button>

    <button mat-menu-item
            (click)="onItemDelClick()">
      <span class="icon icon-o-trashbin o-50"></span>&nbsp;
      Remover produto
    </button>

    <button mat-menu-item>
      <span class="icon icon-b-arrow-l o-50"></span>&nbsp;
      Voltar
    </button>
  </mat-menu>
  <!-- Final menus -->
</ng-container>