//#region ng
import {
  Component,
  Input,
  inject,
} from '@angular/core';
//#endregion

//#region 3rd
import { BsModalService } from 'ngx-bootstrap/modal';
//#endregion

@Component({
  selector: 'app-identificacao-modal',
  templateUrl: './identificacao.modal.html',
  styleUrls: ['./identificacao.modal.scss']
})
export class AppIdentificacaoModal {
  //#region inputs
  // emailLoginButtonCaption
  @Input() emailLoginButtonCaption: string;
  // isMobile
  @Input() isMobile: boolean = false;
  // idModal
  @Input() idModal: number;
  //#endregion

  //#region injects
  #bsModalServ = inject(BsModalService);
  //#endregion

  //#region methods
  onModalCloseClick() {
    this.#bsModalServ.hide(this.idModal);
  }
  //#endregion

}
