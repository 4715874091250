//#region ng
import {
  Injectable,
  inject
} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
} from '@angular/router';
//#endregion

//#region 3rd
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
//#endregion

//#region models
import { ICarrinhoPendenteEvent } from '../_shared/_mercadeiro/_misc/_models/_interfaces/_events';
// import { HOME_ROUTE } from '../models/consts';
//#endregion

//#region stores
import { AppCarrinhoStore  } from '../stores';
import { AppService } from '../services';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class CarrinhoPendenteGuard implements CanActivate {
  //#region injects
  #appCarrinhoStore = inject(AppCarrinhoStore);
  // #appLojasStore = inject(AppLojasStore);
  // #router = inject(Router);
  //#endregion

  //#region methods
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.#appCarrinhoStore.carrinhoPendenteFound$
      .pipe(
        map((carrinhoPendente: ICarrinhoPendenteEvent) => {
          const CARRINHO_PENDENTE: boolean = !!carrinhoPendente;
          !CARRINHO_PENDENTE && AppService.goHome$.emit();
          return CARRINHO_PENDENTE;
        })
      );
  }
  //#endregion
}
