//#region ng
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  inject,
  signal
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
//#endregion

//#region mat
import { MatTabGroup } from '@angular/material/tabs';
//#endregion

//#region 3rd
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  from,
  takeUntil,
} from 'rxjs';
import {
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { get } from 'lodash';
//#endregion

//#region models
interface IVm {
  captions: {
    entrega: string;
    retirada: string;
  };
  conta: IConta;
  // endereco: ICorViacepApi;
  isMobile: boolean;
  isRetirada: boolean;
  loja: ILoja;
};
import {
  IConta,
  ILoja
} from '../../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { ICorViacepApi } from '../../../_shared/_core/_misc/_models/_interfaces/_apis';
import { ICarrinhoMap } from '../../../_shared/_mercadeiro/_misc/_models/_interfaces/_maps';
import { TNullable } from '../../../_shared/_core/_misc/_models/_types';
//#endregion

//#region custom validators
class CustomValidator {
  static naoConfirmado() {
    return (control: FormControl): ValidationErrors => {
      const CONFIRMADO = control?.value;
      // console.log(CONFIRMADO);
      return CONFIRMADO === true ? null : { naoConfirmado: true };
    }
  }

  static endereco(that: any) {
    return (control: FormControl): ValidationErrors => {
      const ENDERECO = control?.value;
      // console.log(that?._vm?.isRetirada);
      // console.log(ENDERECO);
      return !!ENDERECO?.__atendido || !!that?._vm?.isRetirada ? null : { endereco: true };
    }
  }

  static percLimite() {
    return (control: FormControl): ValidationErrors => {
      const PERC: number = Number(control?.value) || 0;
      // console.log(CONFIRMADO);
      return PERC >= 1 ? null : { limite: true };
    }
  }
}
//#endregion

//#region libs
import { fixEndereco } from '../../../_shared/_mercadeiro/_misc/_libs';
import { onDestroy } from '../../../_shared/_core/_ng/_libs';
//#endregion

//#region services
import { CorCepService } from '../../../_shared/_core/_ng/_services';
import {
  CarrinhoService,
  LojasService
} from '../../../_shared/_mercadeiro/_ng/_services';
//#endregion

//#region stores
import {
  AppCarrinhoStore,
  AppEnderecosStore
} from '../../../stores';
import { AppService } from '../../../services';
import {
  ContasStore,
  LojasStore
} from '../../../_shared/_mercadeiro/_ng/_stores';
//#endregion

@Component({
  selector: 'app-pedido-retirada-entrega',
  templateUrl: './pedido-retirada-entrega.component.html',
  styleUrls: ['./pedido-retirada-entrega.component.scss']
})
export class AppPedidoRetiradaEntregaComponent {
  //#region actions
  #destroyAction$: Subject<void> = onDestroy();
  #lojaAction$ = new BehaviorSubject<TNullable<ILoja>>(null);
  isRetiradaAction$ = signal<BehaviorSubject<boolean>>(new BehaviorSubject<boolean>(null));
  //#endregion

  //#region viewchilds
  @ViewChild('tabs') tabsRef: MatTabGroup;
  //#endregion

  //#region inputs
  // isRetirada
  #isRetirada: boolean;
  get isRetirada(): boolean { return this.#isRetirada; }
  @Input({ required: true }) set isRetirada(val: boolean) {
    this.#isRetirada = val;
    // console.log(val);
    this.isRetiradaAction$().next(val);
  }
  // #endregion

  //#region outputs
  @Output() isRetirada$ = new EventEmitter<boolean>();
  @Output() enderecoSel$ = new EventEmitter<ICorViacepApi>();
  @Output() goHome$ = new EventEmitter<void>();
  //#endregion

  //#region publics
  retiradaEntregaForm = signal<FormGroup>(null);
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region privates
  private _vm: IVm;
  //#endregion

  //#region injects
  #appCarrinhoStore = inject(AppCarrinhoStore);
  appEnderecosStore = inject(AppEnderecosStore);
  #carrinhoServ = inject(CarrinhoService);
  #cepServ = inject(CorCepService);
  #contasStore = inject(ContasStore);
  #fb = inject(FormBuilder);
  #lojasServ = inject(LojasService);
  #lojasStore = inject(LojasStore);
  //#endregion

  //#region constructor
  constructor() {
    this.retiradaEntregaForm?.set(
      this.#fb.group({
        confirmado: [false, [Validators.required, CustomValidator.naoConfirmado()]],
        endereco: [null, [CustomValidator.endereco(this)]],
        retirada: [undefined, [Validators.required]],
        percLimite: [0, [Validators.required, CustomValidator.percLimite()]]
      })
    );
  }
  //#endregion

  //#region Controls getters
  get confirmadoRef(): AbstractControl { return this.retiradaEntregaForm()?.get('confirmado'); }
  get enderecoRef(): AbstractControl { return this.retiradaEntregaForm()?.get('endereco'); }
  get percLimiteRef(): AbstractControl { return this.retiradaEntregaForm()?.get('percLimite'); }
  get retiradaRef(): AbstractControl { return this.retiradaEntregaForm()?.get('retirada'); }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.#lojasStore.lojaStateChanged$
      .pipe(
        // tap((loja: ILoja) => console.log(loja)),
        filter((loja: ILoja) => !!loja),
        switchMap((loja: ILoja) => from(this.#lojasServ.lfix(loja))),
        tap((lojas: [TNullable<ILoja>, TNullable<ILoja>]) => {
          // console.log(lojas);
          const [L1, L2] = lojas;
          delete L1.__img;
          delete L2.rede;
          const LOJA: ILoja = { ...L1, ...L2 };
          // console.log(LOJA);
          this.#lojaAction$.next(LOJA);
        }),
        takeUntil(this.#destroyAction$),
      )
      .subscribe();

    this.vm$.set(
      combineLatest([
        AppService.isMobile$,
        this.appEnderecosStore.enderecoStateChanged$,
        this.#contasStore.contaStateChanged$,
        this.#lojaAction$, // this.#lojasStore.lojaStateChanged$,
        this.isRetiradaAction$(),
        // this.#appCarrinhoStore.carrinhoStateChanged$,
        this.#appCarrinhoStore.carrinhoLojaStateChanged$,
      ])
        .pipe(
          map(([isMobile, endereco, conta, loja, isRetirada, carrinho]) => {
            // console.log(endereco, isRetirada);            
            // const CARRINHO: ICarrinhoMap = fixCarrinho(carrinho, loja);
            const CARRINHO: ICarrinhoMap = !!carrinho ? this.#carrinhoServ.fix(carrinho) : null;
            // console.log(CARRINHO);
            const ENDERECO: ICorViacepApi = this.#cepServ.fix(fixEndereco(endereco, loja, conta));
            const ENTREGA_RETIRADA_CAPTION: string = !!isRetirada ? 'retirada' : 'entrega';
            const ENDERECO_ATENDIDO: ICorViacepApi = !!ENDERECO?.__atendido ? ENDERECO : null;
            // console.log(ENDERECO_ATENDIDO);
            !!isRetirada ? this.confirmadoRef?.enable() : this.confirmadoRef?.disable();
            this.retiradaEntregaForm()?.patchValue(
              {
                // confirmado: !!isRetirada ? !!this.confirmadoRef?.value : !!ENDERECO_ATENDIDO,
                endereco: !isRetirada ? ENDERECO_ATENDIDO : null,
                retirada: isRetirada,
                percLimite: Number(get(CARRINHO, `__limites.${ENTREGA_RETIRADA_CAPTION}.perc`)) || 0
              }
            );
            // !!setState && this.appEnderecosStore?.setState(endereco);
            !isRetirada && !!ENDERECO_ATENDIDO && this.enderecoSel$.emit(ENDERECO_ATENDIDO);
            this.isRetirada$.emit(isRetirada);

            const MOBILE: boolean = ['ER', 'RE'].includes(loja?.entrega?.status) && !!isMobile;
            this._vm = {
              captions: {
                entrega: !!MOBILE ? 'Entrega' : 'Entrega no endereço',
                retirada: !!MOBILE ? 'Retirada' : 'Retirada na loja',
              },
              conta,
              // endereco,
              isMobile,
              isRetirada,
              loja,
            };
            // console.log(this._vm);
            return this._vm;
          }),
        )
    );
  }

  ngAfterViewInit() {
    // this.isRetiradaAction$().next(!!this.isRetirada); // Set default tab position.
    const NEW_POS: number = this.isRetirada ? 1 : 0;
    if (
      !!this.tabsRef
      && this.tabsRef?.selectedIndex !== NEW_POS
    ) {
      console.log(`OLD_POS: ${this.tabsRef?.selectedIndex} -> NEW_POS: ${NEW_POS}`);
      this.tabsRef.selectedIndex = NEW_POS;
      this.isRetiradaAction$().next(this.isRetirada);
    } // if
  }
  //#endregion

  //#region methods
  onTabChanged(pos: number) {
    // console.log(pos);
    !!pos && this.confirmadoRef?.setValue(false);
    this.isRetiradaAction$().next(!!pos);
  }
  //#endregion
}
