//#region ng
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
  inject,
  signal
} from '@angular/core';
//#endregion

//#region 3rd
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest,
} from 'rxjs';
import { finalize, first, map, takeUntil } from 'rxjs/operators';
//#endregion

//#region models
interface IVm {
  // produtos: IProduto[];
  carrinho: ICarrinhoMap;
};
import { IProduto } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { ICarrinhoMap } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_maps';
//#endregion

//#region libs
import { fixProdutos } from '../../_shared/_mercadeiro/_misc/_libs';
import { onDestroy } from '../../_shared/_core/_ng/_libs';
//#endregion

//#region services
import {
  CarrinhoService,
  ProdutosService
} from '../../_shared/_mercadeiro/_ng/_services';
import { AppService } from '../../services';
//#endregion

//#region stores
import { ContasStore } from '../../_shared/_mercadeiro/_ng/_stores';
import { AppCarrinhoStore } from '../../stores';
//#endregion

@Component({
  selector: 'app-search-preview',
  templateUrl: './search-preview.component.html',
  styleUrls: ['./search-preview.component.scss']
})
export class AppSearchPreviewComponent {
  //#region actions
  #destroyAction$: Subject<void> = onDestroy();
  // #produtosAction$ = new BehaviorSubject<IProduto[]>([]);
  //#endregion

  //#region viewchilds
  @ViewChild('searchPreview') searchPreviewRef: ElementRef;
  //#endregion

  //#region inputs
  // previewOverflow
  @Input() previewOverflow: number;
  // produtos
  #produtos: IProduto[];
  get produtos(): IProduto[] { return this.#produtos; }
  @Input({ required: true }) set produtos(val: IProduto[]) {
    // console.log(val);
    if (!!val) {
      // this.#produtos = this.#produtosServ.fixes(val);
      const SUB: Subscription = this.#produtosServ.lfixes(val || [])
        .pipe(
          first(),
          finalize(() => SUB?.unsubscribe())
        )
        .subscribe(
          (prods: IProduto[]) => {
            // console.log(prods);
            this.#produtos = prods || [];
            // this.produtosAction$().next(prods);
            this.#appCarrinhoStore.setState(this.#appCarrinhoStore.carrinhoGet());
          }
        );
    } else {
      this.#produtos = null;
    } // else

    // this.#produtos = val ? this.#produtosServ.fixes(val) : null;
    /* const SUB: Subscription = this.#produtosServ.lfixes(val || [])
      .pipe(
        first(),
        finalize(() => SUB?.unsubscribe())
      )
      .subscribe(
        (prods: IProduto[]) => {
          // console.log(prods);
          this.produtos = prods || [];
          // this.produtosAction$().next(prods);
          this.#appCarrinhoStore.setState(this.#appCarrinhoStore.carrinhoGet());
        }
      ); */


    // this.#produtosAction$.next(this.produtos);
    // this._syncProdutos();
  }
  //#endregion

  //#region outputs
  @Output() click$ = new EventEmitter<IProduto>();
  @Output() clickOutside$ = new EventEmitter<Event>();
  @Output() submit$ = new EventEmitter<void>();
  //#endregion

  //#region publics
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region injects
  #appCarrinhoStore = inject(AppCarrinhoStore);
  #carrinhoServ = inject(CarrinhoService);
  #contasStore = inject(ContasStore);
  #produtosServ = inject(ProdutosService);
  #renderer = inject(Renderer2);
  //#endregion

  //#region constructor
  constructor() {
    this.#renderer.listen(
      'window',
      'click',
      (e: Event) => {
        if (
          !this.searchPreviewRef?.nativeElement?.contains(e?.target)
          && !!((this.produtos || []).length)
        ) this.clickOutside$.emit(e);
      }
    );
  }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.#appCarrinhoStore.carrinhoItensStateChanged$
      .pipe(
        takeUntil(this.#destroyAction$)
      )
      .subscribe(
        (carrinho: ICarrinhoMap) =>
          !!this.produtos && fixProdutos(
            this.produtos,
            !!carrinho ? this.#carrinhoServ.fix(carrinho) : null,
          )
      );

    /*  this.vm$.set(
       combineLatest([
         this.#produtosAction$,
         // this.#appCarrinhoStore.carrinhoStateChanged$,
         this.#appCarrinhoStore.carrinhoItensStateChanged$,
       ])
         .pipe(
           map(
             ([produtos, carrinho]) => {
               const CARRINHO: ICarrinhoMap = !!carrinho ? this.#carrinhoServ.fix(carrinho) : null;
               const VM: IVm = {
                 produtos: fixProdutos(produtos, CARRINHO),
                 carrinho: CARRINHO
               };
               // console.log(VM);
               return VM;
             })
         )
     ); */

    /* this.vm$.set(
      combineLatest([
        this.#produtosAction$,
        // this.#appCarrinhoStore.carrinhoStateChanged$,
        this.#appCarrinhoStore.carrinhoItensStateChanged$,
      ])
        .pipe(
          map(
            ([produtos, carrinho]) => {
              const CARRINHO: ICarrinhoMap = !!carrinho ? this.#carrinhoServ.fix(carrinho) : null;
              const VM: IVm = {
                produtos: fixProdutos(produtos, CARRINHO),
                carrinho: CARRINHO
              };
              // console.log(VM);
              return VM;
            })
        )
    ); */
  }
  //#endregion

  //#region functions
  tid_pro(index: any, item: IProduto): string { return item?.id || ''; }
  //#endregion

  //#region methods
  /* onSearchSubmit() {
    this.submit$.emit();
  } */

  onQtdeProdutoChanged(p: IProduto, step: number) {
    // console.log(step, !!this._contasServ.getState(), p);
    if (!!this.#contasStore.getState()) {
      !!p && this.#appCarrinhoStore.setQtde(p, step);
    } else {
      AppService.identificacao$.emit(p);
    } // else
  }
  //#endregion
}
