//#region ng
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  computed,
  effect,
  inject,
  signal
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
//#endregion

//#region mat
import { MatStepper } from '@angular/material/stepper';
//#endregion

//#region 3rd
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest
} from 'rxjs';
import {
  first,
  finalize,
  map,
  tap,
  takeUntil,
  switchMap,
  filter,
} from 'rxjs/operators';
import { get } from 'lodash';
//#endregion

//#region models
/* interface IVm {
  isRetirada: boolean;
  loja: ILoja;
}; */
/* interface IFormaPgto {
  id: string;
  nome: string;
  img: string;
  formas: Partial<IFormaPgto>[];
  possuiBandeiras: boolean;
  permiteTroco: boolean;
  distribuidora: boolean;
}; */
import { environment } from 'src/environments/environment';
import {
  NOMES_TIPOS_FORMAS_PGTO,
  TIPOS_FORMAS_PGTO
} from '../../../_shared/_mercadeiro/_misc/_models/consts';
import {
  IConta,
  IFormaPgto,
  ILoja,
  IPedido
} from '../../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { ICarrinhoMap } from '../../../_shared/_mercadeiro/_misc/_models/_interfaces/_maps';
import { FOCUS_TIMEOUT } from '../../../_shared/_core/_misc/_models/consts';
import { CorFormValidation } from '../../../_shared/_core/_ng/_models/_classes';
import { TFormasPgto } from '../../../_shared/_mercadeiro/_misc/_models/_types';
//#endregion

//#region custom validators
class CustomValidator {
  static trocoPara(that: any) {
    return (control: FormControl): ValidationErrors => {
      const TROCO_PARA: number = Number(control?.value) || 0;
      // const PERMITE_TROCO: boolean = that.tipoSel?.permiteTroco;
      const PERMITE_TROCO: boolean = !!that.permiteTrocoRef?.value;
      // console.log(PERMITE_TROCO, TROCO_PARA, that.changes?.__total?.totalPagar);
      if (!!PERMITE_TROCO && TROCO_PARA < that.changes?.__total?.totalPagar) return { trocoPara: true };
      return null;
    }
  }
}
//#endregion

//#region libs
import { onDestroy } from '../../../_shared/_core/_ng/_libs';
//#endregion

//#region services
import { FormasPgtoService } from '../../../_shared/_mercadeiro/_ng/_services/formas-pgto.service';
import {
  ContasService,
  LojaFormasPgtoService,
  LojasService
} from '../../../_shared/_mercadeiro/_ng/_services';
import { CorLoaderService } from '../../../_shared/_core/_ng/_services';
//#endregion

//#region stores
import { ContasStore, LojasStore } from '../../../_shared/_mercadeiro/_ng/_stores';
//#endregion

@Component({
  selector: 'app-pedido-pagamento',
  templateUrl: './pedido-pagamento.component.html',
  styleUrls: ['./pedido-pagamento.component.scss']
})
export class AppPedidoPagamentoComponent {
  //#region actions
  #destroyAction$ = onDestroy();
  #isRetiradaAction$ = new BehaviorSubject<boolean>(null);
  #lojaFormasAction$ = new BehaviorSubject<IFormaPgto[]>(null);
  //#endregion

  //#region inputs
  // isMobile
  @Input() isMobile: boolean = false;
  // changes
  @Input({ required: true }) changes: Partial<IPedido>;
  // @Input() isRetirada: boolean;
  #isRetirada: boolean;
  get isRetirada(): boolean { return this.#isRetirada; }
  @Input() set isRetirada(val: boolean) {
    this.#isRetirada = val;
    // console.log(val);
    // this.#calcFormas();
    this.#isRetiradaAction$.next(val);
  }
  //#endregion

  //#region outputs
  // @Output() selected$ = new EventEmitter<Partial<IFormaPgto>>();
  @Output() formaChange$ = new EventEmitter<void>();
  //#endregion

  //#region publics
  formaPgtoForm = signal<FormGroup>(null);
  formaSel = signal<IFormaPgto>(null);
  fv = signal<CorFormValidation>(null);
  idPainelAberto = signal<string>('');
  tiposFormas = signal<IFormaPgto[]>([]);
  // totalPagar = signal<number>(0);
  // vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region privates
  // #formas: IFormaPgto[];
  //#endregion

  //#region events
  trocoParaFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>()).asReadonly();
  //#endregion

  //#region injects
  #formasPgtoServ = inject(FormasPgtoService);
  //#endregion

  //#region injects
  #contasStore = inject(ContasStore);
  #fb = inject(FormBuilder);
  #formasServ = inject(FormasPgtoService);
  #lojaFormasServ = inject(LojaFormasPgtoService);
  #lojasStore = inject(LojasStore);
  //#endregion

  //#region constructor
  constructor() {
    this.fv.set(new CorFormValidation());

    this.formaPgtoForm?.set(
      this.#fb.group({
        formaPgto: this.#fb.group({
          id: ['', [Validators.required]],
          __img: ['', [Validators.required]],
          _nome: ['', [Validators.required]],
          _tipo: ['', [Validators.required]],
          __trocoPara: [null, [Validators.required, CustomValidator.trocoPara(this)]],
          __permiteTroco: [null, [Validators.required]],
          __possuiBandeiras: [null, [Validators.required]],
        })
      })
    );

    effect(
      () => {
        const ID_PAINEL_ABERTO: string = this.idPainelAberto() || '';
        // console.log(ID_PAINEL_ABERTO);
        this.possuiBandeirasRef?.setValue(false);
        this.permiteTrocoRef?.setValue(false);
        switch (ID_PAINEL_ABERTO) {
          case 'din':
            this.permiteTrocoRef?.setValue(true);
            // const DIN: IFormaPgto = (this.#formas || []).find((f: IFormaPgto) => f?.id === 'din_dinheiro');
            // !!DIN?.__formas?.length && this.formaSel?.set(DIN?.__formas?.[0]);
            this.trocoParaRef?.setValue('');
            this.focus();
            break;

          case 'pix':
            this.trocoParaRef?.setValue(0);
            // const PIX: IFormaPgto = (this.#formas || []).find((f: IFormaPgto) => f?.id === 'pix_pix');
            // !!PIX?.__formas?.length && this.formaSel?.set(PIX?.__formas?.[0]);
            break;

          default:
            this.trocoParaRef?.setValue(0);
            this.possuiBandeirasRef?.setValue(true);
            break;
        } // switch
      }
    );
  }
  //#endregion

  //#region Controls getters
  get idRef(): AbstractControl { return this.formaPgtoForm()?.get('formaPgto.id'); }
  get nomeRef(): AbstractControl { return this.formaPgtoForm()?.get('formaPgto._nome'); }
  get imgRef(): AbstractControl { return this.formaPgtoForm()?.get('formaPgto.__img'); }
  get tipoRef(): AbstractControl { return this.formaPgtoForm()?.get('formaPgto._tipo'); }
  get trocoParaRef(): AbstractControl { return this.formaPgtoForm()?.get('formaPgto.__trocoPara'); }
  get permiteTrocoRef(): AbstractControl { return this.formaPgtoForm()?.get('formaPgto.__permiteTroco'); }
  get possuiBandeirasRef(): AbstractControl { return this.formaPgtoForm()?.get('formaPgto.__possuiBandeiras'); }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.#formasServ.docs()
      .pipe(
        switchMap((formas: IFormaPgto[]) => this.#formasPgtoServ.lfixes(formas)),
        tap((formas: IFormaPgto[]) => this.#lojaFormasAction$.next(formas)),
        takeUntil(this.#destroyAction$)
      )
      .subscribe();

    combineLatest([
      this.#lojasStore.lojaStateChanged$,
      this.#isRetiradaAction$,
      this.#lojaFormasAction$,
      this.#contasStore.contaStateChanged$,
    ])
      .pipe(
        filter(([loja, isRetirada, formas, conta]) => !!loja?.__idInfo?.lojaPath),
        switchMap(
          ([loja, isRetirada, formas, conta]) => this.#lojaFormasServ.docs(loja?.__idInfo?.lojaPath || '')
            .pipe(map((distribuidoraFormas: IFormaPgto[]) => ({ conta, isRetirada, loja, formas, distribuidoraFormas })))
        ),
        takeUntil(this.#destroyAction$),
      )
      .subscribe(data => {
        // console.log(data);
        const {
          conta: CONTA,
          isRetirada: IS_RETIRADA,
          distribuidoraFormas: DISTRIBUIDORA_FORMAS,
          formas: FORMAS,
          loja: LOJA
        } = data;
        // console.log(DISTRIBUIDORA_FORMAS);
        // console.log(FORMAS);
        // this.#formas = this.#formasServ.fixes(FORMAS);

        const LOJA_FORMAS: IFormaPgto[] = get(LOJA, `formas.${!!IS_RETIRADA ? 'retirada' : 'entrega'}`) || [];
        // console.log(LOJA_FORMAS);
        const TIPOS_LOJAS_OK: string[] = Object.entries(LOJA_FORMAS)
          .filter((e: any) => e[1].length)
          .map((e: any) => e[0]);
        // console.log(TIPOS_LOJAS_OK);
        // console.log(this.#formas);

        let tiposFormas: IFormaPgto[] = this.#formasServ.fixes(
          TIPOS_FORMAS_PGTO
            .filter((s: string) => TIPOS_LOJAS_OK.includes(s))
            .map(
              (s: string) => (
                {
                  id: s,
                  _nome: NOMES_TIPOS_FORMAS_PGTO[s],
                  imgLink: `${environment.firebase.mercadeiro.storage.root}/tipos-formas-pgto%2F${s}.svg?alt=media`,
                  __formas: this.#formasServ.fixes(
                    LOJA_FORMAS[s]
                      .map(
                        (id: string) => FORMAS?.find((f: Partial<IFormaPgto>) => f?.id === id)
                      )
                  ),
                  __permiteTroco: this.#permiteTroco(s),
                  __possuiBandeiras: this.#possuiBandeiras(s),
                  __distribuidora: false,
                  _tipo: s as TFormasPgto,
                }
              )
            )
        );

        // console.log(CONTA);
        const IDS_FORMAS_CONTA: string[] = CONTA.credenciado?.[LOJA?.id]?.idsFormasPgto || [];
        // console.log(IDS_FORMAS_CONTA);
        const DISTRIBUIDORA_FORMAS_OK: IFormaPgto[] = (DISTRIBUIDORA_FORMAS || [])
          .filter((f: IFormaPgto) => IDS_FORMAS_CONTA?.includes(f?.id))
          .map((f: IFormaPgto) => { return { ...f, _tipo: 'dis' } });
        // console.log(DISTRIBUIDORA_FORMAS_OK);

        if (!!DISTRIBUIDORA_FORMAS_OK?.length) {
          tiposFormas = tiposFormas.concat(DISTRIBUIDORA_FORMAS_OK);
        } // if
        this.tiposFormas.set(tiposFormas);
      });
  }
  //#endregion

  //#region functions
  tid_for(index: any, item: IFormaPgto): string { return item?.id || ''; }

  #permiteTroco(formaTipo: string) { return ['din'].includes(formaTipo); }

  #possuiBandeiras(formaTipo: string) { return !['din', 'pix', 'dis'].includes(formaTipo); }

  focus() {
    !this.isMobile && setTimeout(
      () => this.trocoParaFocusEvent$().emit(true),
      FOCUS_TIMEOUT
    );
  }
  //#endregion

  //#region methods
  onFormaSelClick(f: IFormaPgto) {
    // console.log(f);
    this.formaPgtoForm()?.patchValue(
      {
        formaPgto: {
          id: f?.id || '',
          __img: f?.__img || '',
          _nome: f?._nome || '',
          _tipo: f?._tipo || '',
          // __trocoPara: Number(f?.__trocoPara) || 0,
          __permiteTroco: !!f?.__permiteTroco,
          __possuiBandeiras: !!f?.__possuiBandeiras,
        }
      }
    );
    this.formaChange$.emit();
  }
  //#endregion

  //#region methods
  // onTipoSel(f: Partial<IFormaPgto>) {
  //   // console.log(f);
  //   const FORMA_TIPO: string = f?._tipo;
  //   const PERMITE_TROCO: boolean = this.#permiteTroco(FORMA_TIPO);
  //   // console.log(FORMA_TIPO);
  //   this.formaPgtoForm().patchValue(
  //     {
  //       formaPgto: {
  //         id: f?.id || '',
  //         __img: f?.__img || '',
  //         _nome: f?._nome || '',
  //         _tipo: f?._tipo,
  //         __trocoPara: !!PERMITE_TROCO ? f?.__trocoPara : 0,
  //         __distribuidora: f?.id === 'dis',
  //         __permiteTroco: PERMITE_TROCO,
  //         __possuiBandeiras: this.#possuiBandeiras(FORMA_TIPO),
  //       }
  //     }
  //   );
  //   this.tipoSel.set(this.#formasServ.fix(f));
  //   this.selected$.emit(f);
  // }
  //#endregion
}
