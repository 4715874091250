<mat-toolbar color="primary"
             *ngIf="!!loja()">
  <div class="d-flex px-1 w-100">
    <div class="icon-area my-auto">
      <div *ngIf="!!showReturn; else atHome">
        <button mat-icon-button
                (click)="returnClick$.emit()">
          <mat-icon class="rem-20">chevron_left</mat-icon>
        </button>
      </div>
      <ng-template #atHome>
        <button mat-icon-button
                routerLink="/procura">
          <mat-icon>search</mat-icon>
        </button>
      </ng-template>
    </div>

    <div class="flex-1 w-100 d-flex">
      <div class="m-auto">
        <small> {{ loja()?.rede?._nome }} </small>
      </div>
    </div>

    <div class="icon-area my-auto">
      <button mat-icon-button
              (click)="onMenuLojaOpenClick()"
              *ngIf="!!showMenu">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
<cor-mat-messages [isMain]="true" />
