<!-- <p>vm$ {{ vm$() | async }}</p> -->

<ng-container *ngIf="vm$() | async as vm">
  <div class="page"
       [class.no-footer]="!vm?.isMobile">

    <div class="page-header">
      <div *ngIf="!!vm?.isMobile; else headerDesktop">
        <app-loja-m-header voltar="false" />
      </div>
      <ng-template #headerDesktop
                   class="desktop-lg">
        <app-loja-header />
      </ng-template>
    </div>

    <div class="page-content"
         [class.lg]="!!vm?.isAtTop">
      <div *ngIf="!!vm?.banners; else carregandoBanners">
        <div class="container"
             *ngIf="!!vm?.banners?.length">
          <section class="hero-banners">
            <owl-carousel-o [options]="heroBannersOptions()">
              <ng-template carouselSlide
                           *ngFor="let b of vm?.banners; trackBy:tid_ban">
                <img [src]="b?.__url"
                     class="banner"
                     [class.pointer]="!!b?.target"
                     (click)="onBannerClick(b)">
              </ng-template>
            </owl-carousel-o>
          </section>
        </div>
      </div>
      <ng-template #carregandoBanners>
        <ngx-skeleton-loader [theme]="{
                    'border-radius': '10px',
                    height: '35vh',
                    width: '100%'
                  }" />

        <ul class="skel-dots d-flex justify-content-center">
          <li *ngFor="let item of [1,2,3,4,5,6]"></li>
        </ul>
      </ng-template>

      <div class="container">
        <div class="mt-2">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb d-flex">
              <li class="breadcrumb-item my-auto">
                <span class="icon-o-home o-20"></span>
              </li>

              <li class="breadcrumb-item my-auto">Novidades</li>

              <li class="ms-auto my-auto"
                  *ngIf="!!produtos?.length">
                <app-produtos-ord-sel [ord]="ordAction$() | async"
                                      [exceptions]="['r']"
                                      [isMobile]="vm?.isMobile"
                                      (change$)="ordAction$().next($event)" />
              </li>
            </ol>
          </nav>
        </div>

        <!-- <p>produtos {{ produtos | json }} </p> -->
        <!-- <p>produtos {{ produtos?.length }} </p> -->

        <div *ngIf="!!produtos; else carregandoProdutos">
          <ul class="w-cards produtos">
            <li *ngFor="let p of produtos; trackBy:tid_pro"> <!-- | produtosLfixes | async -->
              <app-produto-card [produto]="p"
                                (imgClick$)="onProdutoClick($event)" />
            </li>
          </ul>

          <div *ngIf="!!paginator()?.nextEnabled; else listaCarregada">
            <div class="d-flex mb-3">
              <button type="button"
                      class="btn btn-light m-auto round m-auto"
                      (click)="paginator()?.next()">
                <span class="o-20 icon-r-chevron-d me-2"></span>Carregar mais produtos...
              </button>
            </div>
          </div>
          <ng-template #listaCarregada>
            <div class="d-flex mb-4">
              <div class="alert alert-light text-center flex-1"
                   role="alert">
                Todas ocorrências exibidas.
              </div>
            </div>
          </ng-template>
        </div>
        <ng-template #carregandoProdutos>
          <me-html-produtos-skel />
        </ng-template>
      </div>

      <app-loja-footer />
    </div>
  </div>

  <div class="page-footer"
       *ngIf="vm?.isMobile">
    <mat-toolbar color="primary"
                 (click)="onCarrinhoClick()">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-6 offset-sm-3 pt-3">
            <app-carrinho />
          </div>
        </div>
      </div>
    </mat-toolbar>
  </div>
</ng-container>