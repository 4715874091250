<ng-container *ngIf="vm$() | async as vm">
  <!-- <p>vm {{ vm | json }}</p> -->
  <!-- <p>isMobile() {{ isMobile() }}</p> -->

  <div class="loja-footer toolbar-primary px-2 py-4"
       [class.shade]="!!isMobile()"
       *ngIf="!!vm?.loja">

    <div class="container d-flex"
         [class.flex-column]="!!isMobile()">

      <div class="flex-1 d-flex">
        <div class="m-auto text-center">
          <strong>{{ vm?.loja?.nome }}</strong>
          <br>
          {{ vm?.loja?.endereco?.bairro }}
          <br>
          <p>
            <span class="o-50">{{ vm?.loja?.endereco?.localidade }} - {{ vm?.loja?.endereco?.uf }}</span>
            <br>
            <span class="o-50">
              {{ vm?.loja?.endereco?.logradouro }}, {{ vm?.loja?.endereco?.nro }} {{ vm?.loja?.endereco?.complemento }}
            </span>
          </p>

          <img [src]="vm?.loja?.__img?.thumb"
               [alt]="vm?.loja?.nome"
               class="thumb my-3"
               *ngIf="!!vm?.loja?.__img?.status">
          <br />

          <hr>

          <strong class="o-60">Contato</strong>

          <div *ngIf="!!vm?.loja?.fones?.celular">
            <span class="icon-o-smartphone o-50 me-2"></span>
            {{ vm?.loja?.fones?.celular | fone }}
          </div>

          <div *ngIf="!!vm?.loja?.fones?.fixo">
            <span class="icon-o-phone o-50 me-2"></span>
            {{ vm?.loja?.fones?.fixo | fone }}
          </div>

          <div *ngIf="!!vm?.loja?.fones?.mensagens">
            <span class="icon-o-chat-empty o-50 me-2"></span>
            {{ vm?.loja?.fones?.mensagens | fone }}
          </div>

          <div *ngIf="!!vm?.loja?.email">
            <span class="icon-b-at o-50 me-2"></span>
            {{ vm?.loja?.email }}
          </div>

          <div *ngIf="!!vm?.loja?.siteInstitucional"
               class="pointer"
               routerLink="/redirect"
               [queryParams]="{ url: vm?.loja?.siteInstitucional }">
            <span class="icon-os-globe o-50 me-2"></span>
            <span class="underline">{{ vm?.loja?.siteInstitucional }}</span>
          </div>

          <hr>

          <strong class="o-60">Social</strong>

          <div class="socials d-flex justify-content-center">
            <div *ngIf="!!vm?.loja?.redesSociais?.facebook"
                 matTooltip="Facebook"
                 class="social pointer d-flex"
                 routerLink="/redirect"
                 [queryParams]="{ url: vm?.loja?.redesSociais.facebook }">
              <span class="icon-s-facebook o-50 m-auto"></span>
            </div>

            <div *ngIf="!!vm?.loja?.redesSociais?.instagram"
                 matTooltip="Instagram"
                 class="social pointer d-flex"
                 routerLink="/redirect"
                 [queryParams]="{ url: vm?.loja?.redesSociais.instagram }">
              <span class="icon-s-instagram o-50 m-auto"></span>
            </div>

            <div *ngIf="!!vm?.loja?.redesSociais?.twitter"
                 matTooltip="Twitter"
                 class="social pointer d-flex"
                 routerLink="/redirect"
                 [queryParams]="{ url: vm?.loja?.redesSociais.twitter }">
              <span class="icon-s-twitter o-50 m-auto"></span>
            </div>

            <div *ngIf="!!vm?.loja?.redesSociais?.youtube"
                 matTooltip="Youtube"
                 class="social pointer d-flex"
                 routerLink="/redirect"
                 [queryParams]="{ url: vm?.loja?.redesSociais.youtube }">
              <span class="icon-s-youtube o-50 m-auto"></span>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-1 d-flex">
        <div class="m-auto text-center">
          <hr *ngIf="!!isMobile()">

          <strong class="o-60">Atendimento</strong>

          <hr>

          <app-loja-horarios [horarios]="vm?.loja?.horarios?.funcionamento" />
        </div>
      </div>

      <div class="flex-1 d-flex">
        <div class="m-auto text-center">
          <hr *ngIf="!!isMobile()">

          <strong class="o-60">Formas de pagamento</strong>
          <app-formas-pgto-list [formas]="vm?.formas?.o" />

          <div *ngIf="!!vm?.formas?.c?.length">
            <hr>
            <span class="o-50"> CRÉDITO </span>
            <br />
            <app-formas-pgto-list [formas]="vm?.formas?.c" />
          </div>

          <div *ngIf="!!vm?.formas?.d?.length">
            <hr>
            <span class="o-50"> DÉBITO </span>
            <br />
            <app-formas-pgto-list [formas]="vm?.formas?.d" />
          </div>

          <hr>

          <div class="m-auto pointer"
               routerLink="/redirect"
               [queryParams]="{ url: 'https://sobre.mercadeiro.com.br'}">
            <div class="px-6">
              <div class="o-50 px-4">
                <img src="assets/img/logo_mercadeiro.svg"
                     class="img-fluid">
              </div>
              <p class="mt-2">
                <small class="underline">
                  sobre.mercadeiro.com.br
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr *ngIf="!isMobile()">

    <div class="container d-flex"
         [class.flex-column]="!!isMobile()">
      <div class="flex-1 d-flex">
        <div class="m-auto text-center o-60 p-2">

          <hr *ngIf="!!isMobile()">

          Os produtos estão sujeitos à disponibilidade de estoque no momento da separação e a quantidade solicitada
          será
          levemente diferente para produtos fracionados.
        </div>
      </div>

      <div class="flex-1 d-flex">
        <div class="m-auto text-center o-60 p-2">

          <hr *ngIf="!!isMobile()">

          As fotos dos produtos são ilustrativas, podendo haver divergência com o produto real, confira seus detalhes
          para maiores informações.
        </div>
      </div>

      <div class="flex-1 d-flex">
        <div class="m-auto text-center o-60 p-2">

          <hr *ngIf="!!isMobile()">

          A venda e o consumo de bebidas alcoólicas são proibidos para menores de 18 anos. Beba com moderação.
        </div>
      </div>
    </div>
  </div>
</ng-container>