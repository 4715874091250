<!-- <p>formas {{ formas | json }}</p> -->

<!-- <ul class="formas-list">
  <li class="forma-item d-flex"
      *ngFor="let f of formas">
    <img [src]="f?.__img"
         class="img-fluid m-auto">
  </li>
</ul> -->

<ul class="d-flex flex-wrap justify-content-center formas-list">
  <li class="forma-item d-flex me-1 mb-1"
      *ngFor="let f of formas | formasPgtosLfixes | async; trackBy:tid_for">
    <img [src]="f?.__img"
         class="img-fluid m-auto">
  </li>
</ul>
