//#region ng
import { NgModule } from '@angular/core';
import {
  CommonModule,
  NgOptimizedImage
} from '@angular/common';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { RouterModule } from '@angular/router';
//#endregion

//#region mat
// import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
// import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
// import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
//#endregion

//#region 3rd
import {
  NgxMaskDirective,
  NgxMaskPipe,
  // provideNgxMask
} from 'ngx-mask';
// import { SlickCarouselModule } from 'ngx-slick-carousel';
// import { PinchZoomModule } from 'ngx-pinch-zoom';
// import { SwiperModule } from 'swiper/angular';
//#endregion

//#region modules
// import { CorDirectivesModule } from '../_shared/_core/_ng/_directives/cor-directives.module';
import { CorPipesModule } from '../_shared/_core/_ng/_pipes/cor-pipes.module';
// import { CorMatComponentsModule } from '../_shared/_core/_ng-ui/_mat/cor-mat-components.module';
// import { ManageEnderecoModalModule } from '../modals/_manage-endereco/manage-endereco.modal.module';
// import { MeGaleriaSearchModalModule } from '../modals/_galeria-search/galeria-search-modal.module';
import { CorDirectivesModule } from '../_shared/_core/_ng/_directives/cor-directives.module';
// import { CorMatComponentsModule } from '../_shared/_core/_ng-ui/_mat/cor-mat-components.module';
// import { CorBsComponentsModule } from '../_shared/_core/_ng-ui/_bs/cor-bs-components.module';
import { MeHtmlComponentsModule } from '../_shared/_mercadeiro/_ng-ui/_html/me-html-components.module';
// import { CorPipesModule } from '../_shared/_core/_ng/_pipes/cor-pipes.module';
import { CorMatComponentsModule } from '../_shared/_core/_ng-ui/_mat/cor-mat-components.module';
import { MePipesModule } from '../_shared/_mercadeiro/_ng/_pipes/me-pipes.module';
//#endregion

//#region components
import {
  AppCarrinhoComponent,
  AppContaActionsComponent,
  AppContaContatoFormComponent,
  AppContaDadosPessoaisFormComponent,
  AppContaEnderecosComponent,
  AppEnderecoInfoComponent,
  AppFormasPgtoListComponent,
  AppItemBtnComprarComponent,
  AppLojaFooterComponent,
  AppLojaHeaderComponent,
  AppLojaHorariosComponent,
  AppLojaInfoComponent,
  AppLojaMHeaderComponent,
  AppProdutoCardComponent,
  AppProdutoBtnComprarComponent,
  // AppProdutoCardComponent,
  AppProdutosOrdSelComponent,
  AppSearchPreviewComponent,
  AppCarrinhoInfoComponent,
  // ManageEnderecosComponent,
  // ProdutoImgsComponent,
} from '.';
import { MeBsComponentsModule } from '../_shared/_mercadeiro/_ng-ui/_bs/me-bs-components.module';
// import {
// OperadorFormComponent,
// RedeFormComponent,
//   MeOperadorActionsComponent
// } from '../_shared/_mercadeiro/_ng-ui/_mat';
//#endregion

@NgModule({
  imports: [
    // CorBsComponentsModule,
    CorDirectivesModule,
    CorMatComponentsModule,
    CorPipesModule,
    // CorMatComponentsModule,
    // ManageEnderecoModalModule,
    // MeGaleriaSearchModalModule,
    CommonModule,
    FormsModule,
    // MatAutocompleteModule,
    MeBsComponentsModule,
    MatButtonModule,
    MatDatepickerModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    // MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSelectModule,
    // MatSnackBarModule,
    MatToolbarModule,
    MatTooltipModule,
    MeHtmlComponentsModule,
    MePipesModule,
    NgxMaskDirective,
    NgxMaskPipe,
    // NgOptimizedImage,
    ReactiveFormsModule,
    RouterModule,
    // PinchZoomModule,
    // SlickCarouselModule,
    // SwiperModule,
  ],
  declarations: [
    AppCarrinhoComponent,
    AppCarrinhoInfoComponent,
    AppContaActionsComponent,
    AppContaContatoFormComponent,
    AppContaDadosPessoaisFormComponent,
    AppContaEnderecosComponent,
    AppEnderecoInfoComponent,
    AppFormasPgtoListComponent,
    AppItemBtnComprarComponent,
    AppLojaFooterComponent,
    AppLojaHeaderComponent,
    AppLojaHorariosComponent,
    AppLojaInfoComponent,
    AppLojaMHeaderComponent,
    AppProdutoBtnComprarComponent,
    AppProdutoCardComponent,
    AppProdutosOrdSelComponent,
    AppSearchPreviewComponent,
    // ManageEnderecosComponent,
    // OperadorFormComponent,
    // ProdutoImgsComponent,
    // RedeFormComponent,
    // MeOperadorActionsComponent,
  ],
  exports: [
    AppCarrinhoComponent,
    AppCarrinhoInfoComponent,
    AppContaActionsComponent,
    AppContaContatoFormComponent,
    AppContaDadosPessoaisFormComponent,
    AppContaEnderecosComponent,
    AppEnderecoInfoComponent,
    AppFormasPgtoListComponent,
    AppItemBtnComprarComponent,
    AppLojaFooterComponent,
    AppLojaHeaderComponent,
    AppLojaHorariosComponent,
    AppLojaInfoComponent,
    AppLojaMHeaderComponent,
    AppProdutoBtnComprarComponent,
    AppProdutoCardComponent,
    AppProdutosOrdSelComponent,
    AppSearchPreviewComponent,
    // ManageEnderecosComponent,
    // OperadorFormComponent,
    // ProdutoImgsComponent,
    // RedeFormComponent,
    // MeOperadorActionsComponent,
  ],
})
export class AppComponentsModule { }
