//#region ng
import {
  Component,
  Input,
  inject,
} from '@angular/core';
//#endregion

//#region 3rd
import { BsModalService } from 'ngx-bootstrap/modal';
//#endregion

//#region models
import { IPedido } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
//#endregion

@Component({
  selector: 'app-pedido-detalhes-modal',
  templateUrl: './pedido-detalhes.modal.html',
  styleUrls: ['./pedido-detalhes.modal.scss']
})
export class AppPedidoDetalhesModal {
  //#region inputs
  // isMobile
  @Input() isMobile: boolean = false;
  // idModal
  @Input() idModal: number;
  // pedido
  @Input() pedido: IPedido;
  // lojaPath
  @Input() lojaPath: string;
  //#endregion

  //#region injects
  #bsModalServ = inject(BsModalService);
  //#endregion

  //#region methods
  onModalCloseClick(data: any = null) {
    this.#bsModalServ.hide(data);
  }
  //#endregion
}
