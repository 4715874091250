<div *ngIf="!!loja()"
     class="loja">
  <span class="icon-o-store o-50 me-1"></span>

  <small class="text-nowrap">
    {{ loja()?.endereco?.bairro }} -
    {{ loja()?.endereco?.localidade }} {{ loja()?.endereco?.uf }}
  </small>

  <span *ngIf="!!showChange">
    <span class="o-50 underline pointer ms-2"
          (click)="change$?.emit()">
      trocar
    </span>
  </span>
</div>