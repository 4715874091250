//#region ng
import {
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
  signal,
} from '@angular/core';
//#endregion

//#region 3rd
import {
  Subject,
  takeUntil
} from 'rxjs';
//#endregion

//#region models
import { ILoja } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
//#endregion

//#region libs
import { onDestroy } from '../../_shared/_core/_ng/_libs';
//#endregion

//#region services
import { LojasService } from '../../_shared/_mercadeiro/_ng/_services';
//#endregion

//#region stores
import { LojasStore } from '../../_shared/_mercadeiro/_ng/_stores';
//#endregion

@Component({
  selector: 'app-loja-info',
  templateUrl: './loja-info.component.html',
  styleUrls: ['./loja-info.component.scss']
})
export class AppLojaInfoComponent {
  //#region actions
  #destroyAction$: Subject<void> = onDestroy();
  //#endregion

  //#region inputs
  // showChange
  @Input() showChange: boolean = true;
  //#endregion

  //#region outputs
  @Output() change$ = new EventEmitter<void>();
  //#endregion

  //#region publics
  loja = signal<ILoja>(null);
  // retirada = signal<boolean>(null);
  //#endregion

  //#region injects
  #lojasServ = inject(LojasService);
  #lojasStore = inject(LojasStore);
  //#endregion

  //#region lifecycles
  ngOnInit(): void {
    this.#lojasStore.lojaStateChanged$
      .pipe(takeUntil(this.#destroyAction$))
      .subscribe((loja: ILoja) => this.loja.set(!!loja ? this.#lojasServ.fix(loja) : null));
  }
  //#endregion
}
