//#region ng
import {
  Component,
  inject,
  signal,
} from '@angular/core';
//#endregion

//#region 3rd
import {
  Observable,
  Subject,
  combineLatest,
  from,
  of
} from 'rxjs';
import {
  takeUntil,
  map,
  tap,
  filter,
  switchMap
} from 'rxjs/operators';
//#endregion

//#region models
interface IVm {
  loja: ILoja;
  formas: IFormasPgtoGrupos;
};
import { ILoja } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { IFormasPgtoGrupos } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_misc';
//#endregion

//#region libs
import { onDestroy } from '../../_shared/_core/_ng/_libs';
//#endregion

//#region services
import { AppService } from '../../services';
//#endregion

//#region stores
import { LojasStore } from '../../_shared/_mercadeiro/_ng/_stores';
import { AppLojasStore } from '../../stores';
import { LojasService } from 'src/app/_shared/_mercadeiro/_ng/_services';
import { TNullable } from 'src/app/_shared/_core/_misc/_models/_types';
//#endregion

@Component({
  selector: 'app-loja-footer',
  templateUrl: './loja-footer.component.html',
  styleUrls: ['./loja-footer.component.scss']
})
export class AppLojaFooterComponent {
  //#region actions
  #destroyAction$: Subject<void> = onDestroy();
  //#endregion

  //#region publics
  isMobile = signal<boolean>(false);
  vm$ = signal<Observable<IVm>>(null)
  //#endregion

  //#region injects
  #appLojasStore = inject(AppLojasStore);
  #lojasServ = inject(LojasService);
  #lojasStore = inject(LojasStore);
  //#endregion

  //#region lifecycles
  ngOnInit(): void {
    AppService.isMobile$
      .pipe(takeUntil(this.#destroyAction$))
      .subscribe((isMobile: boolean) => this.isMobile.set(isMobile));

    this.vm$.set(
      combineLatest([
        this.#lojasStore.lojaStateChanged$,
        this.#appLojasStore.lojaFormasGruposStateChanged$,
      ])
        .pipe(
          // tap(([loja, formas]) => console.log(loja, formas)),
          filter(([loja, formas]) => !!loja),
          switchMap(
            ([loja, formas]) => from(this.#lojasServ.lfix(loja))
              .pipe(
                map((lojas: [TNullable<ILoja>, TNullable<ILoja>]) => {
                  // console.log(lojas);
                  // console.log(formas);
                  const [L1, L2] = lojas;
                  delete L1.__img;
                  delete L2.rede;
                  const LOJA: ILoja = { ...L1, ...L2 };
                  // console.log(LOJA);
                  const VM: IVm = {
                    loja: LOJA,
                    formas
                  };
                  // console.log(VM);
                  return VM;
                })
              )
          )
        )
    );
  }
  //#endregion
}
