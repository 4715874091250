//#region ng
import {
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
} from '@angular/core';
//#endregion

//#region models
import { IProduto } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
//#endregion

//#region services
import { AppService } from '../../services';
import { ProdutosService } from '../../_shared/_mercadeiro/_ng/_services';
//#endregion

//#region stores
import { AppCarrinhoStore } from '../../stores';
import { ContasStore } from '../../_shared/_mercadeiro/_ng/_stores';
//#endregion

@Component({
  selector: 'app-produto-card',
  templateUrl: './produto-card.component.html',
  styleUrls: ['./produto-card.component.scss']
})
export class AppProdutoCardComponent {
  //#region inputs
  // produto
  @Input({ required: true }) produto: IProduto;
  // #produto: IProduto;
  // get produto(): IProduto { return this.#produto }
  // @Input({ required: true }) set produto(val: IProduto) {
  //   console.log(val);
  //   this.#produto = val;
  //   // this.#produtosServ.lfix(val)
  //   //   .then((p: IProduto) => {
  //   //     console.log(p);
  //   //     this.#produto = p;
  //   //   });
  // };
  // showQtdeBtn
  @Input() showQtdeBtn: boolean = true;
  //#endregion

  //#region outputs
  @Output() imgClick$ = new EventEmitter<IProduto>();
  //#endregion

  //#region publics
  // tags = signal<IPTag[]>(null);
  //#endregion

  //#region injects
  #appCarrinhoStore = inject(AppCarrinhoStore);
  #appServ = inject(AppService);
  #contasStore = inject(ContasStore);
  #produtosServ = inject(ProdutosService);
  //#endregion

  //#region methods
  onQtdeProdutoChanged(p: IProduto, step: number) {
    // console.log(p, step, !!this._contasServ.getState());
    if (!!this.#contasStore.getState()) {
      // p && this._carrinhoServ.setState(this._carrinhoServ.carrinhoQtdeChange(p, step));
      !!p && this.#appCarrinhoStore.setQtde(p, step);
      // console.log(this._carrinhoServ.getState());
    } else {
      this.#appServ.produtoLogin = p;
      // this._produtosServ.produtoLogin = p;
      AppService.identificacao$.emit(p);
    } // else
  }
  //#endregion
}
