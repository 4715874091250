<div class="search-area p-relative p-2">
  <mat-form-field appearance="outline"
                  class="w-100">
    <button mat-button
            matPrefix
            mat-icon-button>
      <mat-icon class="o-50">search</mat-icon>
    </button>
    <mat-label>O que você está procurando?</mat-label>
    <input matInput
           type="text"
           [(ngModel)]="searchTerm">
    <button mat-button
            *ngIf="!!searchTerm"
            matSuffix
            mat-icon-button
            (click)="searchTerm = ''">
      <mat-icon class="o-50">close</mat-icon>
    </button>
  </mat-form-field>
</div>