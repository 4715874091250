<div class="preview"
     #searchPreview>
  <!-- <ng-container *ngIf="vm$() | async as vm"> -->
  <!-- <p>vm {{ vm | json }}</p> -->

  <ul class="list-group"
      *ngIf="!!produtos?.length">
    <li class="list-group-item d-flex p-relative"
        *ngFor="let p of produtos; trackBy:tid_pro"> <!-- | produtosLfixes | async -->
      <div class="thumb pointer">
        <img [src]="p?.__img?.thumb"
             class="img-fluid"
             (click)="click$.emit(p)">
      </div>

      <div class="flex-1 d-flex flex-column">
        <div>
          <small>{{ p?.nome }}</small>
          <small class="o-50"
                 *ngIf="p?._base?.embalagem">
            ( {{ p?._base?.embalagem }} )
          </small>
        </div>

        <div class="flex-1 my-auto d-flex">
          <div class="my-auto flex-1">
            <me-html-produto-preco [produto]="p" />
          </div>

          <div class="my-auto flex-1">
            <div class="my-auto">
              <app-produto-btn-comprar [produto]="p"
                                       [light]="true"
                                       (change$)="onQtdeProdutoChanged(p, $event)" />
            </div>
          </div>
        </div>
      </div>
    </li>

    <li *ngIf="!!previewOverflow"
        class="list-group-item d-flex pointer"
        (click)="submit$?.emit()">
      <div class="thumb d-flex">
        <span class="icon-b-search search-plus rem-30 o-30 m-auto"></span>
      </div>

      <div class="alert alert-light my-auto flex-1"
           role="alert">
        Ver mais {{ previewOverflow }} ocorrências...
      </div>
    </li>
  </ul>
  <!-- </ng-container> -->
</div>