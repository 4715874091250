<!-- <p>ord {{ ord | json }}</p> -->

<div *ngIf="!!ord">
  <button mat-button
          [matMenuTriggerFor]="appMenu">
    <span class="o-50 me-1"
          [class]="ord?.icon">
    </span>
    <span *ngIf="!isMobile">{{ ord?.caption }}</span>
    &nbsp;<span class="icon-r-chevron-d o-50"> </span>
  </button>
  <mat-menu #appMenu="matMenu">
    <button mat-menu-item
            (click)="onOrderClick('r')"
            *ngIf="!exceptions.includes('r')">
      <span class="icon-b-bang o-50"></span>&nbsp;<span>Relevância</span>
    </button>

    <button mat-menu-item
            (click)="onOrderClick('na')"
            *ngIf="!exceptions.includes('na')">
      <span class="icon-b-alpha-sort-asc o-50"></span>&nbsp;<span>Nome (A-Z)</span>
    </button>

    <button mat-menu-item
            (click)="onOrderClick('nd')"
            *ngIf="!exceptions.includes('nd')">
      <span class="icon-b-alpha-sort-desc o-50"></span>&nbsp;<span>Nome (Z-A)</span>
    </button>

    <button mat-menu-item
            (click)="onOrderClick('pa')"
            *ngIf="!exceptions.includes('pa')">
      <span class="icon-b-num-sort-asc o-50"></span>&nbsp;<span>Menor preço</span>
    </button>

    <button mat-menu-item
            (click)="onOrderClick('pd')"
            *ngIf="!exceptions.includes('pd')">
      <span class="icon-b-num-sort-desc o-50"></span>&nbsp;<span>Maior preço</span>
    </button>
  </mat-menu>
</div>
