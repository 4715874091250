//#region ng
import {
  Component,
  inject,
  signal
} from '@angular/core';
import {
  ActivatedRoute,
  ParamMap,
} from '@angular/router';
//#endregion

//#region mat
// import { MatSnackBar } from '@angular/material/snack-bar';
//#endregion

//#region firebase
import { Firestore } from '@angular/fire/firestore';
//#endregion

//#region 3rd
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest,
} from 'rxjs';
import {
  filter,
  finalize,
  first,
  map,
  switchMap,
  takeUntil,
  tap
} from 'rxjs/operators';
import { OwlOptions } from 'ngx-owl-carousel-o';
//#endregion

//#region models
interface IVm {
  banners: IBanner[];
  isAtTop: boolean;
  isMobile: boolean;
  // lojaInfo: ILojaInfoState;
};
import {
  IBanner,
  ILoja,
  IProduto
} from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { PRODUTOS_POR_PAG } from '../../models/consts';
import { ICarrinhoMap } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_maps';
import { CorAngularFirePaginator } from '../../_shared/_core/_ng/_models/_classes';
import { ICorPaginatorSort } from '../../_shared/_core/_misc/_models/_interfaces/_misc';
import { PRODUTOS_ORD } from '../../_shared/_mercadeiro/_misc/_models/consts';
//#endregion

//#region libs
import { fixProdutos } from '../../_shared/_mercadeiro/_misc/_libs';
import {
  compareValues,
  uniqueListBy
} from '../../_shared/_libs/_misc/_arrays';
import { onDestroy } from '../../_shared/_core/_ng/_libs';
//#endregion

//#region services
import { AppService } from '../../services';
import {
  BannersService,
  CarrinhoService,
  LojasService,
  ProdutosService
} from '../../_shared/_mercadeiro/_ng/_services';
//#endregion

//#region stores
import { LojasStore } from '../../_shared/_mercadeiro/_ng/_stores';
import { AppCarrinhoStore } from '../../stores';
//#endregion

@Component({
  selector: 'app-loja',
  templateUrl: './loja.page.html',
  styleUrls: ['./loja.page.scss']
})
export class LojaPage {
  //#region actions
  #bannersAction$ = new BehaviorSubject<IBanner[]>(null);
  #destroyAction$: Subject<void> = onDestroy();
  ordAction$ = signal<BehaviorSubject<ICorPaginatorSort>>(new BehaviorSubject<ICorPaginatorSort>(PRODUTOS_ORD[1]));
  produtosAction$ = signal<Subject<IProduto[]>>(new Subject<IProduto[]>());
  //#endregion

  //#region publics
  heroBannersOptions = signal<OwlOptions>(
    {
      autoplay: true,
      autoplayTimeout: 6000,
      center: true,
      loop: true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: false,
      navSpeed: 700,
      navText: ['<i class="icon-b-arrow-l"></i>', '<i class="icon-b-arrow-r"></i>'],
      items: 1,
      nav: true
    }
  ).asReadonly();
  loja = signal<ILoja>(null);
  produtos: IProduto[];
  paginator = signal<CorAngularFirePaginator<IProduto>>(null);
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region privates
  #produtosUnsub: Subscription;
  // #banners: IBanner[];
  #ord: ICorPaginatorSort;
  //#endregion

  //#region injects
  #appCarrinhoStore = inject(AppCarrinhoStore);
  // #appLojasStore = inject(AppLojasStore);
  #bannersServ = inject(BannersService);
  #carrinhoServ = inject(CarrinhoService);
  #db = inject(Firestore);
  #lojasServ = inject(LojasService);
  #lojasStore = inject(LojasStore);
  #produtosServ = inject(ProdutosService);
  #route = inject(ActivatedRoute);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.#route.paramMap
      .pipe(takeUntil(this.#destroyAction$))
      .subscribe(
        (params: ParamMap) => {
          const { id: LOJA_ID, retirada: LOJA_IS_RETIRADA } = params?.['params'];
          // console.log(LOJA_ID, LOJA_IS_RETIRADA, (LOJA_IS_RETIRADA || '').toLowerCase().trim() === 'true');
          // const CARRINHO: TNullable<ICarrinhoMap> = this.#appCarrinhoStore.getState() as ICarrinhoMap;
          const CARRINHO: ICarrinhoMap = this.#appCarrinhoStore.carrinhoGet();
          const CARRINHO_CHANGES: Partial<ICarrinhoMap> = {
            ...CARRINHO,
            __loja: {
              ...CARRINHO?.__loja,
              id: LOJA_ID,
              isRetirada: (LOJA_IS_RETIRADA || '').toLowerCase().trim() === 'true'
            }
          };
          // console.log(JSON.stringify(CARRINHO_CHANGES));
          this.#appCarrinhoStore.setState(CARRINHO_CHANGES);
        }
      );

    combineLatest([
      this.#lojasStore.lojaStateChanged$
        .pipe(filter((loja: ILoja) => !!loja?.__idInfo?.lojaPath)),
      this.ordAction$(),
    ])
      .pipe(
        switchMap(
          ([loja, ord]) => this.#bannersServ.docs(this.loja()?.__idInfo?.lojaPath || '')
            .pipe(
              tap((banners: IBanner[]) => {
                // console.log(carrinho);
                // console.log(produtos);
                // console.log(banners);
                const BANNERS: IBanner[] = this.#bannersServ.fixes(banners, this.loja()?.id || '');
                // console.log(BANNERS);
                this.#bannersAction$.next(BANNERS);

                // console.log(loja, ord);
                this.#ord = ord;
                this.loja.set(!!loja ? this.#lojasServ.fix(loja) : null);
                if (!!loja?.__idInfo?.lojaPath) {
                  this.paginator.set(
                    new CorAngularFirePaginator(
                      this.#db,
                      `${loja?.__idInfo?.lojaPath}/produtos`,
                      PRODUTOS_POR_PAG,
                      [{ field: ord?.field || '', direction: ord?.direction }],
                      [{ field: 'ativo._status', op: '==', val: true }]
                    )
                  );

                  // console.log(carrinho?.__solicitado);
                  this.#produtosUnsub?.unsubscribe();
                  this.#produtosUnsub = this.paginator().items$
                    .subscribe(
                      (produtos: IProduto[]) => {
                        // console.log(produtos);
                        this.produtos = this.#produtosServ.fixes(
                          uniqueListBy(
                            (this.produtos || []).concat(produtos), 'id')
                            .filter((e: IProduto) => !!e?.__displayInPagination)
                            .sort(compareValues(this.#ord?.field || '', this.#ord?.direction)
                            )
                        );

                        const SUB: Subscription = this.#produtosServ.lfixes(
                          uniqueListBy((this.produtos || []).concat(produtos), 'id')
                        )
                          .pipe(
                            first(),
                            finalize(() => SUB?.unsubscribe())
                          )
                          .subscribe(
                            (prods: IProduto[]) => {
                              // console.log(prods);
                              this.produtos = prods;
                              this.produtosAction$().next(prods);
                              this.#appCarrinhoStore.setState(this.#appCarrinhoStore.carrinhoGet());
                            }
                          );
                      }
                    );
                } // if
              })
            )
        ),
        takeUntil(this.#destroyAction$),
      )
      .subscribe();

    this.#appCarrinhoStore.carrinhoItensStateChanged$
      .pipe(
        // startWith(this.#appCarrinhoStore.carrinhoGet()),
        takeUntil(this.#destroyAction$)
      )
      .subscribe(
        (carrinho: ICarrinhoMap) =>
          !!this.produtos && fixProdutos(
            this.produtos,
            !!carrinho ? this.#carrinhoServ.fix(carrinho) : null,
          )
      );

    this.vm$.set(
      combineLatest([
        // this.#appLojasStore.lojaInfoStateChanged$,
        AppService.isMobile$,
        AppService.isAtTop$,
        this.#bannersAction$,
      ])
        .pipe(
          map(([isMobile, isAtTop, banners]) => {
            // console.log(banners);
            const VM: IVm = {
              banners: (banners || [])
                .filter((b: IBanner) => b?._mobile === isMobile)
                .slice(0, 10),
              isMobile,
              isAtTop,
              // loja,
              // lojaInfo,
              // ord,
            };
            // console.log(VM);
            return VM;
          }),
        )
    );
  }

  ngOnDestroy() {
    this.paginator()?.unsub();
    this.#produtosUnsub?.unsubscribe();
  }
  //#endregion

  //#region functions
  tid_ban(index: any, item: IBanner): string { return item?.id || ''; }

  tid_pro(index: any, item: IProduto): string { return item?.id || ''; }
  //#endregion

  //#region methods
  onBannerClick(banner: IBanner) {
    // console.log(banner);
    // const BANNER_TARGET: string = banner?.target || '';
    // if (BANNER_TARGET) {
    //   const TARGET: string = BANNER_TARGET.charAt(0) === '_' ? '/marca' : '/produto';
    //   // console.log(TARGET);
    //   let idTarget: string = BANNER_TARGET;
    //   if (TARGET === '/marca') {
    //     idTarget = idTarget.substr(1);
    //   } // if
    //   // console.log(idTarget);

    //   this.#router.navigate(
    //     [TARGET, idTarget]
    //   );
    // } // if
  }

  onProdutoClick(produto: IProduto) {
    AppService.produtoDetalhes$.next({ produto });
  }

  onCarrinhoClick() {
    AppService.menuCarrinhoStatus$.next(true);
  }
  //#endregion
}
