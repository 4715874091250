<ng-container *ngIf="vm$() | async as vm">
  <div class="modal-body">
    <mat-toolbar class="header"
                 color="primary">
      <mat-toolbar-row class="d-flex justify-content-between">
        <div class="my-auto">
          <h4>
            <small>{{ vm?.produtoSel?.departamentos?.d1?.nome }}</small>
            <span *ngIf="vm?.produtoSel?.departamentos?.d2?.nome">
              <span class="o-50 mx-1">/</span>
              <small>{{ vm?.produtoSel?.departamentos?.d2?.nome }}</small>
            </span>
            <span *ngIf="vm?.produtoSel?.departamentos?.d3?.nome">
              <span class="o-50 mx-1">/</span>
              <small>{{ vm?.produtoSel?.departamentos?.d3?.nome }}</small>
            </span>
          </h4>
        </div>

        <div class="my-auto">
          <button mat-icon-button
                  (click)="onModalCloseClick()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>

    <cor-mat-messages />

    <div class="container-fluid p-4">
      <div class="row">
        <div class="col-12 col-sm-6">
          <me-mat-produto-imgs [produto]="vm?.produtoSel"
                               [isMobile]="!!isMobile" />
        </div>

        <div class="col-12 col-sm-6">
          <div class="d-flex flex-column h-100">
            <div class="flex-1 text-center d-flex flex-column">
              <div class="d-flex justify-content-between">
                <div class="o-50 rem-12"
                     *ngIf="!!vm?.produtoSel?._base?.marca?.nome">
                  {{ vm?.produtoSel?._base?.marca?.nome }}
                </div>

                <small *ngIf="!!vm?.produtoSel?._base?.barcode && !isMobile">
                  <span class="o-50">Sku:</span>&nbsp;{{ vm?.produtoSel?._base?.barcode }}
                </small>
              </div>

              <h4 class="my-2">{{ vm?.produtoSel?.nome }} <small class="o-50"
                       *ngIf="vm?.produtoSel?._base?.embalagem">
                  (&nbsp;{{ vm?.produtoSel?._base?.embalagem }}&nbsp;)
                </small>
              </h4>

              <me-bs-produto-promocoes [produto]="vm?.produtoSel" />

              <me-html-produto-preco [produto]="vm?.produtoSel"
                                     size="lg" />

              <div class="text-start o-60">
                <markdown [data]="vm?.produtoSel?._base?.info" />
              </div>

              <div class="row">
                <div class="col-12 col-sm-8 offset-sm-2">
                  <app-produto-btn-comprar [produto]="vm?.produtoSel"
                                           (change$)="onQtdeProdutoChanged(vm?.produtoSel, $event)" />
                </div>
              </div>

              <div class="row"
                   *ngIf="!!isMobile">
                <div class="col">
                  <div class="mt-2"
                       (click)="onVerCarrinhoClick()">
                    <app-carrinho />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="mt-2">
                    <mat-form-field [appearance]="!!isMobile ? 'fill' : 'outline'"
                                    class="w-100"
                                    *ngIf="!!vm?.itemCarrinho">
                      <mat-label>Observações do produto</mat-label>
                      <textarea matInput
                                [focus]="obsFocusEvent$()"
                                name="obs"
                                [(ngModel)]="obs">
                      </textarea>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex"
                 *ngIf="!!showNav">
              <div class="btn-group d-flex w-100"
                   role="group">
                <button type="button"
                        class="btn btn-light flex-1 d-flex"
                        (click)="onModalCloseClick()">
                  <div class="m-auto">
                    <span class="o-50 icon-r-chevron-l"></span>
                  </div>

                  <div class="flex-1">
                    <small>Continuar<br>comprando</small>
                  </div>
                </button>

                <button type="button"
                        class="btn btn-light flex-1 d-flex">
                  <div class="flex-1"
                       (click)="onVerCarrinhoClick()">
                    <small>Ir para<br>carrinho</small>
                  </div>

                  <div class="m-auto">
                    <span class="o-50 icon-b-shopping-cart"></span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3"
           *ngIf="!!similares?.length">
        <div class="col">
          <div class="card">
            <div class="card-header">
              Similares <small class="o-50">( {{ similares?.length }} )</small>
            </div>

            <section class="similares-cards p-3">
              <owl-carousel-o [options]="similaresOptions()">
                <ng-container *ngFor="let s of similares"
                              class="d-flex">
                  <ng-template carouselSlide
                               [id]="s?.id"
                               [width]="200">
                    <app-produto-card [produto]="s"
                                      (imgClick$)="onProdutoSelClick(s)" />
                  </ng-template>
                </ng-container>
              </owl-carousel-o>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>