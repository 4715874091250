//#region ng
import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
//#endregion

//#region 3rd
import { get } from 'lodash';
//#endregion

//#region libs
import { calcQtdeFracionado } from '../../_shared/_mercadeiro/_misc/_libs';
//#endregion

//#region models
import { IItemCarrinhoMap } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_maps';
//#endregion

@Component({
  selector: 'app-item-btn-comprar',
  templateUrl: './item-btn-comprar.component.html',
  styleUrls: ['./item-btn-comprar.component.scss']
})
export class AppItemBtnComprarComponent {
  //#region inputs
  // item
  @Input({ required: true }) item: IItemCarrinhoMap;
  // light
  @Input() light: boolean = false;
  //#endregion

  //#region outputs
  @Output() change$ = new EventEmitter<number>();
  //#endregion

  //#region methods
  async onQtdeitemClick(
    item: IItemCarrinhoMap,
    step: number = 1
  ) {
    // console.log(step, item);
    // if (produto) {
    // const QTDE: number = calcQtdeFracionado(get(item, 'produto'), step);
    //   // console.log(qtde);
    //   // this.qtdeUn = qtde;
    //   // console.log(this.qtdeUn);
    // this.change$.emit(Number(QTDE.toFixed(3)) || 0);
    this.change$.emit(step);
    // } // if
  }
  //#endregion
}
