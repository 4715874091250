//#region ng
import {
  Component,
  Input,
  inject,
  signal
} from '@angular/core';
//#endregion

//#region 3rd
import {
  Observable,
  combineLatest,
} from 'rxjs';
import { map } from 'rxjs/operators';
//#endregion

//#region models
interface IVm {
  carrinho: ICarrinhoMap;
  entregaRetiradaCaption: string;
  loja: ILoja;
  // lojaInfo: ILojaInfoState;
};
import { ICarrinhoMap, IItemCarrinhoMap } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_maps';
import { ILoja } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
//#endregion

//#region services
import { CarrinhoService } from '../../_shared/_mercadeiro/_ng/_services';
//#endregion

//#region stores
import { LojasStore } from '../../_shared/_mercadeiro/_ng/_stores';
import {
  AppCarrinhoStore,
  // AppLojasStore
} from '../../stores';
//#endregion

@Component({
  selector: 'app-carrinho',
  templateUrl: './carrinho.component.html',
  styleUrls: ['./carrinho.component.scss']
})
export class AppCarrinhoComponent {
  //#region inputs
  // size
  @Input() size: 'sm' | 'lg' = 'lg';
  //#endregion

  //#region publics
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region injects
  #carrinhoServ = inject(CarrinhoService);
  #appCarrinhoStore = inject(AppCarrinhoStore);
  // #appLojasStore = inject(AppLojasStore);
  #lojasStore = inject(LojasStore);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.vm$.set(
      combineLatest([
        // this.#appCarrinhoStore.carrinhoStateChanged$,
        this.#appCarrinhoStore.carrinhoItensStateChanged$,
        this.#lojasStore.lojaStateChanged$,
      ])
        .pipe(
          map(([carrinho, loja]) => {
            // console.log(carrinho?.__solicitado?.itens);
            const VM: IVm = {
              // carrinho: fixCarrinho(carrinho, loja),
              carrinho: !!carrinho ? this.#carrinhoServ.fix(carrinho) : null,
              entregaRetiradaCaption: !!carrinho?.__loja?.isRetirada ? 'retirada' : 'entrega', // lojaInfo?.retirada ? 'retirada' : 'entrega',
              loja,
              // lojaInfo,
            };
            // console.log(VM?.carrinho?.__solicitado?.itens);
            // (VM?.carrinho?.__solicitado?.itens || [])
            //   .forEach(
            //     (i: IItemCarrinhoMap) => console.log(i?.produto)
            //   );
            // console.log(VM?.carrinho?.__solicitado?.total);
            return VM;
          })
        )
    );
  }
  //#endregion
}
