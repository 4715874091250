//#region ng
import {
  Component,
  Input,
  signal,
} from '@angular/core';
//#endregion

//#region models
import { DIAS_SEMANA_NOME_CURTO } from '../../_shared/_core/_misc/_models/consts';
// import { IHorarioAgendamentoMap } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_maps';
//#endregion

@Component({
  selector: 'app-loja-horarios',
  templateUrl: './loja-horarios.component.html',
  styleUrls: ['./loja-horarios.component.scss']
})
export class AppLojaHorariosComponent {
  //#region inputs
  // horarios
  #horarios: any;
  @Input({ required: true }) set horarios(val: any) {
    this.#horarios = val;
    // console.log(val);
    this.dows.set([]);
    !!val && [0, 1, 2, 3, 4, 5, 6].forEach(
      (dow: number) => {
        const DOW: any[] = val['dow' + dow] || [];
        this.dows.mutate((dows: any[]) => dows.push(DOW));
      }
    );
  }
  get horarios(): any {
    return this.#horarios;
  }
  //#endregion

  //#region publics
  diasSemanaCurto = signal<string[]>(DIAS_SEMANA_NOME_CURTO).asReadonly();
  dows = signal<any[]>([]);
  //#endregion
}
