<ng-container *ngIf="vm$() | async as vm">
  <div class="page"
       [class.no-footer]="!vm?.isMobile">

    <div class="page-header">
      <div *ngIf="!!vm?.isMobile; else headerDesktop">
        <app-loja-m-header [showReturn]="true"
                           (returnClick$)="onGotoHome()" />
      </div>
      <ng-template #headerDesktop
                   class="desktop-lg">
        <app-loja-header [searchTerm]="searchTerm()"
                         [showSearchPreview]="false" />
      </ng-template>
    </div>

    <div class="page-content container"
         [class.lg]="vm?.isAtTop">

      <div class="mt-2">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb d-flex">
            <li class="breadcrumb-item my-auto pointer">
              <a (click)="onGotoHome()">
                <span class="icon-o-home"></span>
              </a>
            </li>

            <li class="breadcrumb-item my-auto">Procura</li>

            <li class="breadcrumb-item my-auto"
                *ngIf="!!searchTerm()">
              {{ searchTerm() }} <small class="o-50"
                     *ngIf="produtos?.length">
                ( {{ hits()?.length }} )
              </small>
            </li>

            <li class="ms-auto my-auto"
                *ngIf="!!produtos?.length">
              <app-produtos-ord-sel [ord]="ordAction$() | async"
                                    [isMobile]="vm?.isMobile"
                                    (change$)="ordAction$().next($event)" />
            </li>
          </ol>
        </nav>
      </div>

      <app-mobile-search *ngIf="vm?.isMobile"
                         [searchTerm]="searchTerm()"
                         (searchChange$)="searchTerm.set($event)"
                         (clearSearch$)="searchTerm.set('')" />

      <div *ngIf="!!produtos; else carregandoProdutos">
        <ul class="w-cards produtos">
          <li *ngFor="let p of produtos; trackBy:tid_pro"> <!-- | produtosLfixes | async -->
            <app-produto-card [produto]="p"
                              (imgClick$)="onProdutoClick($event)" />
          </li>
        </ul>

        <div class="d-flex mb-3"
             *ngIf="itensRestantes() > 0; else listaCarregada">
          <button type="button"
                  class="btn btn-light m-auto round"
                  (click)="onCarregarMaisClick()">
            <span class="o-20 icon-r-chevron-d me-2"></span>Carregar mais produtos...
          </button>
        </div>
        <ng-template #listaCarregada>
          <div class="d-flex mb-3"
               *ngIf="!!produtos.length; else nenhumProduto">
            <div class="alert alert-light text-center flex-1"
                 role="alert">
              Todas ocorrências exibidas.
            </div>
          </div>
          <ng-template #nenhumProduto>
            <div class="text-center mb-5"
                 *ngIf="!produtos.length">
              <span class="icon-r-search warn-icon"></span>
              <br>
              <p>Nenhum produto.</p>
            </div>
          </ng-template>
        </ng-template>
      </div>
      <ng-template #carregandoProdutos>
        <me-html-produtos-skel />
      </ng-template>
    </div>

    <app-loja-footer />
  </div>

  <div class="page-footer"
       *ngIf="vm?.isMobile">
    <mat-toolbar color="primary"
                 (click)="onCarrinhoClick()">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-6 offset-sm-3 pt-3">
            <app-carrinho />
          </div>
        </div>
      </div>
    </mat-toolbar>
  </div>
</ng-container>