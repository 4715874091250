<ng-container *ngIf="vm$() | async as vm">
  <!-- <p>vm {{ vm| json }}</p> -->
  <!-- <p>loja() {{ loja()| json }}</p> -->
  <!-- <p>preview() {{ preview() | json }}</p> -->
  <!-- <p>vm?.isRetirada {{ vm?.isRetirada }}</p> -->

  <div class="header-desktop toolbar-primary d-flex flex-column"
       [ngClass]="{ 'lg': !!vm?.isAtTop, 'sm': !vm?.isAtTop }">
    <div class="container p-relative d-flex flex-column flex-1">
      <div class="thumb m-auto d-flex"
           (click)="onGotoHome()">
        <img *ngIf="!!loja()"
             class="pointer img-fluid m-auto"
             [src]="loja()?.rede?.__logo">
      </div>

      <div class="toolbar-primary flex-1 d-flex"
           *ngIf="vm?.isAtTop">
        <div class="thumb-area d-flex"></div>

        <div class="flex-1 d-flex">
          <div class="m-auto">
            <app-loja-info (change$)="onTrocarLojaClick()" />
          </div>
        </div>

        <div class="action-area d-flex">
          <div class="m-auto">
            <button mat-button
                    [matMenuTriggerFor]="entregaMenu">
              <span class="o-30"
                    [class.icon-o-shopping-basket]="!!vm?.isRetirada"
                    [class.icon-o-truck]="!vm?.isRetirada">
              </span> {{ !!vm?.isRetirada ? 'Retirada' : 'Entrega' }}&nbsp;<span class="icon-r-chevron-d o-50"></span>
            </button>
          </div>
        </div>
      </div>

      <div class="toolbar-primary flex-1 d-flex">
        <div class="thumb-area d-flex"></div>

        <div class="flex-1 d-flex">
          <div class="search-area no-border d-flex ms-2 m-auto w-100">
            <button mat-button
                    [matMenuTriggerFor]="deptosMenu">
              Departamentos&nbsp;<span class="icon-r-chevron-d"></span>
            </button>

            <div class="sep"></div>

            <input type="text"
                   class="form-control flex-1"
                   [(ngModel)]="searchTerm"
                   (keyup.enter)="onSearchSubmit()"
                   (keyup.esc)="onLimpaProcuraClick()"
                   [focus]="onSearchFocusEvent$()"
                   placeholder="O que você procura?"
                   #searchArea>

            <button mat-button
                    matTooltip="Limpa procura"
                    *ngIf="!!searchTerm"
                    (click)="onLimpaProcuraClick()">
              <div class="icon-b-cancel me-2 o-50"></div>
            </button>

            <button mat-button
                    [matTooltip]="!!searchTerm && !!previewOverflow() ? 'Ver mais ' + previewOverflow() + ' ocorrências...' : ''"
                    (click)="onSearchSubmit()">
              <div class="icon-r-search rem-12 me-2 o-50"></div>
            </button>

            <app-search-preview [produtos]="preview()"
                                [previewOverflow]="previewOverflow()"
                                (click$)="onProdutoClick($event)"
                                (submit$)="onSearchSubmit()"
                                (clickOutside$)="onOutsidePreviewClick($event)" />
          </div>
        </div>

        <div class="action-area d-flex">
          <!-- <div class="carrinho m-auto pointer"
               (click)="onCarrinhoClick()">
            <app-carrinho size="sm" />
          </div> -->
          <button mat-button
                  class="carrinho m-auto pointer"
                  (click)="onCarrinhoClick()">
            <app-carrinho size="sm" />
          </button>
        </div>

        <div class="icon-area my-auto"
             *ngIf="!vm?.isAtTop">
          <button mat-icon-button
                  (click)="onMenuLojaOpenClick()">
            <mat-icon>menu</mat-icon>
          </button>
        </div>
      </div>

      <div class="toolbar-primary flex-1 d-flex"
           *ngIf="vm?.isAtTop">
        <div class="thumb-area"></div>

        <div class="flex-1 d-flex">
          <div class="m-auto">
            <app-endereco-info [canChange]="!vm?.route?.includes('/suaConta/seusEnderecos')"
                               (change$)="onTrocarEnderecoClick()" />
          </div>
        </div>

        <div class="action-area d-flex conta-actions">
          <div class="m-auto">
            <app-conta-actions (logout$)="onLogoutClick()" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <cor-mat-messages [isMain]="true" />

  <!-- Início menus -->
  <mat-menu #entregaMenu="matMenu"
            yPosition="below">
    <button mat-menu-item
            [disabled]="!vm?.isRetirada"
            routerDirection="root"
            [routerLink]="['/loja', loja()?.id, 'false']"
            *ngIf="loja()?.entrega?.status.includes('E')">
      <span class="o-30 me-3 icon-o-truck"></span>
      Entrega <small class="o-80"
             *ngIf="!!loja()?.pedidos?.minimo?.entrega">
        ( pedido mínimo {{ loja()?.pedidos?.minimo?.entrega | currency: "BRL":"symbol":"1.2-2" }} )
      </small>
    </button>

    <button mat-menu-item
            [disabled]="!!vm?.isRetirada"
            routerDirection="root"
            [routerLink]="['/loja', loja()?.id, 'true']"
            *ngIf="loja()?.entrega?.status.includes('R')">
      <span class="o-30 me-3 icon-o-shopping-basket"></span>
      Retirada <small class="o-80"
             *ngIf="!!loja()?.pedidos?.minimo?.retirada">
        ( pedido mínimo {{ loja()?.pedidos?.minimo?.retirada | currency: "BRL":"symbol":"1.2-2" }} )
      </small>
    </button>
  </mat-menu>

  <mat-menu #deptosMenu="matMenu"
            yPosition="below">
    <button mat-menu-item
            [routerLink]="['/departamentos']">
      [ VER TODOS ]
    </button>
    <div *ngFor="let d1 of departamentos(); trackBy:tid_dep">
      <div *ngIf="d1._subs.length; else noD2">
        <button mat-menu-item
                [matMenuTriggerFor]="D2menu">
          {{ d1?.nome }} <!-- <small>{{ d1?.id }}</small> -->
        </button>
      </div>
      <ng-template #noD2>
        <button mat-menu-item>
          {{ d1?.nome }} <!-- <small>{{ d1?.id }}</small> -->
        </button>
      </ng-template>

      <mat-menu #D2menu="matMenu">
        <button mat-menu-item
                [routerLink]="['/departamentos']"
                [queryParams]="{ id: d1?.id }">
          <!-- [queryParams]="{ d1: d1?.id }" -->
          [ VER TODOS ]
        </button>
        <div *ngFor="let d2 of d1?._subs; trackBy:tid_dep">
          <div *ngIf="d2._subs.length; else noD3">
            <button mat-menu-item
                    [matMenuTriggerFor]="D3menu">
              {{ d2?.nome }} <!-- <small>{{ d2?.id }}</small> -->
            </button>
          </div>
          <ng-template #noD3>
            <button mat-menu-item
                    [routerLink]="['/departamentos']"
                    [queryParams]="{ id: d2?.id }">
              <!-- [queryParams]="{ d1: d1?.id, d2: d2?.id }" -->
              {{ d2?.nome }} <!-- <small>{{ d2?.id }}</small> -->
            </button>
          </ng-template>

          <mat-menu #D3menu="matMenu">
            <button mat-menu-item
                    [routerLink]="['/departamentos']"
                    [queryParams]="{ id: d2?.id }">
              <!-- [queryParams]="{ d1: d1?.id, d2: d2?.id }" -->
              [ VER TODOS ]
            </button>
            <button *ngFor="let d3 of d2?._subs; trackBy:tid_dep"
                    mat-menu-item
                    [routerLink]="['/departamentos']"
                    [queryParams]="{ id: d3?.id }">
              <!-- [queryParams]="{ d1: d1?.id, d2: d2?.id, d3: d3?.id }" -->
              {{ d3?.nome }} <!-- <small>{{ d3?.id }}</small> -->
            </button>
          </mat-menu>
        </div>
      </mat-menu>
    </div>
  </mat-menu>
  <!-- Final menus -->
</ng-container>