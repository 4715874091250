<ng-container *ngIf="vm$() | async as vm">
  <div class="page"
       [class.no-footer]="!vm?.isMobile">
    <div class="page-header">
      <div *ngIf="vm?.isMobile; else headerDesktop">
        <app-loja-m-header voltar="false"></app-loja-m-header>
      </div>
      <ng-template #headerDesktop
                   class="desktop-lg">
        <app-loja-header></app-loja-header>
      </ng-template>
    </div>

    <div class="page-content"
         [class.lg]="vm?.isAtTop">

      <div class="container">
        <div class="mt-2">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item my-auto pointer">
                <a (click)="onGotoHome()">
                  <span class="icon-o-home"></span>
                </a>
              </li>

              <li class="breadcrumb-item active my-auto"><small>Sua conta</small></li>

              <li class="breadcrumb-item active my-auto"
                  aria-current="page">
                <small>{{ vm?.rota?.caption }}</small>
              </li>
            </ol>
          </nav>
        </div>

        <div class="d-flex mb-4"
             [class.flex-column]="!!vm?.isMobile">
          <div *ngIf="!vm?.isMobile; else mobileNav">
            <ul class="list-group me-4">
              <li class="list-group-item no-shadow no-select pointer"
                  routerLinkActive="active"
                  routerDirection="root"
                  [routerLink]="rota.url"
                  *ngFor="let rota of rotas(); trackBy:tid_rot">
                <span [class]="rota.icon"></span>
                {{ rota?.caption }}
              </li>

              <li class="list-group-item no-shadow no-select pointer"
                  (click)="onLogoutClick()">
                <span class="icon-r-import"></span>
                Sair da conta
              </li>
            </ul>
          </div>
          <ng-template #mobileNav>
            <mat-form-field appearance="fill"
                            class="w-100">
              <mat-select [(ngModel)]="suaContaRouteId">
                <mat-option *ngFor="let r of rotas(); trackBy:tid_rot"
                            [value]="r.id">
                  {{ r?.caption }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-template>

          <div class="flex-1">
            <router-outlet />
          </div>
        </div>
      </div>

      <app-loja-footer />
    </div>
  </div>
</ng-container>
