//#region ng
import { Injectable, inject } from '@angular/core';
import { CanActivate } from '@angular/router';
//#endregion

//#region services
import { AppService } from '../services';
import { CorFbAuthService } from '../_shared/_core/_ng/_services';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class ContaGuard implements CanActivate {
  //#region injects
  #authServ = inject(CorFbAuthService);
  //#endregion

  //#region methods
  canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
      const USER: any = this.#authServ?.getFirebaseUser();
      // console.log(USER);
      if (USER) {
        resolve(true);
      } else {
        // this._goHome();
        AppService.goHome$.emit();
        resolve(false);
      } // if
    });
  }
  //#endregion
}
