<div class="modal-body p-relative">
  <mat-toolbar class="header"
               color="primary">
    <mat-toolbar-row>
      <h3>
        <span class="o-50">Pedido #</span>{{ pedido?.id | numPedido }}
      </h3>

      <span class="flex-1"></span>

      <button mat-icon-button
              (click)="onModalCloseClick()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="container-fluid p-4">
    <me-mat-pedido-info [pedido]="pedido"
                        [isMobile]="isMobile"
                        [lojaPath]="lojaPath" />
  </div>
</div>
