//#region ng
import {
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
  signal
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
//#endregion

//#region 3rd
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
//#endregion

//#region models
import { IPedido } from '../../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
//#endregion

//#region libs
import { onDestroy } from '../../../_shared/_core/_ng/_libs';
//#endregion

//#region sevices
import { PedidosService } from '../../../_shared/_mercadeiro/_ng/_services';
//#endregion

@Component({
  selector: 'app-pedido-resumo',
  templateUrl: './pedido-resumo.component.html',
  styleUrls: ['./pedido-resumo.component.scss']
})
export class AppPedidoResumoComponent {
  //#region actions
  #destroyAction$: Subject<void> = onDestroy();
  //#endregion

  //#region inputs
  // isPedidoOk
  @Input({ required: true }) isPedidoOk: boolean;
  // isMobile
  @Input() isMobile: boolean = false;
  // cpfCnpj
  #cpfCnpj: string = '';
  get cpfCnpj(): string { return this.#cpfCnpj; }
  @Input() set cpfCnpj(val: string) {
    this.#cpfCnpj = val;
    // console.log(val);
    this.isCpf.set((val || '').length === 11);
  }
  // changes
  private _changes: Partial<IPedido>;
  get changes(): Partial<IPedido> { return this._changes; }
  @Input({ required: true }) set changes(val: Partial<IPedido>) {
    // console.log(val);
    this._changes = val;
    this.pedido.set(
      !!val
        ? this.#pedidosServ.fix(val)
        : null
    );
  };
  //#endregion

  //#region outputs
  @Output() submit$ = new EventEmitter<void>();
  //#endregion

  //#region publics
  isCpf = signal<boolean>(null);
  resumoForm = signal<FormGroup>(null);
  pedido = signal<IPedido>(null);
  //#endregion

  //#region injects
  #fb = inject(FormBuilder);
  #pedidosServ = inject(PedidosService);
  //#endregion

  //#region constructor
  constructor() {
    this.resumoForm.set(
      this.#fb.group({
        // cpfNota: [false],
        docNota: this.#fb.group({
          status: [false],
          _tipo: [''],
          _val: [''],
        }),
        obs: [''],
      })
    );
  }
  //#endregion

  //#region Controls getters
  get docNotaStatusRef(): AbstractControl { return this.resumoForm()?.get('docNota.status'); }
  get docNotaTipoRef(): AbstractControl { return this.resumoForm()?.get('docNota._tipo'); }
  get docNotaValRef(): AbstractControl { return this.resumoForm()?.get('docNota._val'); }
  get docNotaObsRef(): AbstractControl { return this.resumoForm()?.get('obs'); }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.docNotaStatusRef?.valueChanges
      .pipe(takeUntil(this.#destroyAction$))
      .subscribe(
        (status: boolean) => {
          this.resumoForm().patchValue(
            {
              docNota: {
                // status: status,
                _tipo: !!this.isCpf ? 'CPF' : 'CNPJ',
                _val: !!status ? this.cpfCnpj : '',
              }
            }
          );
        }
      );
  }
  //#endregion
}
