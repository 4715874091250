<ng-container *ngIf="vm$() | async as vm">

  <div class="d-flex flex-column">
    <div class="cart-icon">
      <app-carrinho-info />
    </div>

    <mat-progress-bar mode="determinate"
                      class="mb-2"
                      [value]="vm?.carrinho?.__limites?.[vm?.entregaRetiradaCaption]?.perc * 100" />

    <div class="move-up">
      <div *ngIf="!!vm?.loja && vm?.carrinho?.__limites?.[vm?.entregaRetiradaCaption]?.perc < 1; else minimoOk">
        <div class="d-flex justify-content-between">
          <small class="o-70 rem-8">{{ vm?.carrinho?.__limites?.[vm?.entregaRetiradaCaption]?.perc * 100 | number :
            '1.2-2' }}%</small>
            
          <div class="rem-8">
            <small class="o-70">
              {{ size === 'lg' ? 'Pedido mínimo' : 'Mínimo' }}
            </small>
            <small>
              {{ vm?.carrinho?.__limites?.[vm?.entregaRetiradaCaption]?.val | currency: "BRL":"symbol":"1.2-2" }}
            </small>
          </div>
        </div>
      </div>
      <ng-template #minimoOk>
        <div class="minimo-ok progress-labels text-center">
          <span class="o-70 rem-8">
            <small>Pedido mínimo alcançado. <span class="icon-b-check o-100"></span></small>
          </span>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>
