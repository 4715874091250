<ng-container *ngIf="vm$() | async as vm">

  <!-- <p>vm {{ vm | json }}</p> -->

  <div class="loader-container"
       *ngIf="vm?.isLoading">
    <div class="spinner-container">
      <mat-spinner class="spinner"></mat-spinner>
    </div>
  </div>

  <div id="home">
    <router-outlet />
  </div>

  <!-- Go top button -->
  <button id="goTopBtn"
          (click)="onGotoTopClick()"
          *ngIf="!vm?.isAtTop">
    <span class="icon-b-double-chevron-u"
          [class.rem-20]="!vm?.isMobile">
    </span>
  </button>
</ng-container>