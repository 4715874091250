//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
//#endregion

//#region 3rd
import {
  BehaviorSubject,
  Observable,
} from "rxjs";
//#endregion

//#region models
import { IDepartamento } from '../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { IFormasPgtoGrupos } from '../_shared/_mercadeiro/_misc/_models/_interfaces/_misc';
import { ID_DEPTO_INDEFINIDO } from '../_shared/_core/_misc/_models/consts';
//#endregion

//#region services
// import { CorStorageService } from '../_shared/_core/_ng/_services';
import { DepartamentosService } from '../_shared/_mercadeiro/_ng/_services';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class AppLojasStore {
  //#region lojaDepartamentosState
  #lojaDepartamentosState: Partial<IDepartamento>[];
  getLojaDepartamentosState(): Partial<IDepartamento>[] { return this.#lojaDepartamentosState; }
  setLojaDepartamentosState(val: Partial<IDepartamento>[]) {
    // console.log(val);
    this.#lojaDepartamentosState = !!val
      ? this.#departamentosServ.fixes(val as Partial<IDepartamento>[])
        .filter((d: IDepartamento) => d?.id !== ID_DEPTO_INDEFINIDO)
      : [];
    this.#lojaDepartamentosStateSubject$.next(this.#lojaDepartamentosState as IDepartamento[]);
  }
  //#endregion

  //#region lojaFormasGruposState
  #lojaFormasGruposState: IFormasPgtoGrupos;
  getLojaFormasGruposState(): IFormasPgtoGrupos { return this.#lojaFormasGruposState; }
  setLojaFormasGruposState(val: IFormasPgtoGrupos) {
    this.#lojaFormasGruposState = val || null;
    this.#lojaFormasGruposStateSubject$.next(this.#lojaFormasGruposState as IFormasPgtoGrupos);
  }
  //#endregion

  //#region events
  // lojaFormasGruposStateChanged$
  #lojaFormasGruposStateSubject$ = new BehaviorSubject<IFormasPgtoGrupos>(null);
  lojaFormasGruposStateChanged$: Observable<IFormasPgtoGrupos> = this.#lojaFormasGruposStateSubject$?.asObservable();
  // lojaDepartamentosStateChanged$
  #lojaDepartamentosStateSubject$ = new BehaviorSubject<IDepartamento[]>([]);
  lojaDepartamentosStateChanged$: Observable<IDepartamento[]> = this.#lojaDepartamentosStateSubject$?.asObservable();
  //#endregion

  //#region injects
  #departamentosServ = inject(DepartamentosService);
  //#endregion
}
