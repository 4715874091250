//#region ng
import {
  Component,
  EventEmitter,
  ViewChild,
  effect,
  inject,
  signal
} from '@angular/core';
import { Router } from '@angular/router';
//#endregion

//#region firebase
import {
  doc,
  Firestore,
  onSnapshot,
  Unsubscribe as FbUnsubscribe,
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import {
  Observable,
  Subject,
  Subscription,
  combineLatest
} from 'rxjs';
import {
  filter,
  finalize,
  first,
  map,
  takeUntil,
  tap
} from 'rxjs/operators';
import Swal from 'sweetalert2';
import {
  BsModalRef,
  BsModalService
} from 'ngx-bootstrap/modal';
//#endregion

//#region models
interface IVm {
  isMobile: boolean;
};
import {
  FOCUS_TIMEOUT,
  NO_COLOR,
  ODOCS_TAG
} from '../../_shared/_core/_misc/_models/consts';
import { ICorViacepApi } from '../../_shared/_core/_misc/_models/_interfaces/_apis';
import { IConta } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { TNullable } from '../../_shared/_core/_misc/_models/_types';
//#endregion

//#region libs
import { guid } from '../../_shared/_libs/_misc/_strings';
import { splitLocalidadeId } from '../../_shared/_mercadeiro/_misc/_libs';
import { onDestroy } from '../../_shared/_core/_ng/_libs';
//#endregion

//#region services
import { AppService } from '../../services';
import {
  CorCepService,
  CorLoaderService
} from '../../_shared/_core/_ng/_services';
import { ContasService } from '../../_shared/_mercadeiro/_ng/_services';
//#endregion

//#region stores
import { ContasStore } from '../../_shared/_mercadeiro/_ng/_stores';
import {
  AppEnderecosStore,
  AppLojasStore
} from '../../stores';
//#endregion

//#region components
import {
  CompletaEnderecoModal,
  SelecionaEnderecoModal
} from '../../modals';
//#endregion

@Component({
  selector: 'app-onde',
  templateUrl: './onde.page.html',
  styleUrls: ['./onde.page.scss']
})
export class OndePage {

  //#region actions
  #destroyAction$: Subject<void> = onDestroy();
  //#endregion

  //#region viewchilds
  // loaderSwalRef
  @ViewChild('loaderSwal')
  public readonly loaderSwalRef: SwalComponent;
  //#endregion

  //#region publics
  cep = signal<string>('');
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region conta
  #conta: IConta;
  get conta(): IConta { return this.#conta; }
  set conta(val: IConta) {
    // console.log(val);
    this.#conta = !!val ? this.#contasServ.fix(val) : null;
    const CONTA_ID: string = val?.id || '';
    // console.log(CONTA_ID);
    if (!!CONTA_ID) {
      this.loaderSwalRef?.fire();
      const CONTA_PATH: string = `/_contas/${CONTA_ID}/conta-enderecos/${ODOCS_TAG}`
      // console.log(CONTA_PATH);
      this.#enderecosUnsub && this.#enderecosUnsub();
      this.#enderecosUnsub = onSnapshot(
        doc(this.#db, CONTA_PATH),
        snap => {
          // console.log(snap.data());
          if (!!snap.id) {
            this.loaderSwalRef?.close();
            this.onSelecionarEnderecoClick();            
          } // if
        }
      );
    } // if
  }
  //#endregion

  //#region privates
  #enderecosUnsub: FbUnsubscribe;
  #bsSelEnderecoModalRef: BsModalRef;
  #idModalSelEndereco: TNullable<number>;
  #vm: IVm;
  //#endregion

  //#region events
  cepFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>());
  //#endregion

  //#region injects
  #appEnderecosStore = inject(AppEnderecosStore);
  // #appLojasStore = inject(AppLojasStore);
  #bsModalServ = inject(BsModalService);
  #cepServ = inject(CorCepService);
  #contasServ = inject(ContasService);
  #contasStore = inject(ContasStore);
  #db = inject(Firestore);
  #loaderServ = inject(CorLoaderService);
  #router = inject(Router);
  //#endregion

  //#region constructor
  constructor() {
    effect(() => {
      const CEP: string = (this.cep() || '').trim();
      this.onConsultaCep(CEP);
    })
  }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.#appEnderecosStore.setState(null);
    // this.#appLojasStore.setLojaInfoState(null);

    this.#contasStore.contaStateChanged$
      .pipe(takeUntil(this.#destroyAction$))
      .subscribe(
        (conta: IConta) => this.conta = conta);

    this.vm$.set(
      AppService.isMobile$
        .pipe(
          map(
            (isMobile: boolean) => {
              // console.log(conta);
              this.#vm = { isMobile };
              // console.log(this.#vm);
              return this.#vm;
            }
          )
        )
    );
  }

  ngAfterViewInit() {
    !this.#vm?.isMobile && this.#focus();
  }

  ngOnDestroy(): void {
    !!this.#enderecosUnsub && this.#enderecosUnsub();
  }
  //#endregion

  //#region functions
  #focus() {
    // console.log('cep focus', this.isMobile);
    !this.#vm?.isMobile && setTimeout(
      () => { this.cepFocusEvent$().emit(true); },
      FOCUS_TIMEOUT
    );
  }

  #selEndereco(endereco: ICorViacepApi) {
    if (!!endereco) {
      this.#appEnderecosStore?.setState(endereco);
      // const UF: string = (get(endereco, 'uf') || '').toLowerCase();
      const BAIRRO: string = endereco?.bairro || '';
      const [UF, LOCALIDADE, LOCALIDADE_ID] = splitLocalidadeId(
        endereco?.uf || '',
        endereco?.localidade || ''
      );
      // console.log(UF, LOCALIDADE, encodeURI(LOCALIDADE_ID), BAIRRO);

      this.#router.navigate(
        ['/lojas'],
        {
          queryParams: {
            // filtro: this.filtro,
            locId: encodeURI(LOCALIDADE_ID),
            // idRede: this._idRede,
            bairro: encodeURI(BAIRRO),
          }
        }
      );
    } // if
  }
  //#endregion

  //#region methods
  onSwalLoaderDidOpen() {
    Swal.showLoading();
    Swal.disableButtons();
  }

  onLimpaProcuraClick() {
    this.cep.set('');
    this.#focus();
  }

  onEntrarClick() {
    AppService.identificacao$.emit();
  }

  onLogoutClick() {
    AppService.logout$.emit('/onde');
  }

  onSelecionarEnderecoClick() {
    // console.log(this.#bsModalServ.getModalsCount());
    if (
      !!this.conta
      && !this.#bsModalServ.getModalsCount()
      && !this.#idModalSelEndereco
    ) {
      this.#idModalSelEndereco = +guid(10, 10);
      this.#bsSelEnderecoModalRef = this.#bsModalServ.show(
        SelecionaEnderecoModal,
        {
          class: 'modal-dialog-centered',
          id: this.#idModalSelEndereco,
          initialState: {
            isMobile: this.#vm?.isMobile,
            idModal: this.#idModalSelEndereco,
            idEndSel: this.conta?._idEnderecoFav,
            conta: this.conta
          },
        });

      const SUB: Subscription = this.#bsSelEnderecoModalRef?.onHidden
        .pipe(first(), finalize(() => SUB?.unsubscribe()))
        .subscribe(
          () => {
            this.#idModalSelEndereco = null;
            if (!!this.#bsSelEnderecoModalRef?.content?.data()) {
              this.#selEndereco(this.#bsSelEnderecoModalRef?.content?.data());
            } else {
              this.#focus();
            } // if
          }
        );
    } // if
  }

  onConsultaCep(cep: string) {
    if (cep?.length === 8) {
      // this.#loaderServ.lstart();
      const SUB: Subscription = this.#loaderServ.showUntilCompleted(
        this.#cepServ.cep(cep)
          .pipe(first(), finalize(() => SUB?.unsubscribe()))
      )
        .subscribe(
          (viacep: ICorViacepApi) => {
            // console.log({ viacep });
            const ERRO: boolean = !!viacep?.erro;
            if (!!ERRO) {
              this.#focus();
              Swal.fire({
                icon: "error",
                title: "Ooops...",
                html: "<h2>CEP inválido ou não encontrado!</h2>",
                backdrop: false,
                confirmButtonColor: NO_COLOR,
                timer: 3000
              })
              // .then((result: any) => this.submitted$.emit(null));
            } else {
              const MODAL_ID: number = +guid(10, 10);
              const MODAL_REF: any = this.#bsModalServ.show(
                CompletaEnderecoModal,
                {
                  class: 'modal-dialog-centered modal-lg',
                  id: MODAL_ID,
                  initialState: {
                    isMobile: this.#vm?.isMobile,
                    idModal: MODAL_ID,
                    endereco: {
                      ...viacep,
                      complemento: ''
                    }
                  },
                });

              const SUB: Subscription = MODAL_REF?.onHidden
                .pipe(first(), finalize(() => SUB?.unsubscribe()))
                // .pipe(filter(() => !!MODAL_REF?.content?.data()))
                // .pipe(tap(() => console.log(MODAL_REF?.content?.data())))
                .subscribe(
                  () => {
                    if (!!MODAL_REF?.content?.data()) {
                      this.#selEndereco(MODAL_REF?.content?.data());
                    } else {
                      this.#focus();
                    } // if
                  }
                );
            } // if
          },
        );
    } // if
  }
  //#endregion
}
