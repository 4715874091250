<!-- <p>item {{ item | json }}</p> -->
<!-- <p>item?.__solicitado {{ item?.__solicitado | json }}</p> -->

<div *ngIf="item?.__solicitado?.qtde?.val > 0">
  <div class="btn btn-qtde round w-100"
       [class.light]="!!light">
    <span class="qtde-icon qtde-icon-left"
          [ngClass]="{'icon-s-cancel-coin': !!item?.__solicitado?.qtde?.isMin, 'icon-s-minus-coin': !item?.__solicitado?.qtde?.isMin }"
          (click)="onQtdeitemClick(item, -1)">
    </span>

    <!-- <span class="qtde rem-12">
      {{ item?.__solicitado?.qtde?.val | number : '1.0-3' }}
    </span> -->

    <span class="qtde rem-12">
      {{ item?.__solicitado?.qtde?.fixed | number : '1.0-3' }}
      <span *ngIf="item?.produto?.fracionado?._status">
        <!--  && !(item?.produto?.fracionado?.unidade?.fracaoAproxUn) && item?.produto?.__qtdeCarrinho?.tipoQtde === 'Q' -->
        {{ item?.produto?.fracionado?.unidade?._legenda }}
      </span>
    </span>

    <span class="icon-s-plus-coin qtde-icon qtde-icon-right"
          (click)="onQtdeitemClick(item)">
    </span>
  </div>
</div>
