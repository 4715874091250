//#region ng
import {
  Component,
  Input,
  inject,
  signal
} from "@angular/core";
import { Router } from "@angular/router";
//#endregion

//#region 3rd
import {
  Subject,
  takeUntil
} from "rxjs";
//#endregion

//#region models
import {
  IConta,
  IDepartamento,
  ILoja
} from "../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols";
import { SUA_CONTA_ROTAS } from "../../models/consts";
import { ICorRota } from "../../_shared/_core/_misc/_models/_interfaces/_misc";
//#endregion

//#region libs
import { onDestroy } from "../../_shared/_core/_ng/_libs";
import { compareValues } from "../../_shared/_libs/_misc/_arrays";
//#endregion

//#region services
import { AppService } from "../../services";
import { DepartamentosService } from "../../_shared/_mercadeiro/_ng/_services";
//#endregion

//#region stores
import { AppEnderecosStore, AppLojasStore } from "../../stores";
//#endregion

@Component({
  selector: "app-loja-menu-modal",
  templateUrl: "loja-menu.modal.html",
  styleUrls: ["loja-menu.modal.scss"]
})
export class AppLojaMenuModal {
  //#region actions
  #destroyAction$: Subject<void> = onDestroy();
  //#endregion

  //#region inputs
  // conta
  @Input({ required: true }) conta: IConta;
  // idModal
  @Input({ required: true }) idModal: number;
  // isRetirada
  @Input() isRetirada: boolean;
  // loja
  @Input({ required: true }) loja: ILoja;
  //#endregion

  //#region publics
  departamentos = signal<IDepartamento[]>(null);
  rotas = signal<any[]>(SUA_CONTA_ROTAS).asReadonly();
  //#endregion

  //#region injects
  #appEnderecoStore = inject(AppEnderecosStore);
  #appLojasStore = inject(AppLojasStore);
  #departamentosServ = inject(DepartamentosService);
  #router = inject(Router);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.#appLojasStore.lojaDepartamentosStateChanged$
      .pipe(takeUntil(this.#destroyAction$))
      .subscribe((deptos: Partial<IDepartamento[]>) => {
        // console.log(deptos);
        this.#departamentosServ.geraMenu(
          deptos,
          (deptos: IDepartamento[]) => this.departamentos.set(
            !!deptos
              ? this.#departamentosServ.fixes(deptos)
                .sort(compareValues('nome'))
              : null
          )
        )
      });
  }
  //#endregion

  //#region functions
  tid_dep(index: any, item: IDepartamento | Partial<IDepartamento>): string { return item?.id || ''; }

  tid_rot(index: any, item: ICorRota): string { return item?.titulo || ''; }
  //#endregion

  //#region methods
  onMenuCloseClick() {
    AppService.menuLojaStatus$.next(false);
  }

  onLogoutClick() {
    AppService.logout$.emit('home');
  }

  onTrocarEnderecoClick() {
    // console.log('onTrocarEnderecoClick');
    this.onMenuCloseClick();
    if (!!this.conta) {
      this.#router.navigateByUrl('/suaConta/seusEnderecos');
    } else {
      AppService.logout$.emit('/onde');
    } // else
  }

  onTrocarLojaClick() {
    this.onMenuCloseClick();
    // console.log(this.loja?._idLocalidade, this.loja?.endereco?.bairro, this.loja?.rede?.id);
    this.#router.navigate(
      ['/lojas'],
      {
        queryParams: {
          locId: encodeURI(this.loja?._idLocalidade || ''),
          // bairro: encodeURI(this.loja?.endereco?.bairro || ''),
          bairro: encodeURI(this.#appEnderecoStore.getState()?.bairro || ''),
          redeId: encodeURI(this.loja?.rede?.id || ''),
        }
      }
    );
  }
  //#endregion
}
