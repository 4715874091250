//#region ng
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//#endregion

//#region mat
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
//#endregion

//#region modules
import { CorMatComponentsModule } from '../../_shared/_core/_ng-ui/_mat/cor-mat-components.module';
//#endregion

//#region components
import { AppIdentificacaoModal } from '..';
//#endregion

@NgModule({
  imports: [
    CommonModule,
    CorMatComponentsModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
  ],
  declarations: [
    AppIdentificacaoModal,
  ],
  exports: [
    AppIdentificacaoModal,
  ],
})
export class AppIdentificacaoModalModule { }
