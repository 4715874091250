//#region ng
import {
  Component,
  EventEmitter,
  inject,
  signal
} from '@angular/core';
import {
  ActivatedRoute,
  Router
} from '@angular/router';
//#endregion

//#region 3rd
import Fuse from 'fuse.js';
import {
  Observable,
  Subject,
  Subscription,
  combineLatest
} from 'rxjs';
import {
  finalize,
  first,
  map,
  startWith,
  takeUntil,
} from 'rxjs/operators';
//#endregion

//#region models
interface IVm {
  bairro: string;
  endereco: ICorViacepApi;
  entregaRetirada: TLojaTipoEntrega;
  // isMobile: boolean;
  locId: string;
  lojas: ILoja[];
  origem: TOrigemLoja;
  redeId: string;
  searchTerm: string;
};
type TOrigemLoja = 'localidade' | 'rede';
import { ILoja } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import {
  TDistribuidoraRota,
  TLojaTipoEntrega
} from '../../_shared/_mercadeiro/_misc/_models/_types';
import { ICorViacepApi } from '../../_shared/_core/_misc/_models/_interfaces/_apis';
//#endregion

//#region libs
import { calcLocalidadeId } from '../../_shared/_mercadeiro/_misc/_libs';
import { compareValues } from '../../_shared/_libs/_misc/_arrays';
import { onDestroy } from '../../_shared/_core/_ng/_libs';
//#endregion

//#region services
import { AppService } from '../../services';
import { LojasService } from '../../_shared/_mercadeiro/_ng/_services';
import {
  CorCepService,
  CorLoaderService,
  CorMessagesService
} from '../../_shared/_core/_ng/_services';
//#endregion

//#region stores
import { AppEnderecosStore } from '../../stores';
//#endregion

@Component({
  selector: 'app-lojas',
  templateUrl: './lojas.page.html',
  styleUrls: ['./lojas.page.scss']
})
export class LojasPage {
  //#region actions
  #destroyAction$: Subject<void> = onDestroy();
  entregaRetiradaAction$ = signal<Subject<TLojaTipoEntrega>>(new Subject<TLojaTipoEntrega>()).asReadonly();
  origemAction$ = signal<Subject<TOrigemLoja>>(new Subject<TOrigemLoja>()).asReadonly();
  searchTermAction$ = signal<Subject<string>>(new Subject<string>()).asReadonly();
  //#endregion

  //#region publics
  isMobile = signal<boolean>(null);
  vm$ = signal<Observable<IVm>>(null)
  //#endregion

  //#region privates
  #lojas: ILoja[];
  #fuse: Fuse<ILoja>;
  //#endregion

  //#region events
  searchFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>()).asReadonly();;
  //#endregion

  //#region injects
  #appEnderecosStore = inject(AppEnderecosStore);
  #cepServ = inject(CorCepService);
  #loaderServ = inject(CorLoaderService);
  #lojasServ = inject(LojasService);
  #msgServ = inject(CorMessagesService);
  #route = inject(ActivatedRoute);
  #router = inject(Router);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.#msgServ.send();

    AppService.isMobile$
      .pipe(takeUntil(this.#destroyAction$))
      .subscribe(
        (isMobile: boolean) => {
          this.isMobile.set(isMobile);
        }
      );

    const SUB: Subscription = this.#loaderServ.showUntilCompleted(
      this.#lojasServ
        .docs()
        .pipe(first(), finalize(() => SUB?.unsubscribe()))
    )
      .subscribe((lojas: ILoja[]) => {
        // console.log(lojas);        
        this.#lojas = !!lojas ? this.#lojasServ.fixes(lojas) : null;
        this.searchTermAction$().next('');
      });

    // http://localhost:4200/#/lojas?locId=uberaba--mg&bairro=Merc%25C3%25AAs&redeId=modelo
    this.vm$.set(
      combineLatest([
        this.origemAction$().pipe(startWith('localidade')),
        this.searchTermAction$(),
        // AppService.isMobile$,
        this.#route.queryParams,
        this.entregaRetiradaAction$().pipe(startWith('ER')),
        this.#appEnderecosStore.enderecoStateChanged$
      ])
        .pipe(
          map(([origem, searchTerm, /* isMobile, */ queryParams, entregaRetirada, endereco]) => {
            // console.log(entregaRetirada);
            // console.log(queryParams);
            // console.log(this.#lojas);
            // console.log(endereco);
            const BAIRRO: string = decodeURI(queryParams['bairro'] || '*');
            const LOC_ID: string = decodeURI(queryParams['locId'] || '');
            const REDE_ID: string = decodeURI(queryParams['redeId'] || '');
            // console.log(BAIRRO, LOC_ID, REDE_ID);            
            (!LOC_ID && !REDE_ID) && this.#router.navigateByUrl('/onde');

            // console.log(LOC_ID, this.#lojas);
            let lojas: ILoja[] = (this.#lojas || [])
              .filter((l: ILoja) => !!REDE_ID && origem === 'rede' ? l?.rede?.id === REDE_ID : true)
              .filter((l: ILoja) => !!LOC_ID ? l?._idLocalidade === LOC_ID : true)
              .filter((l: ILoja) => !!l?.ativo?._status)
              .filter((l: ILoja) => {
                const STATUS: TLojaTipoEntrega = l?.entrega?.status;
                // console.log(l?.nome, STATUS, entregaRetirada);                
                if (entregaRetirada === 'ER') return true;
                return STATUS.includes(entregaRetirada);
              })
              .map(
                (l: any) => {
                  const DISTRIBUIDORA_STATUS: boolean = !!l?.distribuidora?.status;
                  const DISTRIBUIDORA_TIPO_ROTA: TDistribuidoraRota = l?.distribuidora?.tipoRota;
                  // console.log(l?.nome, DISTRIBUIDORA_STATUS, DISTRIBUIDORA_TIPO_ROTA);
                  if (
                    !!DISTRIBUIDORA_STATUS
                    && DISTRIBUIDORA_TIPO_ROTA === 'loc'
                  ) {
                    const DISTRIBUIDORA_LOCALIDADES_IDS: string[] = (l?.distribuidora?.localidades || [])
                      .map((l: any) => l?.id);
                    const LOCALIDADE_ID: string = calcLocalidadeId(l?.endereco);
                    // console.log(LOCALIDADE_ID, DISTRIBUIDORA_LOCALIDADES_IDS);
                    return {
                      ...l,
                      entrega: {
                        ...l?.entrega,
                        __atendido: DISTRIBUIDORA_LOCALIDADES_IDS.includes(LOCALIDADE_ID)
                      }
                    };
                  } // if

                  // console.log(BAIRRO, l?.nome, l?.entrega?._bairros);
                  return {
                    ...l,
                    entrega: {
                      ...l?.entrega,
                      __atendido: !!BAIRRO
                        ? l?.entrega?._bairros.includes(BAIRRO) || l?.entrega?._bairros.includes('*')
                        : true
                    }
                  };
                }
              );

            if (!!searchTerm.trim()) {
              this.#fuse = new Fuse(
                (lojas || []),
                {
                  includeScore: false,
                  keys: [
                    'nome',
                  ],
                  // minMatchCharLength: 3,
                }
              );
              // console.log(this.#fuse.search(searchTerm) );
              // const HITS: any[] = this.#lojasServ.fixes((this.#fuse.search(searchTerm) || []));
              const HITS: any[] = this.#fuse.search(searchTerm) || [];
              // console.log(lojas);
              // console.log(searchTerm, HITS);
              lojas = this.#lojasServ.fixes(
                (HITS || [])
                  .map(h => h?.item as ILoja)
                  .sort(compareValues('nome')
                  )
              );
            } // if

            const VM: IVm = {
              bairro: BAIRRO,
              endereco: !!endereco ? this.#cepServ.fix(endereco) : null,
              entregaRetirada: entregaRetirada as TLojaTipoEntrega,
              // isMobile,
              locId: LOC_ID,
              lojas,
              origem: origem as TOrigemLoja,
              redeId: REDE_ID,
              searchTerm,
            };
            // console.log(VM);
            return VM;
          }),
        )
    );
  }
  //#endregion

  //#region methods
  onGotoOnde() {
    this.#appEnderecosStore.setState(null);
    this.#router.navigateByUrl('/onde');
  }
  //#endregion

  //#region functions
  tid_loj(index: any, item: ILoja): string { return item?.id || ''; }
  //#endregion
}
