//#region ng
import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router
} from '@angular/router';
//#endregion

//#region 3rd
import { get } from 'lodash';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class LocalGuard implements CanActivate {
  //#region injects
  #router = inject(Router);
  //#endregion

  //#region methods
  canActivate(route: ActivatedRouteSnapshot): boolean {
    const ID_LOCALIDADE: string = (get(route, 'queryParams.locId') || '').split('--') || [];
    const BAIRRO: string = get(route, 'queryParams.bairro') || '';
    const OK: boolean = ID_LOCALIDADE.length === 2 && !!BAIRRO;
    // console.log(ID_LOCALIDADE, BAIRRO);
    !OK && this.#router.navigateByUrl('/onde');
    return OK;
  }
  //#endregion
}
