//#region ng
import {
  NgModule,
  LOCALE_ID
} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from "@angular/common";
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import localePt from "@angular/common/locales/pt";
registerLocaleData(localePt);
import { ServiceWorkerModule } from '@angular/service-worker';
//#endregion

//#region firebase
import {
  initializeApp,
  provideFirebaseApp
} from '@angular/fire/app';
import {
  provideAuth,
  getAuth
} from '@angular/fire/auth';
import {
  getFirestore,
  provideFirestore
} from '@angular/fire/firestore';
import {
  getFunctions,
  provideFunctions,
} from '@angular/fire/functions';
//#endregion

//#region mat
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
import {
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  MAT_DATE_FORMATS,
  DateAdapter
} from '@angular/material/core';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_FORMATS
} from '@angular/material-moment-adapter';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  MatSnackBarModule,
  MAT_SNACK_BAR_DEFAULT_OPTIONS
} from '@angular/material/snack-bar';
// import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
//#endregion

//#region 3rd
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ModalModule } from 'ngx-bootstrap/modal';
import {
  NgxMaskDirective,
  NgxMaskPipe,
  provideNgxMask,
} from 'ngx-mask';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MarkdownModule } from 'ngx-markdown';
//#endregion

//#region models
import { environment } from 'src/environments/environment';
//#endregion

//#region modules
import { AppComponentsModule } from './components/components.module';
import { AppRoutingModule } from './app-routing.module';
import { AppIdentificacaoModalModule } from './modals/identificacao/identificacao-modal.module';
import { AppCompletarCadastroModalModule } from './modals/completar-cadastro/completar-cadastro-modal.module';
import { AppProdutoDetalhesModalModule } from './modals/produto-detalhes/produto-detalhes-modal.module';
import { MeHtmlComponentsModule } from './_shared/_mercadeiro/_ng-ui/_html/me-html-components.module';
import { AppCarrinhoMenuModalModule } from './modals/carrinho-menu/carrinho-menu.module';
import { AppLojaMenuModalModule } from './modals/loja-menu/loja-menu.module';
//#endregion

//#endregion
import { AppComponent } from './app.component';
import {
  AcessoNegadoPage,
  CarrinhoPendentePage,
  NotFoundPage
} from './pages';
//#region components

@NgModule({
  imports: [
    AppCarrinhoMenuModalModule,
    AppComponentsModule,
    AppCompletarCadastroModalModule,
    AppIdentificacaoModalModule,
    AppLojaMenuModalModule,
    AppProdutoDetalhesModalModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    MatButtonModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MarkdownModule.forRoot(),
    MatSnackBarModule,
    MatToolbarModule,
    MeHtmlComponentsModule,
    ModalModule.forRoot(),
    NgxMaskDirective,
    NgxMaskPipe,
    NgxSkeletonLoaderModule.forRoot(),
    provideFirebaseApp(() => initializeApp(environment.firebase.config)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()),
    RouterModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !!environment?.production, // !isDevMode()
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    SweetAlert2Module.forRoot(),
  ],
  declarations: [
    AcessoNegadoPage,
    AppComponent,
    CarrinhoPendentePage,
    NotFoundPage,
  ],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: LOCALE_ID, useValue: "pt-BR" },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
