//#region ng
import {
  Component,
  inject,
  signal
} from '@angular/core';
import { Router } from '@angular/router';
//#endregion

//#region 3rd
import {
  combineLatest,
  Observable,
} from 'rxjs';
import { filter, map } from 'rxjs/operators';
//#endregion

//#region models
interface IVm {
  isAtTop: boolean;
  isMobile: boolean;
  carrinhoPendente: ICarrinhoPendenteEvent;
  pedido: IPedido;
  // loja: ILoja;
};
import { IPedido } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { ICarrinhoPendenteEvent } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_events';
//#endregion

//#region services
import { AppService } from '../../services';
import { PedidosService } from '../../_shared/_mercadeiro/_ng/_services';
//#endregion

//#region stores
import { AppCarrinhoStore } from '../../stores';
//#endregion

@Component({
  selector: 'app-carrinho-pendente',
  templateUrl: './carrinho-pendente.page.html',
  styleUrls: ['./carrinho-pendente.page.scss']
})
export class CarrinhoPendentePage {
  //#region publics
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region privates
  #vm: IVm;
  //#endregion

  //#region injects
  #appCarrinhoStore = inject(AppCarrinhoStore);
  // #appLojasStore = inject(AppLojasStore);
  // #lojasStore = inject(LojasStore);
  #router = inject(Router);
  #pedidosServ = inject(PedidosService);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.vm$.set(
      combineLatest([
        AppService.isMobile$,
        AppService.isAtTop$,
        // this.#lojasStore.lojaStateChanged$
        //   .pipe(filter((loja: ILoja) => !!loja)),
        // this.#appCarrinhoStore.carrinhoStateChanged$,
        this.#appCarrinhoStore.carrinhoItensStateChanged$,
        this.#appCarrinhoStore.carrinhoPendenteFound$,
        // .pipe(filter((loja: ILoja) => !!loja)),
      ])
        .pipe(
          map(([isMobile, isAtTop, carrinho, carrinhoPendente]) => {
            // console.log(carrinho);
            // console.log(carrinhoPendente);
            this.#vm = {
              isMobile,
              isAtTop,
              carrinhoPendente,
              pedido: this.#pedidosServ.fix({ carrinho })
              // loja
            };
            // console.log(this.#vm);
            return this.#vm;
          }),
        )
    );
  }
  //#endregion

  //#region methods
  onGotoHomeClick() {
    AppService.goHome$.emit();
  }

  onAbandonarCarrinhoClick() {
    // console.log(this.#vm?.carrinhoPendente?.to?.idLoja);
    this.#appCarrinhoStore.carrinhoRemove();
    this.#appCarrinhoStore.setState(null);
    this.#router.navigate(
      [
        '/loja',
        this.#vm?.carrinhoPendente?.to?.idLoja || '',
        !!this.#vm?.carrinhoPendente?.to?.isRetirada,
      ]
    );
  }
  //#endregion
}
