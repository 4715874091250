//#region ng
import {
  Component,
  ViewChild,
  effect,
  inject,
  signal
} from '@angular/core';
import { Router } from '@angular/router';
//#endregion

//#region firebase
import {
  doc,
  Firestore,
  onSnapshot,
  Unsubscribe as FbUnsubscribe,
} from '@angular/fire/firestore';
import { getApp } from 'firebase/app';
import {
  getFunctions,
  httpsCallable
} from 'firebase/functions';
//#endregion

//#region mat
import { MatStepper } from '@angular/material/stepper';
import { MatDialog } from '@angular/material/dialog';
//#endregion

//#region 3rd
// import moment from 'moment';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest,
  from
} from 'rxjs';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { set } from 'lodash';
import {
  distinctUntilChanged,
  filter,
  finalize,
  first,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
//#endregion

//#region models
interface IVm {
  carrinho: ICarrinhoMap;
  changes: Partial<IPedido>;
  // pedido: IPedido;
  conta: IConta;
  cpfCnpj: string;
  endereco: ICorViacepApi;
  // hasDescTaxaEntrega: boolean;
  isAtTop: boolean;
  isMobile: boolean;
  isRetirada: boolean;
  loja: ILoja;
  descTaxaEntrega: {
    pendente?: boolean;
    status: boolean;
    perc: number;
    minTotalCarrinho: number;
  };
  taxaEntrega: {
    _liquido?: number;
    desc: number;
    val: number;
  };
};
enum ESteps {
  Contato,
  Retirada,
  Horario,
  Pagamento,
  Confirmacao
};
import { environment } from 'src/environments/environment';
import {
  IConta,
  ILoja,
  IPedido,
  ISetorEntrega
} from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import {
  ICarrinhoMap,
  IItemCarrinhoMap
} from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_maps';
import { ICorViacepApi } from '../../_shared/_core/_misc/_models/_interfaces/_apis';
import {
  DIAS_SEMANA_NOME_LONGO,
  MESES_NOME_LONGO
} from '../../_shared/_core/_misc/_models/consts';
import { NOMES_TIPOS_FORMAS_PGTO } from '../../_shared/_mercadeiro/_misc/_models/consts';
import { TNullable } from '../../_shared/_core/_misc/_models/_types';
//#endregion

//#region libs
import {
  // fixPedido,
  calcLojaIdInfo,
  mapCarrinhoInfo,
  // fixCarrinho,
} from '../../_shared/_mercadeiro/_misc/_libs';
import { min2time } from '../../_shared/_libs/_misc/_convert';
import {
  endereco2Str,
  guid
} from '../../_shared/_libs/_misc/_strings';
import {
  formataCurrency,
  formataTelefone
} from '../../_shared/_libs/_misc/_formats';
import { onDestroy } from '../../_shared/_core/_ng/_libs';
import { nextId } from '../../_shared/_libs/_misc/_numbers';
//#endregion

//#region services
import {
  CarrinhoService,
  ContasService,
  LojasService,
  PedidosService,
  SetoresEntregaService
} from '../../_shared/_mercadeiro/_ng/_services';
import { AppService } from '../../services';
import { CorLoaderService } from '../../_shared/_core/_ng/_services';
//#endregion

//#region stores
import {
  ContasStore,
  LojasStore
} from '../../_shared/_mercadeiro/_ng/_stores';
import {
  AppCarrinhoStore,
  AppEnderecosStore,
} from '../../stores';
//#endregion

//#region components
import {
  AppPedidoContatoFormComponent,
  AppPedidoHorarioComponent,
  AppPedidoPagamentoComponent,
  AppPedidoResumoComponent,
  AppPedidoRetiradaEntregaComponent
} from '.';
import { CorMatAlertDialog } from '../../_shared/_core/_ng-ui/_mat';
//#endregion

@Component({
  selector: 'app-pedido',
  templateUrl: './pedido.page.html',
  styleUrls: ['./pedido.page.scss']
})
export class PedidoPage {
  //#region actions
  #carrinhoAction$ = new Subject<ICarrinhoMap>();
  // #changesAction$ = new Subject<Partial<IPedido>>();
  #destroyAction$: Subject<void> = onDestroy();
  #isRetiradaAction$ = new BehaviorSubject<boolean>(false);
  #lojaAction$ = new BehaviorSubject<TNullable<ILoja>>(null);
  #stepChangedAction$ = new BehaviorSubject<number>(null);
  //#endregion

  //#region viewchilds
  // loaderSwalRef
  @ViewChild('loaderSwal')
  public readonly loaderSwalRef: SwalComponent;
  // concluirLoaderSwalRef
  @ViewChild('concluirLoaderSwal')
  public readonly concluirLoaderSwalRef: SwalComponent;
  // stepperRef
  @ViewChild('stepper')
  public readonly stepperRef: MatStepper;
  // contatoFormRef
  @ViewChild(AppPedidoContatoFormComponent) contatoFormRef: AppPedidoContatoFormComponent;
  // retiradaEntregaFormRef
  @ViewChild(AppPedidoRetiradaEntregaComponent) retiradaEntregaFormRef: AppPedidoRetiradaEntregaComponent;
  // formaPgtoFormRef
  @ViewChild(AppPedidoHorarioComponent) horarioFormRef: AppPedidoHorarioComponent;
  // retiradaEntregaFormRef
  @ViewChild(AppPedidoPagamentoComponent) formaPgtoFormRef: AppPedidoPagamentoComponent;
  // resumoFormRef
  @ViewChild(AppPedidoResumoComponent) resumoFormRef: AppPedidoResumoComponent;
  //#endregion

  //#region publics
  isPedidoOk = signal<boolean>(false);
  lojaPath = signal<string>(null);
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region privates
  #pedidoUnsub: FbUnsubscribe;
  #setoresEntrega: ISetorEntrega[];
  #vm: IVm;
  //#endregion

  //#region injects
  #appCarrinhoStore = inject(AppCarrinhoStore);
  #appEnderecosStore = inject(AppEnderecosStore);
  // #appLojasStore = inject(AppLojasStore);
  #carrinhoServ = inject(CarrinhoService);
  #contasServ = inject(ContasService);
  #contasStore = inject(ContasStore);
  #db = inject(Firestore);
  #loaderServ = inject(CorLoaderService);
  #lojasServ = inject(LojasService);
  #lojasStore = inject(LojasStore);
  #modal = inject(MatDialog);
  #pedidosServ = inject(PedidosService);
  #router = inject(Router);
  #setoresEntregaServ = inject(SetoresEntregaService);
  //#endregion

  //#region constructor
  constructor() {
    effect(
      () => {
        this.#setoresEntregaServ.docs(this.lojaPath() || '')
          .subscribe(
            (setoresEntrega: ISetorEntrega[]) => {
              this.#setoresEntrega = this.#setoresEntregaServ.fixes(setoresEntrega);
            }
          )
      }
    );
  }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.#appCarrinhoStore.carrinhoLojaStateChanged$
      .pipe(
        filter(data => !!data),
        // debounceTime(500),
        distinctUntilChanged(),
        // tap(data => console.log(data?.__loja)),
        takeUntil(this.#destroyAction$),
      )
      .subscribe(
        async (carrinho: ICarrinhoMap) => {
          // console.log(carrinho);
          // this.loja.set(!!loja ? this.#lojasServ.fix(loja) : null);
          this.#isRetiradaAction$.next(!!carrinho?.__loja?.isRetirada);
          this.lojaPath.set(calcLojaIdInfo(carrinho?.__loja?.id || '')?.lojaPath || '');
          // console.log(this.lojaPath());
          if (!!carrinho && !!this.lojaPath()) {
            const functions = getFunctions(getApp(), environment?.firebase?.region);
            const fn = httpsCallable(functions, 'onCorrigeCarrinho');
            const RET: any = await fn({
              data: {
                carrinho: this.#carrinhoServ.fix(carrinho),
                lojaPath: this.lojaPath()
              }
            });
            // console.log(JSON.stringify(RET.data));
            this.#carrinhoAction$.next(carrinho);
            this.loaderSwalRef?.close();
            const CARRINHO: ICarrinhoMap = !!RET?.data ? this.#carrinhoServ.fix(RET?.data) : null;
            this.#carrinhoAction$.next(CARRINHO);
            set(this.stepperRef, 'selectedIndex', 0);
          } // if
        }
      );

    /* combineLatest([
      this.#carrinhoAction$,
      this.#lojasStore.lojaStateChanged$,
      this.#isRetiradaAction$,
      this.#stepChangedAction$,
    ])
      .pipe(takeUntil(this.#destroyAction$))
      .subscribe(
        ([carrinho, loja, isRetirada, refresh]) => {
          // console.log(carrinho, loja, isRetirada);
          const CHANGES: Partial<IPedido> = this.#pedidosServ.fix(this.#calcChanges(carrinho, loja, isRetirada));
          // console.log(CHANGES);
          this.#changesAction$.next(CHANGES);
        }
      ); */

    this.#lojasStore.lojaStateChanged$
      .pipe(
        // tap((loja: ILoja) => console.log(loja)),
        filter((loja: ILoja) => !!loja),
        switchMap((loja: ILoja) => from(this.#lojasServ.lfix(loja))),
        tap((lojas: [TNullable<ILoja>, TNullable<ILoja>]) => {
          // console.log(lojas);
          const [L1, L2] = lojas;
          delete L1.__img;
          delete L2.rede;
          const LOJA: ILoja = { ...L1, ...L2 };
          // console.log(LOJA);
          this.#lojaAction$.next(LOJA);
        }),
        takeUntil(this.#destroyAction$),
      )
      .subscribe();

    this.vm$.set(
      combineLatest([
        AppService.isMobile$,
        AppService.isAtTop$,
        this.#appEnderecosStore.enderecoStateChanged$,
        this.#contasStore.contaStateChanged$,
        this.#carrinhoAction$,
        this.#lojaAction$,
        this.#isRetiradaAction$,
        // this.#changesAction$,
        this.#stepChangedAction$,
      ])
        .pipe(
          filter(([isMobile, isAtTop, endereco, conta, carrinho, loja, isRetirada, stepChanged]) => !!carrinho && !!conta && !!loja),
          map(([isMobile, isAtTop, endereco, conta, carrinho, loja, isRetirada, changes]) => {
            // console.log(isRetirada, endereco);
            const BAIRRO: string = endereco?.bairro || '';
            let taxaEntrega = {
              // _liquido: 0,
              desc: 0,
              val: 0,
            };
            let descTaxaEntrega: any = {
              pendente: false,
              status: false,
              perc: 0,
              minTotalCarrinho: 0,
            };

            if (!!BAIRRO && !isRetirada) {
              const TG_STATUS: boolean = !!loja?.entrega?.taxaGeral?.status;
              const TG_DESC_STATUS: boolean = !!TG_STATUS && !!loja?.entrega?.taxaGeral?.desc?.status;
              const TAXA_GERAL = {
                val: !!TG_STATUS ? Number(loja?.entrega?.taxaGeral?.valor) || 0 : 0,
                desc: {
                  minCarrinho: !!TG_DESC_STATUS ? Number(loja?.entrega?.taxaGeral?.desc?.minTotalCarrinho) || 0 : 0,
                  perc: !!TG_DESC_STATUS ? Number(loja?.entrega?.taxaGeral?.desc?.perc) || 0 : 0,
                  status: TG_DESC_STATUS
                }
              };
              // console.log(TAXA_GERAL);
              // console.log(BAIRRO, this.#setoresEntrega);
              let setorEntrega: TNullable<ISetorEntrega> = this.#setoresEntrega?.find((SE: ISetorEntrega) => SE?.bairros?.includes(BAIRRO)) || null;
              // console.log(setorEntrega);
              const SETOR_ENTREGA_DESC_STATUS: boolean = !!setorEntrega?.taxaEntrega?.desc?.status;
              if (!!setorEntrega && !SETOR_ENTREGA_DESC_STATUS) {
                setorEntrega.taxaEntrega.desc.perc = 0;
                setorEntrega.taxaEntrega.desc.minTotalCarrinho = 0;
              } // if
              // console.log(setorEntrega);

              descTaxaEntrega = {
                status: SETOR_ENTREGA_DESC_STATUS ?? TG_DESC_STATUS,
                perc: !!setorEntrega
                  ? setorEntrega?.taxaEntrega?.desc?.perc || 0
                  : TAXA_GERAL?.desc?.perc || 0,
                minTotalCarrinho: !!setorEntrega
                  ? setorEntrega?.taxaEntrega?.desc?.minTotalCarrinho || 0
                  : TAXA_GERAL?.desc?.minCarrinho || 0,
              };

              const CARRINHO_TOT_PROD: number = carrinho?.__solicitado?.total?.prod || 0;
              // console.log(CARRINHO_TOT_PROD);
              const TAXA_ENTREGA_VAL: number = !!setorEntrega
                ? setorEntrega?.taxaEntrega?.val || 0
                : TAXA_GERAL?.val || 0;
              taxaEntrega.val = TAXA_ENTREGA_VAL || 0;

              descTaxaEntrega.pendente = CARRINHO_TOT_PROD < descTaxaEntrega?.minTotalCarrinho;
              // console.log(CARRINHO_TOT_PROD, TE_DESC_ATINGIDO);
              // taxaEntrega.val = TAXA_ENTREGA_VAL;
              if (!descTaxaEntrega.pendente) {
                // console.log(TAXA_ENTREGA_VAL, descTaxaEntrega?.perc);
                taxaEntrega.desc = !!TAXA_ENTREGA_VAL && !!descTaxaEntrega?.perc
                  ? TAXA_ENTREGA_VAL * descTaxaEntrega?.perc / 100
                  : 0;
              } // if

            } // if
            // taxaEntrega._liquido = taxaEntrega?.val - taxaEntrega?.desc?._liquido;
            // console.log(taxaEntrega);
            const CARRINHO: ICarrinhoMap = this.#carrinhoServ.fix(
              {
                ...carrinho,
                __loja: {
                  ...carrinho?.__loja,
                  taxaEntrega: {
                    desc: taxaEntrega?.desc,
                    val: taxaEntrega?.val,
                    _liquido: taxaEntrega?.val - taxaEntrega?.desc,
                  }
                }
              }
            );
            // console.log(CARRINHO);

            // const CHANGES: Partial<IPedido> = this.#pedidosServ.fix(this.#calcChanges(carrinho, loja, isRetirada));
            this.#vm = {
              carrinho: this.#carrinhoServ.fix(CARRINHO),
              changes: this.#pedidosServ.fix(this.#calcChanges(CARRINHO, loja, isRetirada)),
              cpfCnpj: conta?.cpf || conta?.cnpj || '',
              conta,
              descTaxaEntrega,
              isMobile,
              isAtTop,
              endereco,
              // hasDescTaxaEntrega: !!endereco &&
              //   !isRetirada,
              loja,
              isRetirada,
              taxaEntrega,
            };
            // console.log(this.#vm);
            // console.log(JSON.stringify(this.#vm?.changes?._info));
            return this.#vm;
          }),
        )
    );
  }

  ngAfterViewInit() {
    this.#loaderServ.lstop();
    this.loaderSwalRef?.fire();
    // if (this.stepperRef) this.stepperRef.selectedIndex = 0;
  }

  ngOnDestroy() {
    !!this.#pedidoUnsub && this.#pedidoUnsub();
  }
  //#endregion

  //#region functions
  #calcChanges(
    carrinho: ICarrinhoMap = null,
    loja: ILoja = null,
    isRetirada: boolean
  ): Partial<IPedido> {
    // const CARRINHO: ICarrinhoMap = carrinho || this.#vm?.carrinho;
    // const LOJA: ILoja = loja || this.#vm?.loja;
    // const IS_RETIRADA: boolean = isRetirada ?? this.#vm?.isRetirada;
    // console.log(CARRINHO, !!LOJA, this.#vm?.isRetirada);
    if (!!carrinho && !!loja && (isRetirada !== null)) {
      const CARRINHO: ICarrinhoMap = this.#carrinhoServ.fix(carrinho);
      // console.log(CARRINHO);
      // console.log(CARRINHO?.__solicitado?.__total);      
      return {
        _info: {
          contato: {
            legenda: 'Contato',
            val: `Cel: ${this.contatoFormRef?.nomeRef?.value || ''} - ${formataTelefone(this.contatoFormRef?.foneCelRef?.value || '')}${!!this.contatoFormRef?.foneFixoRef?.value ? ' - Fixo: ' + formataTelefone(this.contatoFormRef?.foneFixoRef?.value) : ''}`,
          },
          retiradaEntrega: {
            legenda: !!isRetirada ? 'Retirada na loja' : 'Entrega no endereço',
            val: !!isRetirada
              ? endereco2Str(loja?.endereco)
              : endereco2Str(this.retiradaEntregaFormRef?.retiradaEntregaForm()?.get('endereco')?.value),
          },
          horario: {
            legenda: 'Horário',
            val: !!this.horarioFormRef?.isRetirada
              ? `Tempo estimado de separação: ${this.horarioFormRef?.tempoEstimadoSeparacaoRetirada()?.val} minutos.`
              : !!this.horarioFormRef?.semHorario()
                ? 'Disponibilidade do próximo entregador.' // 'O próximo entregador disponível entregará seu pedido em breve.'
                : `${DIAS_SEMANA_NOME_LONGO[this.horarioFormRef?.diaSel()?.__dow]} - ${this.horarioFormRef?.diaSel()?.__dia} / ${MESES_NOME_LONGO[this.horarioFormRef?.diaSel()?.__mes]} - Início: ${min2time(this.horarioFormRef?.horarioForm()?.get('horario.hora')?.value?.inicio)} - Final: ${min2time(this.horarioFormRef?.horarioForm()?.get('horario.hora')?.value?.final)}`,
          },
          formaPgto: {
            legenda: 'Pagamento',
            val: `${!!this.formaPgtoFormRef?.possuiBandeirasRef?.value ? `Tipo: ${NOMES_TIPOS_FORMAS_PGTO[this.formaPgtoFormRef?.tipoRef?.value]} - ` : ''}Forma: ${this.formaPgtoFormRef?.nomeRef?.value} ${!!this.formaPgtoFormRef?.trocoParaRef?.value ? formataCurrency(this.formaPgtoFormRef?.trocoParaRef?.value) : ''}`,
          },
        },
        _changes: {
          aguardandoSeparacao: {
            // _timestamp: TIME_STAMP,
            _operador: {
              id: this.#vm?.conta?.id || '',
              nome: this.#vm?.conta?.__nomeCompleto || '',
            },
          },
        },
        carrinho: CARRINHO,
        ...this.contatoFormRef?.pedidoContatoForm()?.value,
        ...this.retiradaEntregaFormRef?.retiradaEntregaForm()?.value,
        ...this.horarioFormRef?.horarioForm()?.value,
        ...this.formaPgtoFormRef?.formaPgtoForm()?.value,
        ...this.resumoFormRef?.resumoForm()?.value,
        conta: this.#contasServ.unfix(this.#vm?.conta),
        // __taxaEntrega: this.#vm?.taxaEntrega,
        // __total: CARRINHO?.__solicitado?.total,
      };
    } else {
      return null
    } // else
    // _status: 'aguardandoSeparacao',
  }
  //#endregion

  //#region methods
  onSwalLoaderDidOpen() {
    Swal.showLoading();
    Swal.disableButtons();
  }

  onGoHomeClick() {
    AppService.goHome$.emit();
  }

  onStepChanged(e: any) {
    // console.log(e);
    const POS: number = +e?.selectedIndex;
    // console.log(POS);
    this.isPedidoOk.set(false);
    this.#stepChangedAction$.next(POS);
    switch (POS) {
      case ESteps.Contato:
        this.contatoFormRef?.focus();
        break;

      case ESteps.Retirada:
        if (!!this.retiradaEntregaFormRef?.tabsRef) {
          this.retiradaEntregaFormRef.tabsRef.selectedIndex = !!this.#vm?.isRetirada ? 1 : 0;
        } // if
        this.retiradaEntregaFormRef?.retiradaEntregaForm()?.get('retirada').setValue(!!this.#vm?.isRetirada);
        break;

      case ESteps.Horario:
        break;

      case ESteps.Pagamento:
        break;

      case ESteps.Confirmacao:
        this.isPedidoOk.set(true);
        break;
    } // switch
  }

  onVerificaContato() {
    if (!this.contatoFormRef?.pedidoContatoForm()?.valid) {
      // const DIALOG_REF: any =
      this.#modal.open(
        CorMatAlertDialog,
        {
          id: 'alert-contato',
          panelClass: 'no-border-dialog-container',
          data: {
            title: 'Informações de contato.',
            confirmCaption: 'Ok',
            msg: `Preencha todos campos obrigatórios para continuar.`,
          }
        }
      );
    } else {
      this.stepperRef?.next();
    } // else
  }

  onSetRetiradaClick(isRetirada: boolean) {
    this.#isRetiradaAction$?.next(isRetirada);
  }

  onVerificaRetiradaEntrega() {
    // console.log(this.retiradaEntregaFormRef?.percLimiteRef?.value);
    if (!this.retiradaEntregaFormRef?.retiradaEntregaForm()?.valid) {
      // const DIALOG_REF: any =
      this.#modal.open(
        CorMatAlertDialog,
        {
          id: 'alert-retirada-entrega',
          panelClass: 'no-border-dialog-container',
          data: {
            title: !!this.#vm?.isRetirada ? 'Retirada na loja.' : 'Entrega no endereço.',
            confirmCaption: 'Ok',
            msg: this.retiradaEntregaFormRef?.percLimiteRef?.value < 1
              ? 'Limite mínimo do pedido não atingido.'
              : !!this.#vm?.isRetirada
                ? 'Verifique o endereço da loja e confirme o aviso para continuar.'
                : 'Selecione ou cadastre um endereço ATENDIDO PELA LOJA para continuar.',
          }
        }
      );
    } else {
      this.stepperRef?.next();
    } // else
  }

  onVerificaHorario() {
    if (!this.horarioFormRef?.horarioForm()?.valid) {
      // const DIALOG_REF: any =
      this.#modal.open(
        CorMatAlertDialog,
        {
          id: 'alert-horario',
          panelClass: 'no-border-dialog-container',
          data: {
            title: 'Horário.',
            confirmCaption: 'Ok',
            msg: 'Selecione um horário para continuar.',
          }
        }
      );
    } else {
      this.stepperRef?.next();
    } // else
  }

  onVerificaPgto() {
    // console.log(this.formaPgtoFormRef?.formaPgtoForm?.value);
    // console.log(this.formaPgtoFormRef?.formaPgtoForm?.valid);
    // console.log(this.formaPgtoFormRef?.formaPgtoForm?.errors);
    if (!this.formaPgtoFormRef?.formaPgtoForm()?.valid) {
      // const DIALOG_REF: any =
      this.#modal.open(
        CorMatAlertDialog,
        {
          id: 'alert-pgto',
          panelClass: 'no-border-dialog-container',
          data: {
            title: 'Pagamento.',
            confirmCaption: 'Ok',
            msg: 'Selecione ou corrija a forma de pagamento para continuar.',
          }
        }
      );
    } else {
      this.stepperRef?.next();
    } // else
  }

  onGotoStep(step: number) {
    if (!!this.stepperRef) { this.stepperRef.selectedIndex = step; }
  }

  onSubmit() {
    const ID: string = `${guid(5, 10)}${guid(4, 16)}${guid(4, 16)}`;
    const CHANGES: Partial<IPedido> = this.#pedidosServ.unfix(this.#vm?.changes);
    // delete CHANGES?.confirmado;
    if (!!CHANGES && !!this.lojaPath()) {
      this.concluirLoaderSwalRef?.fire();
      try {
        set(
          CHANGES,
          'carrinho.__solicitado.itens',
          (CHANGES?.carrinho?.__solicitado?.itens || [])
            .map((i: IItemCarrinhoMap) => !!i?.produto?.id ? { ...i, id: i?.produto?.id } : null)
            .filter((i: IItemCarrinhoMap) => !!i)
        );
        // console.log(CHANGES);

        const SUB: Subscription = this.#pedidosServ.add(this.lojaPath(), CHANGES, ID)
          .pipe(first(), finalize(() => SUB?.unsubscribe()))
          .subscribe(
            () => {
              const PATH: string = `${this.lojaPath()}/pedidos/${ID}`;
              // console.log(PATH);
              !!this.#pedidoUnsub && this.#pedidoUnsub();
              this.#pedidoUnsub = onSnapshot(
                doc(this.#db, PATH),
                snap => {
                  this.concluirLoaderSwalRef?.close();
                  if (!!snap.id) {
                    const NEW_CARRINHO: ICarrinhoMap = this.#carrinhoServ.fix(
                      {
                        ...this.#vm?.carrinho,
                        id: nextId().toString(),
                        _separado: mapCarrinhoInfo(this.#carrinhoServ.fix(null), true),
                        __solicitado: mapCarrinhoInfo(this.#carrinhoServ.fix(null), false)
                      }
                    );
                    // console.log(NEW_CARRINHO);
                    this.#appCarrinhoStore.setState(NEW_CARRINHO);
                    // console.log(this.#appCarrinhoStore.getState());
                    // console.log(snap.id, snap.data());
                    // this.pedido = this.#pedidosServ.fix({ id: snap.id, ...snap.data() });
                    // !!this.#pedidoUnsub && this.#pedidoUnsub();
                    this.#router?.navigate(
                      ['suaConta/seusPedidos'],
                      { queryParams: { ped: ID } }
                    );
                  } // if
                }
              );
            },
          );
      } finally {
        this.concluirLoaderSwalRef?.close();
      } // try-finally
    } // if
  }
  //#endregion
}

