<ng-container *ngIf="vm$() | async as vm">
  <div class="page"
       [class.no-footer]="!isMobile()">

    <div class="page-header">
      <div *ngIf="!!isMobile(); else headerDesktop">
        <app-loja-m-header [showReturn]="true"
                           (returnClick$)="onGotoHome()" />
      </div>
      <ng-template #headerDesktop
                   class="desktop-lg">
        <app-loja-header [showSearchPreview]="true" />
      </ng-template>
    </div>

    <div class="page-content container"
         [class.lg]="isAtTop()">
      <div class="mt-2">
        <nav aria-label="breadcrumb"
             class="mt-2">
          <ol class="breadcrumb d-flex">
            <li class="breadcrumb-item my-auto">
              <a (click)="onGotoHome()"
                 class="pointer">
                <span class="icon-o-home"></span>
              </a>
            </li>

            <li class="breadcrumb-item my-auto">
              <a routerLink="/departamentos">
                Departamentos
              </a>
            </li>

            <li class="breadcrumb-item my-auto"
                [class.active]="vm?.deptoAtivo?.nivel === 1"
                *ngIf="!!vm?.deptosInfo?.d1?.id">
              <a routerLink="/departamentos"
                 [queryParams]="{ id: vm?.deptosInfo?.d1?.id }">
                {{ vm?.deptosInfo?.d1?.depto?.nome }}
              </a>
            </li>

            <li class="breadcrumb-item my-auto"
                [class.active]="vm?.deptoAtivo?.nivel === 2"
                *ngIf="!!vm?.deptosInfo?.d2?.id">
              <a routerLink="/departamentos"
                 [queryParams]="{ id: vm?.deptosInfo?.d2?.id }">
                {{ vm?.deptosInfo?.d2?.depto?.nome }}
              </a>
            </li>

            <li class="breadcrumb-item my-auto active"
                *ngIf="!!vm?.deptosInfo?.d3?.id">
              <a routerLink="/departamentos"
                 [queryParams]="{ id: vm?.deptosInfo?.d3?.id }">
                {{ vm?.deptosInfo?.d3?.depto?.nome }}
              </a>
            </li>

            <li class="ms-auto my-auto"
                *ngIf="!!produtos?.length">
              <app-produtos-ord-sel [ord]="ordAction$() | async"
                                    [isMobile]="isMobile()"
                                    [exceptions]="['r']"
                                    (change$)="produtos = []; ordAction$().next($event);" />
            </li>
          </ol>
        </nav>
      </div>

      <div *ngIf="!vm?.deptosInfo?.d1?.id && !vm?.deptosInfo?.d2?.id && !vm?.deptosInfo?.d3?.id; else listaProdutos"
           class="mb-3">
        <div *ngIf="!!vm?.deptos; else carregandoPedidos">
          <div class="w-cards departamentos">
            <me-html-departamento-card *ngFor="let d of vm?.deptos | departamentosLfixes | async; trackBy:tid_dep"
                                       class="departamento pointer"
                                       [departamento]="d"
                                       (click)="onGotoDepto(d.id)" />
          </div>

          <div class="alert alert-light text-center"
               role="alert"
               *ngIf="!vm?.deptos?.length">
            Nenhum departamento.
          </div>
        </div>
        <ng-template #carregandoPedidos>
          <me-html-departamentos-skel />
        </ng-template>
      </div>
      <ng-template #listaProdutos>
        <div *ngIf="!!produtos">
          <div class="d-flex mb-3"
               [class.flex-column]="!!isMobile()">
            <div [class.me-4]="!isMobile()"
                 [class.mb-2]="!!isMobile()"
                 *ngIf="!!vm?.deptoAtivo?._subs?.length">
              <ul class="list-group"
                  style="min-width: 200px">
                <li class="list-group-item pointer d-flex"
                    *ngFor="let s of vm?.deptoAtivo?._subs; trackBy:tid_dep"
                    [routerLink]="['/departamentos']"
                    [queryParams]="{ id: s?.id }">
                  <div class="my-auto">
                    {{ s?.nome }}
                  </div>

                  <div class="ms-auto my-auto">
                    <span class="icon-r-chevron-r o-50"></span>
                  </div>
                </li>
              </ul>
            </div>

            <div class="flex-1">
              <ul class="w-cards produtos">
                <li *ngFor="let p of produtos; trackBy:tid_pro"> <!--  | produtosLfixes | async -->
                  <app-produto-card [produto]="p"
                                    (imgClick$)="onProdutoClicked($event)" />
                </li>
              </ul>

              <div *ngIf="!!paginator?.nextEnabled; else listaCarregada">
                <div class="d-flex mb-3">
                  <button type="button"
                          class="btn btn-light m-auto round m-auto"
                          (click)="paginator.next()">
                    <span class="o-20 icon-r-chevron-d me-2"></span>Carregar mais produtos...
                  </button>
                </div>
              </div>
              <ng-template #listaCarregada>
                <div class="alert alert-light text-center m-auto"
                     role="alert">
                  Todas ocorrências exibidas.
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
    <app-loja-footer />
  </div>

  <div class="page-footer"
       *ngIf="!!isMobile()">
    <mat-toolbar color="primary"
                 (click)="onCarrinhoClick()">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-6 offset-sm-3 pt-3">
            <app-carrinho />
          </div>
        </div>
      </div>
    </mat-toolbar>
  </div>
</ng-container> 
