//#region ng
import {
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
  signal,
} from '@angular/core';
//#endregion

//#region 3rd
import {
  Subject,
  takeUntil
} from 'rxjs';
//#endregion

//#region models
import { ICorViacepApi } from '../../_shared/_core/_misc/_models/_interfaces/_apis';
//#endregion

//#region libs
import { onDestroy } from '../../_shared/_core/_ng/_libs';
//#endregion

//#region services
import { CorCepService } from '../../_shared/_core/_ng/_services';
//#endregion

//#region stores
import { AppEnderecosStore } from '../../stores';
//#endregion

@Component({
  selector: 'app-endereco-info',
  templateUrl: './endereco-info.component.html',
  styleUrls: ['./endereco-info.component.scss']
})
export class AppEnderecoInfoComponent {
  //#region actions
  #destroyAction$: Subject<void> = onDestroy();
  //#endregion

  //#region inputs
  @Input() showChange: boolean = true;
  // @Input() multiline: boolean = false;
  @Input() canChange: boolean = true;
  //#endregion

  //#region outputs
  @Output() change$ = new EventEmitter<void>();
  //#endregion

  //#region publics
  endereco = signal<ICorViacepApi>(null);
  //#endregion

  //#region injects
  #appEnderecosStore = inject(AppEnderecosStore);
  #cepServ = inject(CorCepService);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.#appEnderecosStore.enderecoStateChanged$
      .pipe(takeUntil(this.#destroyAction$))
      .subscribe((endereco: ICorViacepApi) => this.endereco.set(!!endereco ? this.#cepServ.fix(endereco) : null));
  }
  //#endregion
}
