//#region models
import { ISuaContaRota } from "./interfaces/misc";
//#endregion

//#region misc
export const HOME_ROUTE: string = '/lojas';
export const ID_MODULO: string = 'mer_portal_www';
export const SCROLL_TOP_OFFSET = 150;
export const THEME: string = 'info';
export const PRODUTOS_POR_PAG: number = 30;
export const PEDIDOS_POR_PAG: number = 30;
//#endregion

//#region storage
export const KEY_ENDERECO_SEL: string = `ENDERECO_SEL_${ID_MODULO}`.toLowerCase();
export const KEY_BANNERS: string = `BANNERS_${ID_MODULO}`.toLowerCase();
export const KEY_CARRINHO: string = `CARRINHO_${ID_MODULO}`.toLowerCase();
export const KEY_LOJA_INFO: string = `LOJA_INFO_${ID_MODULO}`.toLowerCase();
export const KEY_ORDEM_PRODUTOS: string = `ORDEM_PRODUTOS_${ID_MODULO}`.toLowerCase();
//#endregion

//#region carrinho
export const PROGRESSBAR_BG_COLOR = '#92B558';
//#endregion

//#region sua-conta
export const SUA_CONTA_ROTAS: ISuaContaRota[] = [
  {
    id: 'seusEnderecos',
    caption: 'Seus endereços',
    icon: 'icon-ob-pin',
    url: '/suaConta/seusEnderecos'
  },
  {
    id: 'seusPedidos',
    caption: 'Seus pedidos',
    icon: 'icon-o-copy',
    url: '/suaConta/seusPedidos'
  },
  {
    id: 'seusDados',
    caption: 'Dados cadastrais',
    icon: 'icon-r-person',
    url: '/suaConta/seusDados'
  },
  {
    id: 'seusContatos',
    caption: 'Informações de contato',
    icon: 'icon-o-chat-full',
    url: '/suaConta/seusContatos'
  },
  {
    id: 'mudarSenha',
    caption: 'Mudar senha',
    icon: 'icon-b-key',
    url: '/suaConta/mudarSenha'
  }
];
//#endregion
