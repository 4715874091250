//#region ng
import {
  Component,
  EventEmitter,
  Input,
  Output,
  effect,
  inject,
  signal,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
//#endregion

//#region 3rd
// import * as moment, { Moment } from 'moment';
import * as moment from 'moment';
//#endregion

//#region models
import { ICorFbUserApi } from '../../_shared/_core/_misc/_models/_interfaces/_apis';
import { CorFormValidation } from '../../_shared/_core/_ng/_models/_classes';
import { IConta } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { FOCUS_TIMEOUT } from '../../_shared/_core/_misc/_models/consts';
//#endregion

//#region custom validators
class CustomValidator {
  static cpf() {
    return (control: FormControl): ValidationErrors => {
      const CPF: string = (control.value || '');
      // console.log(CPF);
      if (!!CPF) {
        // console.log(isCpf(CPF));
        return isCpf(CPF) ? null : { cpf: true };
      } else {
        return null;
      } // else
    }
  }

  static cnpj() {
    return (control: FormControl): ValidationErrors => {
      const CNPJ = (control.value || '');
      // console.log(CNPJ);
      if (!!CNPJ) {
        // console.log(isCnpj(CNPJ));
        return isCnpj(CNPJ) ? null : { cnpj: true };
      } else {
        return null;
      } // else
    }
  }
  static nascimento() {
    return (control: FormControl): ValidationErrors => {
      const NASC: moment.Moment = (control.value || '');
      if (!!NASC) {
        const IDADE: number = moment()?.diff(NASC, 'years');
        // console.log(IDADE);
        return IDADE >= 18 ? null : { idade: true };
      } // if
      return null;
    }
  }
}
//#endregion

//#region libs
import {
  isCnpj,
  isCpf
} from '../../_shared/_libs/_misc/_validations';
//#endregion

//#region services
import { ContasService } from '../../_shared/_mercadeiro/_ng/_services';
import { CorFbUserService } from '../../_shared/_core/_ng/_services';
//#endregion

@Component({
  selector: 'app-conta-dados-pessoais-form',
  templateUrl: './conta-dados-pessoais-form.component.html',
  styleUrls: ['./conta-dados-pessoais-form.component.scss']
})
export class AppContaDadosPessoaisFormComponent {
  //#region inputs
  // conta
  #conta: IConta;
  get conta(): IConta { return this.#conta; }
  @Input({ required: true }) set conta(val: IConta) {
    // console.log(val);
    this.novo.set(!val?.id);
    this.#conta = val;
    this.pessoaFisica.set(!this.conta?.cnpj);
    // this.#geraForm(); 
    this.dadosPessoaisForm.set(
      this.#fb.group({
        // id: [get(this.user, 'uid')],
        nome: this.#fb.group({
          nome: [this?.conta?.nome?.nome, [Validators.required]],
          sobrenome: [this?.conta?.nome?.sobrenome],
        }),
        nascimento: [this?.conta?.nascimento, [Validators.required, CustomValidator.nascimento()]],
        sexo: [this?.conta?.sexo, [Validators.required]],
        cpf: [this?.conta?.cpf || '', [Validators.required, CustomValidator.cpf()]],
        cnpj: [this?.conta?.cnpj || '', [Validators.required, CustomValidator.cnpj()]],
        _user: [this?.user || null, [Validators.required]],
      })
    );
    setTimeout(() => this.focus(), FOCUS_TIMEOUT);
  };
  // isMobile
  @Input() isMobile: boolean = false;
  // user
  #user: ICorFbUserApi;
  get user(): ICorFbUserApi { return this.#user; }
  @Input() set user(val: ICorFbUserApi) {
    this.#user = !!val ? this.#userServ.fix(val) : null;
    this.isNomeFixo.set(!['password', 'phone'].includes(this.user?.type));
    this.conta = this.#contasServ.fix(this.conta, this.user);
  }
  // submitCaption
  @Input() submitCaption: string = 'CONFIRMAR';
  // showSubmit
  @Input() showSubmit: boolean = false;
  // submit$
  @Output() submit$ = new EventEmitter<Partial<IConta>>();
  //#endregion

  //#region publics
  dadosPessoaisForm = signal<FormGroup>(null);
  fv = signal<CorFormValidation>(null);
  isNomeFixo = signal<boolean>(null);
  novo = signal<boolean>(null);
  pessoaFisica = signal<boolean>(null);
  // vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region events
  cnpjFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>());
  nomeFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>());
  sexoFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>());
  //#endregion

  //#region injects
  #contasServ = inject(ContasService);
  #fb = inject(FormBuilder);
  #userServ = inject(CorFbUserService);
  //#endregion

  //#region constructor
  constructor() {
    this.fv.set(new CorFormValidation());
    effect(
      () => {
        const PESSOA_FISICA: boolean = this.pessoaFisica();
        // this.form.controls['name'].disable();
        if (!!PESSOA_FISICA) {
          this.cnpjRef?.disable();
          this.cpfRef?.enable();
          this.nascimentoRef?.enable();
          this.sexoRef?.enable();
          this.sobrenomeRef?.enable();
        } else {
          this.cnpjRef?.enable();
          this.cpfRef?.disable();
          this.nascimentoRef?.disable();
          this.sexoRef?.disable();
          this.sobrenomeRef?.disable();
        } // else
      }
    );
    effect(() => { this.pessoaFisica(); this.isNomeFixo(); this.focus() });
  }
  //#endregion

  //#region Controls getters
  get cnpjRef(): AbstractControl { return this.dadosPessoaisForm()?.get('cnpj'); }
  get cpfRef(): AbstractControl { return this.dadosPessoaisForm()?.get('cpf'); }
  get nascimentoRef(): AbstractControl { return this.dadosPessoaisForm()?.get('nascimento'); }
  get sexoRef(): AbstractControl { return this.dadosPessoaisForm()?.get('sexo'); }
  get nomeRef(): AbstractControl { return this.dadosPessoaisForm()?.get('nome.nome'); }
  get sobrenomeRef(): AbstractControl { return this.dadosPessoaisForm()?.get('nome.sobrenome'); }
  //#endregion

  //#region lifecycles

  ngAfterViewInit() {
    this.focus();
  }
  //#endregion

  //#region functions
  /* #geraForm() {
    // console.log(this.conta, this.user);
    this.dadosPessoaisForm.set(
      this.#fb.group({
        // id: [get(this.user, 'uid')],
        nome: this.#fb.group({
          nome: [this?.conta?.nome?.nome, [Validators.required]],
          sobrenome: [this?.conta?.nome?.sobrenome],
        }),
        nascimento: [this?.conta?.nascimento, [Validators.required]],
        sexo: [this?.conta?.sexo, [Validators.required]],
        cpf: [this?.conta?.cpf || '', [Validators.required, CustomValidator.cpf()]],
        cnpj: [this?.conta?.cnpj || '', [Validators.required, CustomValidator.cnpj()]],
        _user: [this?.user || null, [Validators.required]],
      })
    );
    setTimeout(() => this.focus(), FOCUS_TIMEOUT);
  } */

  focus() {
    // console.log('focus', /* this.isMobile, */ this.isNomeFixo(), this.pessoaFisica());
    !this.isNomeFixo()
      ? this.#nomeFocus()
      : !!this.pessoaFisica()
        ? this.#sexoFocus()
        : this.#cnpjFocus();
  }

  #nomeFocus(): void {
    // console.log('#nomeFocus', this.isMobile);
    !this.isMobile && setTimeout(
      () => this.nomeFocusEvent$().emit(true),
      FOCUS_TIMEOUT
    );
  }

  #sexoFocus(): void {
    // console.log('#sexoFocus', this.isMobile);
    !this.isMobile && setTimeout(
      () => this.sexoFocusEvent$().emit(true),
      FOCUS_TIMEOUT
    );
  }

  #cnpjFocus(): void {
    // console.log('#cnpjFocus', this.isMobile);
    !this.isMobile && setTimeout(
      () => this.cnpjFocusEvent$().emit(true),
      FOCUS_TIMEOUT
    );
  }
  //#endregion
}
