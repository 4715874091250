<div class="modal-body p-relative"
     *ngIf="!!user">
  <mat-toolbar class="header"
               color="primary">
    <mat-toolbar-row>
      <h3>
        Completando cadastro
        <small class="o-50">
          ( {{ user?.type === 'phone' ? user?.phoneNumber : user?.email }} )
        </small>
      </h3>

      <span class="flex-1"></span>

      <button mat-icon-button
              (click)="onModalCloseClick()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <cor-mat-messages />

  <div class="container-fluid">
    <mat-stepper [linear]="true"
                 labelPosition="bottom"
                 [orientation]="isMobile ? 'vertical' : 'horizontal'"
                 (selectionChange)="onStepChanged($event)"
                 #stepper>
      <mat-step state="dadosPessoais"
                label="Dados pessoais"
                [stepControl]="dadosPessoaisFormRef?.dadosPessoaisForm()">
        <div class="d-flex my-2">
          <div class="ms-auto">
            <button type="button"
                    mat-flat-button
                    class="pointer ms-2"
                    color="primary"
                    [disabled]="!dadosPessoaisFormRef?.dadosPessoaisForm()?.valid"
                    matStepperNext>
              <span>Próximo</span>
              <mat-icon class="o-50">chevron_right</mat-icon>
            </button>
          </div>
        </div>

        <app-conta-dados-pessoais-form [conta]="null"
                                       [user]="user"
                                       [isMobile]="isMobile" />
      </mat-step>

      <mat-step state="contato"
                label="Contato"
                [stepControl]="contatoFormRef?.contatoForm()">
        <div class="d-flex my-2">
          <div class="me-auto">
            <button type="button"
                    mat-flat-button
                    class="pointer"
                    color="light"
                    matStepperPrevious>
              <mat-icon class="o-50">chevron_left</mat-icon>
              <span> Anterior </span>
            </button>
          </div>

          <div class="ms-auto">
            <button type="button"
                    mat-flat-button
                    class="pointer ms-2"
                    color="primary"
                    [disabled]="!contatoFormRef?.contatoForm()?.valid"
                    matStepperNext>
              <span>Próximo </span>
              <mat-icon class="o-50">chevron_right</mat-icon>
            </button>
          </div>
        </div>

        <app-conta-contato-form [conta]="null"
                                [user]="user"
                                [isMobile]="isMobile" />
      </mat-step>

      <mat-step state="endereco"
                label="Endereço"
                class="mb-6"
                [stepControl]="manageEnderecoRef?.enderecoFormRef?.enderecoForm()">
        <div class="d-flex mt-2">
          <div class="me-auto">
            <button type="button"
                    mat-flat-button
                    class="pointer"
                    color="light"
                    matStepperPrevious>
              <mat-icon class="o-50">chevron_left</mat-icon>
              <span>Anterior </span>
            </button>
          </div>

          <div class="ms-auto">
            <button type="button"
                    mat-flat-button
                    class="pointer ms-2"
                    color="primary"
                    [disabled]="!manageEnderecoRef?.enderecoFormRef?.enderecoForm()?.valid"
                    matStepperNext>
              <span> Próximo </span>
              <mat-icon class="o-50">chevron_right</mat-icon>
            </button>
          </div>
        </div>

        <div class="mt-4 d-flex flex-column">
          <cor-mat-manage-endereco [endereco]="conta()?.endereco"
                                   [isMobile]="isMobile"
                                   [submitCaption]="" />
        </div>
      </mat-step>

      <mat-step state="resumo"
                label="Resumo">
        <div class="row mt-4">
          <div class="col-12 col-sm-6">
            <ul class="list-group"
                *ngIf="!!changes">
              <li class="list-group-item">
                <small class="o-50">Nome</small><br>
                <span>{{ changes()?.nome?.nome }}</span>
              </li>

              <div *ngIf="this.dadosPessoaisFormRef?.pessoaFisica; else pessoaJuridica">
                <li class="list-group-item">
                  <small class="o-50">Sobrenome</small><br>
                  <span>{{ changes()?.nome?.sobrenome || '-' }}</span>
                </li>

                <li class="list-group-item">
                  <small class="o-50">Sexo</small><br>
                  <span>{{ changes()?.sexo ? 'Masculino' : 'Feminino' }}</span>
                </li>

                <li class="list-group-item"
                    *ngIf="dataNasc">
                  <small class="o-50">Nascimento</small><br>
                  <span>{{ dataNasc() | date: 'dd/MM/yyyy' }}</span>
                </li>

                <li class="list-group-item">
                  <small class="o-50">CPF</small><br>
                  <span>{{ (changes()?.cpf | cpf) || '-' }}</span>
                </li>

              </div>
              <ng-template #pessoaJuridica>
                <li class="list-group-item">
                  <small class="o-50">CNPJ</small><br>
                  <span>{{ (changes()?.cnpj | cnpj) || '-' }}</span>
                </li>
              </ng-template>
            </ul>
          </div>

          <div class="col col-sm-6">
            <ul class="list-group"
                *ngIf="!!changes">
              <li class="list-group-item">
                <small class="o-50">E-mail</small><br>
                <span>{{ changes()?.email || '-'}}</span>
              </li>

              <li class="list-group-item">
                <small class="o-50">Fone celular</small><br>
                <span>{{ changes()?.fones?.celular | fone }}</span>
              </li>

              <li class="list-group-item">
                <small class="o-50">Fone fixo</small><br>
                <span>{{ (changes()?.fones?.fixo | fone) || '-' }}</span>
              </li>

              <li class="list-group-item">
                <small class="o-50">Fone mensagens</small><br>
                <span>{{ (changes()?.fones?.mensagens | fone) || '-' }}</span>
              </li>

              <li class="list-group-item">
                <small class="o-50">
                  <cor-bs-endereco [endereco]="changes()?.endereco"></cor-bs-endereco>
                </small>
              </li>
            </ul>
          </div>
        </div>

        <div class="d-flex mt-2">
          <div class="me-auto">
            <button type="button"
                    mat-flat-button
                    class="pointer"
                    color="light"
                    matStepperPrevious>
              <mat-icon class="o-50">chevron_left</mat-icon>
              <span> Anterior </span>
            </button>
          </div>

          <div class="ms-auto">
            <button type="button"
                    mat-flat-button
                    class="pointer ms-2"
                    color="primary"
                    (click)="onConfirmarClick()">
              <span> CONFIRMAR </span>
            </button>
          </div>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
</div>
