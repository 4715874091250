//#region ng
import {
  EventEmitter,
  Injectable
} from '@angular/core';
//#endregion

//#region 3rd
import { BehaviorSubject } from 'rxjs';
//#endregion

//#region models
// import { ILoginRet } from '../_shared/_cpre/_models/_interfaces/_rets';
import { IProduto } from '../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { IProdutoDetalheParams } from '../models/interfaces/params';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class AppService {
  //#region publics
  produtoLogin: IProduto;
  // static menuStatus: {
  //   loja: boolean;
  //   carrinho: boolean
  // } = {
  //     loja: false,
  //     carrinho: false
  //   };
  //#endregion

  //#region events
  static changeLoja$ = new EventEmitter<string>();
  static isAtTop$ = new BehaviorSubject<boolean>(true);
  static isMobile$ = new BehaviorSubject<boolean>(false);
  static identificacao$ = new EventEmitter<IProduto>();
  static goHome$ = new EventEmitter<void>();
  static goLojas$ = new EventEmitter<void>();
  static logout$ = new EventEmitter<string>();
  static menuLojaStatus$ = new BehaviorSubject<boolean>(false);
  static menuCarrinhoStatus$ = new BehaviorSubject<boolean>(false);
  static searchHitsChanged$ = new BehaviorSubject<IProduto[]>([]);
  static suaContaRotaIdChanged$ = new BehaviorSubject<string>('');
  static produtoDetalhes$ = new EventEmitter<IProdutoDetalheParams>();
  static urlChanged$ = new BehaviorSubject<string>('');
  static viewportWidthChanged$ = new BehaviorSubject<number>(0);
  //#endregion
}
