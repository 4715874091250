<ng-container *ngIf="vm$() | async as vm">
  <div class="page"
       [class.no-footer]="!vm?.isMobile">

    <div class="page-header">
      <div *ngIf="!!vm?.isMobile; else headerDesktop">
        <app-loja-m-header [showReturn]="true"
                           (returnClick$)="onGotoHomeClick()" />
      </div>
      <ng-template #headerDesktop
                   class="desktop-lg">
        <app-loja-header [showSearchPreview]="false" />
      </ng-template>
    </div>

    <div class="page-content d-flex flex-column"
         [class.lg]="vm?.isAtTop">
      <div class="m-auto text-center p-4">
        <img src="https://firebasestorage.googleapis.com/v0/b/mercadeiro-896b2.appspot.com/o/misc%2Fshopping-cart-with-cardboxes.png?alt=media"
             class="img-fluid">

        <div class="page-title warning tint fw-bold my-3">
          CARRINHO PENDENTE ENCONTRADO
        </div>

        <div class="mb-4">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div style="max-width: 600px;">
                    <app-carrinho-info />
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <me-html-pedido-itens [pedido]="vm?.pedido"
                                    [isMobile]="vm?.isMobile" />
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div class="d-flex flex-column">
          <button type="button"
                  class="btn btn-light btn-lg"
                  (click)="onGotoHomeClick()">
            MANTER o carrinho e CONTINUAR na loja <strong>{{ vm?.pedido?.carrinho?.__loja?.nome }}</strong>
          </button>

          <br>

          <button type="button"
                  class="btn btn-light btn-lg"
                  (click)="onAbandonarCarrinhoClick()">
            ABANDONAR o carrinho da loja <strong class="o-80">{{ vm?.pedido?.carrinho?.__loja?.nome }}</strong> e IR
            PARA a loja <strong>{{ vm?.carrinhoPendente?.to?.nomeLoja }}</strong>
          </button>
        </div>
      </div>
    </div>

    <app-loja-footer />
  </div>
</ng-container>
