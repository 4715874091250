//#region ng
import { Injectable, inject } from '@angular/core';
//#endregion

//#region 3rd
import {
  BehaviorSubject,
  Observable
} from "rxjs";
//#endregion

//#region models
import { ICorViacepApi } from '../_shared/_core/_misc/_models/_interfaces/_apis';
import { KEY_ENDERECO_SEL } from '../models/consts';
//#endregion

//#region services
import {
  CorCepService,
  CorStorageService
} from '../_shared/_core/_ng/_services';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class AppEnderecosStore {
  //#region enderecoState
  #enderecoState: ICorViacepApi | null;
  getState(): ICorViacepApi | null { return this.#enderecoState; }
  setState(val: ICorViacepApi | null) {
    // console.log(val);
    this.#enderecoState = !!val ? this.#cepServ.fix(val) : null;
    this.enderecoSelSet(this.#enderecoState);
    // console.log(this.#enderecoState);
    this.#enderecoStateSubject$.next(this.#enderecoState);
  };
  //#endregion

  //#region events
  #enderecoStateSubject$ = new BehaviorSubject<ICorViacepApi | null>(null);
  enderecoStateChanged$: Observable<ICorViacepApi | null> = this.#enderecoStateSubject$?.asObservable();
  //#endregion

  //#region injects
  #cepServ = inject(CorCepService);
  #storageServ = inject(CorStorageService);
  //#endregion

  //#region constructor
  constructor() {
    const ENDERECO: ICorViacepApi = this.enderecoSelGet();
    // console.log(ENDERECO);
    /* !!ENDERECO && */ this.setState(ENDERECO);
  }
  //#endregion

  //#region storage KEY_ENDERECO_SEL
  enderecoSelGet(): ICorViacepApi {
    const VAL: any = this.#storageServ.ls_get(KEY_ENDERECO_SEL);
    return typeof VAL === 'string' && VAL ? JSON.parse(VAL) : null;
  }

  enderecoSelSet(endereco: ICorViacepApi): ICorViacepApi {
    if (!!endereco) {
      this.#storageServ.ls_set(
        KEY_ENDERECO_SEL,
        JSON.stringify(endereco)
      );
      return this.enderecoSelGet();
    } else {
      return this.enderecoSelRemove();
    } // else
  }

  enderecoSelRemove(): ICorViacepApi {
    this.#storageServ.ls_remove(KEY_ENDERECO_SEL);
    return this.enderecoSelGet();
  }
  //#endregion
}
