<!-- <p>dadosPessoaisForm?.value {{ dadosPessoaisForm()?.value | json }}</p> -->
<!-- <p>dadosPessoaisForm?.valid {{ dadosPessoaisForm()?.valid }}</p> -->
<!-- <p>fv()?.error(nascimentoRef, 'nascimento') {{ fv()?.error(nascimentoRef, 'nascimento') }}</p> -->

<div class="row my-3"
     *ngIf="!!conta && !!user">
  <div class="col">
    <mat-radio-group [ngModel]="pessoaFisica()"
                     (ngModelChange)="pessoaFisica.set($event)"
                     [disabled]="!novo()">
      <mat-radio-button [value]="true"
                        class="me-4">
        Pessoa física
      </mat-radio-button>
      <mat-radio-button [value]="false">
        Pessoa jurídica
      </mat-radio-button>
    </mat-radio-group>
  </div>
</div>

<form novalidate
      (ngSubmit)="submit$?.emit(dadosPessoaisForm()?.value)"
      [formGroup]="dadosPessoaisForm()"
      *ngIf="!!conta && !!dadosPessoaisForm()">
  <div class="row"
       formGroupName="nome">
    <div class="col pb-2"
         [class.col-lg-6]="pessoaFisica()">
      <mat-form-field class="w-100"
                      [class.off]="!!isNomeFixo()"
                      [appearance]="!!isMobile ? 'fill' : 'outline'">
        <mat-label>
          {{ pessoaFisica() ? 'Primeiro nome' : 'Nome empresa' }}
        </mat-label>
        <input matInput
               type="text"
               formControlName="nome"
               [readonly]="!!isNomeFixo()"
               [focus]="nomeFocusEvent$()"
               onReturn>
        <mat-hint *ngIf="fv()?.error(nomeRef, 'nome')">
          <span class="danger">
            {{ fv()?.error(nomeRef, 'nome') }}
          </span>
        </mat-hint>
      </mat-form-field>
    </div>

    <div class="col-12 col-lg-6 pb-2"
         *ngIf="!!pessoaFisica()">
      <mat-form-field class="w-100"
                      [class.off]="!!isNomeFixo()"
                      [appearance]="!!isMobile ? 'fill' : 'outline'">
        <mat-label>Sobrenome</mat-label>
        <input matInput
               type="text"
               [readonly]="!!isNomeFixo()"
               formControlName="sobrenome"
               onReturn>
      </mat-form-field>
    </div>
  </div>

  <div class="row"
       *ngIf="pessoaFisica()">
    <div class="col-12 col-lg-6 pb-2">
      <mat-form-field class="w-100"
                      [appearance]="!!isMobile ? 'fill' : 'outline'">
        <mat-label>Sexo</mat-label>
        <mat-select formControlName="sexo"
                    [focus]="sexoFocusEvent$()"
                    [disabled]="!novo()">
          <mat-option value="">-- Informe --</mat-option>
          <mat-option value="M">Masculino</mat-option>
          <mat-option value="F">Feminino</mat-option>
        </mat-select>
        <mat-hint *ngIf="fv()?.error(sexoRef, 'sexo')">
          <span class="danger">
            {{ fv()?.error(sexoRef, 'sexo') }}
          </span>
        </mat-hint>
      </mat-form-field>
    </div>

    <div class="col-12 col-lg-6 pb-2">
      <mat-form-field class="w-100"
                      [class.off]="!novo()"
                      [appearance]="!!isMobile ? 'fill' : 'outline'">
        <mat-label>Data nascimento</mat-label>
        <input matInput
               formControlName="nascimento"
               [matDatepicker]="picker"
               [disabled]="!novo()"
               onReturn>
        <mat-datepicker-toggle matSuffix
                               [for]="picker">
        </mat-datepicker-toggle>
        <mat-datepicker #picker>
        </mat-datepicker>
        <!-- <mat-hint *ngIf="fv()?.error(nascimentoRef, 'nascimento')">
          <span class="danger">
            {{ fv()?.error(nascimentoRef, 'nascimento') }}
          </span>
        </mat-hint> -->
        <mat-hint *ngIf="fv()?.error(nascimentoRef) || nascimentoRef?.errors?.['idade']">
          <span class="danger">
            {{ fv()?.error(nascimentoRef) || 'Idade dever ser igual ou superior a 18 anos.' }}
          </span>
        </mat-hint>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-lg-6 pb-2">
      <mat-form-field class="w-100"
                      [class.off]="!!conta?.cpf"
                      [appearance]="!!isMobile ? 'fill' : 'outline'"
                      *ngIf="pessoaFisica(); else cnpj">
        <mat-label>CPF</mat-label>
        <input matInput
               type="text"
               mask="000.000.000-00"
               [showMaskTyped]="false"
               formControlName="cpf"
               [readonly]="!novo()"
               onReturn>
        <mat-hint *ngIf="fv()?.error(cpfRef, 'cpfCnpj') || cpfRef?.errors?.['cpf']">
          <span class="danger">
            {{ fv()?.error(cpfRef, 'cpfCnpj') || 'CPF inválido.' }}
          </span>
        </mat-hint>
      </mat-form-field>
      <ng-template #cnpj>
        <mat-form-field class="w-100"
                        [appearance]="!!isMobile ? 'fill' : 'outline'"
                        [class.off]="conta?.cnpj">
          <mat-label>CNPJ</mat-label>
          <input matInput
                 type="text"
                 formControlName="cnpj"
                 mask="00.000.000/0000-00"
                 [showMaskTyped]="false"
                 [readonly]="!novo()"
                 [focus]="cnpjFocusEvent$()"
                 onReturn>
          <mat-hint *ngIf="fv()?.error(cnpjRef, 'cpfCnpj') || cnpjRef?.errors?.['cnpj']">
            <span class="danger">
              {{ fv()?.error(cnpjRef, 'cpfCnpj') || 'CNPJ inválido.' }}
            </span>
          </mat-hint>
        </mat-form-field>
      </ng-template>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="d-flex mt-4"
           *ngIf="showSubmit">
        <button type="submit"
                class="ms-auto pointer"
                mat-raised-button
                color="primary"
                [disabled]="!dadosPessoaisForm()?.valid">
          {{ submitCaption }}
        </button>
      </div>
    </div>
  </div>
</form>