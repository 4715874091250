//#region ng
import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
//#endregion

//#region models
import { ICorPaginatorSort } from '../../_shared/_core/_misc/_models/_interfaces/_misc';
import { PRODUTOS_ORD } from '../../_shared/_mercadeiro/_misc/_models/consts';
//#endregion

@Component({
  selector: 'app-produtos-ord-sel',
  templateUrl: './produtos-ord-sel.component.html',
  styleUrls: ['./produtos-ord-sel.component.scss']
})
export class AppProdutosOrdSelComponent {
  //#region inputs
  // isMobile
  @Input() isMobile: boolean = false;
  // ord
  @Input({ required: true }) ord: ICorPaginatorSort;
  // exceptions
  @Input() exceptions: string[] = [];
  //#endregion

  //#region outputs
  @Output() change$ = new EventEmitter<ICorPaginatorSort>();
  //#endregion

  //#region methods
  onOrderClick(ordId: string = '') {
    // console.log(ordId);
    this.change$.emit(PRODUTOS_ORD.find((s: ICorPaginatorSort) => s?.id === ordId));
  }
  //#endregion
}
