//#region ng
import {
  Component,
  Input,
  effect,
  inject,
  signal
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
//#endregion

//#region 3rd
import { get } from 'lodash';
import * as moment from 'moment';
moment.locale('pt-br');
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest
} from 'rxjs';
import {
  map,
  takeUntil
} from 'rxjs/operators';
//#endregion

//#region models
interface ITempoEstimadoSeparacaoRetirada {
  caption: string;
  val: number;
};
import { ILoja } from '../../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import {
  DIAS_SEMANA_NOME_LONGO,
  MESES_NOME_CURTO
} from '../../../_shared/_core/_misc/_models/consts';
import {
  IDiaAgendamentoMap,
  IHorarioAgendamentoMap
} from '../../../_shared/_mercadeiro/_misc/_models/_interfaces/_maps';
//#endregion

//#region libs
import { guid } from '../../../_shared/_libs/_misc/_strings';
import { onDestroy } from '../../../_shared/_core/_ng/_libs';
//#endregion

//#region services
import { LojasService } from '../../../_shared/_mercadeiro/_ng/_services';
//#endregion

//#region stores
import { LojasStore } from '../../../_shared/_mercadeiro/_ng/_stores';
//#endregion

@Component({
  selector: 'app-pedido-horario',
  templateUrl: './pedido-horario.component.html',
  styleUrls: ['./pedido-horario.component.scss']
})
export class AppPedidoHorarioComponent {
  //#region actions
  #destroyAction$ = onDestroy();
  #isRetiradaAction$ = new BehaviorSubject<boolean>(null);
  //#endregion

  //#region inputs
  // isMobile
  @Input() isMobile: boolean = false;
  // isRetirada
  #isRetirada: boolean;
  get isRetirada(): boolean { return this.#isRetirada; }
  @Input() set isRetirada(val: boolean) {
    this.#isRetirada = val;
    this.#isRetiradaAction$.next(val);
  }
  //#endregion

  //#region publics
  dias = signal<IDiaAgendamentoMap[]>([]);
  diaSel = signal<IDiaAgendamentoMap>(null);
  horarioForm = signal<FormGroup>(null);
  // legendaRetiradaEntrega = signal<string>('');
  loja = signal<ILoja>(null);
  mesesNomeCurto = signal<string[]>(MESES_NOME_CURTO).asReadonly();
  semHorario = signal<boolean>(null);
  tempoEstimadoSeparacaoRetirada = signal<ITempoEstimadoSeparacaoRetirada>(null);
  // vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region idHoraSel
  #idHoraSel: string;
  get idHoraSel(): string { return this.#idHoraSel; }
  set idHoraSel(val: string) {
    this.#idHoraSel = val;
    if (!val) { this.horarioHoraRef?.setValue(null); }
  }
  //#endregion

  //#region injects
  #lojasServ = inject(LojasService);
  #lojasStore = inject(LojasStore);
  #fb = inject(FormBuilder);
  //#endregion

  //#region constructor
  constructor() {
    effect(() => {
      const DIA_SEL: IDiaAgendamentoMap = this.diaSel();
      this.idHoraSel = '';
    });
  }

  //#region controls getters
  get horarioDiaRef(): AbstractControl { return this.horarioForm()?.get('horario.dia'); }
  get horarioHoraRef(): AbstractControl { return this.horarioForm()?.get('horario.hora'); }
  get horarioRef(): AbstractControl { return this.horarioForm(); }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    combineLatest([
      this.#isRetiradaAction$,
      this.#lojasStore.lojaStateChanged$,
    ])
      .pipe(
        map(([isRetirada, loja,]) => {
          // console.log(isRetirada, loja);
          this.loja.set(!!loja ? this.#lojasServ.fix(loja) : null);
          let now: moment.Moment = moment();
          if (!!isRetirada) {
            // console.log(now);
            const DIA_SEMANA: number = now.day();
            // console.log(DIA_SEMANA);
            this.tempoEstimadoSeparacaoRetirada.set(
              {
                caption: '',
                val: 0
              }
            );
            const HORARIOS_HOJE: IHorarioAgendamentoMap[] = get(loja, `horarios.funcionamento.dow${DIA_SEMANA}`) || [];
            // console.log(HORARIOS_HOJE);
            if (
              !!HORARIOS_HOJE?.length
              // TODO: levar em conta horário atual para buscar próximo tempo estimado de separação.
            ) {
              const TEMPO_ESTIMADO_SEP_RET: number = HORARIOS_HOJE[0]?.tempoEstimadoSeparacaoMin || 0;
              // console.log(TEMPO_ESTIMADO_SEP_RET);
              this.tempoEstimadoSeparacaoRetirada.set(
                {
                  caption: `em aproximadamente ${TEMPO_ESTIMADO_SEP_RET} minutos, `,
                  val: TEMPO_ESTIMADO_SEP_RET
                }
              );
            } // if
          } else {
            this.dias.set([]);
            // console.log(this.retirada, this.loja);

            // https://metring.com.br/tutorial-momentjs
            const HORARIOS: any = get(loja, `horarios.${!!isRetirada ? 'funcionamento' : 'entrega'}`);
            const LIMITE_DIAS_AGENDAMENTO: number = get(loja, `pedidos.limiteDiasAgendamento.${!!isRetirada ? 'retirada' : 'entrega'}`);
            // console.log(HORARIOS, LIMITE_DIAS_AGENDAMENTO);

            // const NOW: any = moment();
            // const DIA_HOJE: number = NOW.format('D');
            // console.log('DIA_HOJE', DIA_HOJE);
            // const MES_HOJE: number = NOW.month(); // NOW.format('M');
            // console.log('MES_HOJE', MES_HOJE);
            // const ANO_HOJE: number = NOW.year(); // NOW.format('YYYY');
            // console.log('ANO_HOJE', ANO_HOJE);
            // const DIA_SEMANA_HOJE: number = NOW.day();
            // console.log('DIA_SEMANA_HOJE', DIA_SEMANA_HOJE);
            // console.log(NOW.format());
            const DIAS: IDiaAgendamentoMap[] = [];
            for (
              let i = 7; // LIMITE_DIAS_AGENDAMENTO;
              i > 0;
              i--
            ) {
              // console.log(i);
              const DISTANCIA_DIAS: number = now.diff(
                moment().startOf('day'),
                'days'
              );
              let legendaDia = '';
              switch (DISTANCIA_DIAS) {
                case 0:
                  legendaDia = 'Hoje';
                  break;

                case 1:
                  legendaDia = 'Amanhã';
                  break;

                default:
                  legendaDia = DIAS_SEMANA_NOME_LONGO[now.day()];
                  break;
              } // switch

              const DIA_SEMANA: number = now.day();
              const HORARIOS_DIA: IHorarioAgendamentoMap[] = get(HORARIOS, `dow${DIA_SEMANA}`) || [];
              // TODO: Remover horários vencidos.
              const H: IDiaAgendamentoMap = {
                id: guid(),
                __legenda: legendaDia,
                __dia: +now.format('D'),
                __dow: DIA_SEMANA,
                __mes: now.month(),
                __ano: now.year(),
                __distanciaDias: DISTANCIA_DIAS,
                __horarios: HORARIOS_DIA
              };
              !!HORARIOS_DIA.length && DIAS.push(H);
              // now.add('days', 1);
              now.add(1, 'days');
            } // for
            // console.log(DIAS);
            this.dias.set(DIAS.slice(0, LIMITE_DIAS_AGENDAMENTO));
            // console.log(this.dias());
            // console.log(DIAS, this.dias);
            // console.log(this.dias.length);
            this.diaSel.set(!!this.dias.length ? this.dias[0] : null);
          } // else

          // console.log(isRetirada, loja?.distribuidora?.tipoRota, loja?._qtde?.horariosEntrega);
          this.semHorario.set(
            !!isRetirada
            || loja?.distribuidora?.tipoRota === 'loc'
            || !(Number(loja?._qtde?.horariosEntrega) || 0)
          );
          this.horarioForm.set(
            this.#fb.group(
              !!this.semHorario()
                ? {}
                : {
                  horario: this.#fb.group({
                    dia: [null, [Validators.required]],
                    hora: [null, [Validators.required]],
                  })
                }
            )
          );
        }),
        takeUntil(this.#destroyAction$),
      )
      .subscribe();
  }
  //#endregion

  //#region functions
  tid_dia(index: any, item: IDiaAgendamentoMap): string { return item?.id || ''; }

  tid_hor(index: any, item: IHorarioAgendamentoMap): string { return item?.id || ''; }
  //#endregion

  //#region methods
  onHoraClick(h: IHorarioAgendamentoMap) {
    // console.log(h);
    if (!!h) {
      const DIA_SEL: IDiaAgendamentoMap = { ...this.diaSel() };
      delete DIA_SEL.__horarios;
      this.horarioForm().patchValue(
        {
          horario: {
            dia: DIA_SEL,
            hora: h
          }
        }
      );
    } // if
  }
  //#endregion
}
