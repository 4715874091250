//#region ng
import {
  Component,
  EventEmitter,
  Output,
  inject,
  signal
} from '@angular/core';
// import { Router } from '@angular/router';
//#endregion

//#region 3rd
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
//#endregion

//#region models
interface IVm {
  conta: IConta;
  rotas: any[];
};
import { IConta } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { SUA_CONTA_ROTAS } from '../../models/consts';
import { ICorRota } from '../../_shared/_core/_misc/_models/_interfaces/_misc';
//#endregiononEntrarClick

//#region stores
import { ContasStore } from '../../_shared/_mercadeiro/_ng/_stores';
//#endregion

//#region services
import { AppService } from '../../services';
//#endregion

@Component({
  selector: 'app-conta-actions',
  templateUrl: './conta-actions.component.html',
  styleUrls: ['./conta-actions.component.scss']
})
export class AppContaActionsComponent {
  //#region outputs
  @Output() logout$ = new EventEmitter<void>();
  //#endregion

  //#region publics
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region injects
  #contasStore = inject(ContasStore);
  //#endregion

  //#region lifecycles
  ngOnInit(): void {
    this.vm$.set(
      this.#contasStore.contaStateChanged$
        .pipe(
          map((conta: IConta) => {
            // console.log(banners);
            const VM: IVm = {
              conta,
              rotas: SUA_CONTA_ROTAS
            };
            // console.log(VM);
            return VM;
          }),
        )
    );
  }
  //#endregion

  //#region functions
  tid_rot(index: any, item: ICorRota): string { return item?.titulo || ''; }
  //#endregion

  //#region methods
  onEntrarClick() {
    AppService.identificacao$.emit();
  }
  //#endregion
}
