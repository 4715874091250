//#region ng
import {
  Component,
  Input,
  inject,
  signal,
} from '@angular/core';
//#endregion

//#region 3rd
import { BsModalService } from 'ngx-bootstrap/modal';
//#endregion

//#region models
import { ICorCompletaEnderecoRet } from '../../_shared/_core/_misc/_models/_interfaces/_rets';
import { ICorViacepApi } from '../../_shared/_core/_misc/_models/_interfaces/_apis';
//#endregion

//#region services
import { CorCepService } from '../../_shared/_core/_ng/_services';
//#endregion

@Component({
  selector: 'app-completa-endereco-modal',
  templateUrl: './completa-endereco.modal.html',
  styleUrls: ['./completa-endereco.modal.scss']
})
export class CompletaEnderecoModal {
  //#region inputs
  // isMobile
  @Input() isMobile: boolean = false;
  // idModal
  @Input() idModal: number;
  // endereco
  @Input({ required: true }) endereco: Partial<ICorViacepApi>;
  //#endregion

  //#region publics
  data = signal<ICorCompletaEnderecoRet>(null);
  //#endregion

  //#region injects
  #bsModalServ = inject(BsModalService);
  #cepServ = inject(CorCepService);
  //#endregion

  //#region methods
  onModalCloseClick(data: any = null) {
    // console.log(data);
    this.data.set(!!data && this.#cepServ.fix(data) || null);
    this.#bsModalServ.hide(this.idModal);
  }
  //#endregion

}
