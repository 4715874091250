<mat-accordion>
  <mat-expansion-panel *ngFor="let t of tiposFormas(); trackBy:tid_for"
                       (opened)="idPainelAberto.set(t?.id)">
    <mat-expansion-panel-header>
      <mat-panel-title class="d-flex">
        <img [src]="t?.__img"
             class="img-fluid me-4">

        <div class="my-auto">
          <span class="o-70 mt-2">{{ t?._nome }}</span>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div *ngIf="t?.__permiteTroco">
      <div class="text-center">
        <h4>
          Você deseja troco para qual valor?
        </h4>

        <p>
          <span class="o-50">Total a pagar </span> {{ changes?.carrinho?.__solicitado?.total?.liq| currency:
          "BRL":"symbol":"1.2-2" }}
        </p>
      </div>

      <form novalidate
            class="flex-1"
            [formGroup]="formaPgtoForm()"
            *ngIf="!!formaPgtoForm()">
        <div class="row"
             formGroupName="formaPgto">
          <div class="col-12 col-lg-8 offset-lg-2">
            <mat-form-field class="w-100"
                            [appearance]="!!isMobile ? 'fill' : 'outline'">
              <mat-label> Valor do pgto (R$)</mat-label>
              <input matInput
                     type="number"
                     class="text-end"
                     formControlName="__trocoPara"
                     [focus]="trocoParaFocusEvent$()"
                     onReturn>
              <mat-hint *ngIf="fv()?.error(trocoParaRef) || trocoParaRef?.errors?.['trocoPara']">
                <span class="danger">
                  {{ fv()?.error(trocoParaRef) || 'Deve ser maior do que ' + (changes?.carrinho?.__solicitado?.total?.liq | currency:
                  "BRL":"symbol":"1.2-2") }}
                </span>
              </mat-hint>
            </mat-form-field>
          </div>
        </div>
      </form>

      <button mat-flat-button
              color="primary"
              class="w-100 mt-2"
              [disabled]="!trocoParaRef?.valid"
              (click)="onFormaSelClick(t)">
        SELECIONAR
      </button>
    </div>

    <div *ngIf="!!t?.__possuiBandeiras">
      <h4 class="mb-2">
        Selecione a {{ !!t?.__distribuidora ? 'forma' : 'bandeira' }} desejada.
      </h4>
      <ul class="formas">
        <li *ngFor="let f of t?.__formas | formasPgtosLfixes | async; trackBy:tid_for"
            class="forma pointer"
            [class.active]="idRef?.value === f?.id"
            (click)="onFormaSelClick(f)">
          <div class="thumb d-flex">
            <div *ngIf="!t?.__distribuidora; else distribuidoraImg">
              <img [src]="f?.__img"
                   class="img-fluid m-auto">
            </div>
            <ng-template #distribuidoraImg>
              <img src="f?.__img"
                   class="img-fluid m-auto">
            </ng-template>
          </div>

          <p class="o-50 flex-1 mt-3">
            {{ f?._nome }}
          </p>
        </li>
      </ul>
    </div>

    <div *ngIf="!t?.__possuiBandeiras && !t?.__permiteTroco">
      <button mat-flat-button
              color="primary"
              class="w-100 mt-2"
              (click)="onFormaSelClick(t)">
        SELECIONAR
      </button>
    </div>
  </mat-expansion-panel>
</mat-accordion>