//#region ng
import {
  Component,
  Input,
  inject,
  signal,
} from "@angular/core";
//#endregion

//#region mat
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
//#endregion

//#region 3rd
import {
  Observable,
  Subscription,
  combineLatest
} from "rxjs";
import {
  filter,
  finalize,
  first,
  map
} from 'rxjs/operators';
// import Swal from "sweetalert2";
//#endregion

//#region models
interface IVm {
  carrinho: ICarrinhoMap;
  entregaRetiradaCaption: string;
  loja: ILoja;
  // lojaInfo: ILojaInfoState;
}
import {
  ICarrinhoMap,
  IItemCarrinhoMap
} from "../../_shared/_mercadeiro/_misc/_models/_interfaces/_maps";
import {
  ILoja,
  IProduto
} from "../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols";
//#endregion

//#region services
import { AppService } from "../../services";
import {
  CarrinhoService,
  ProdutosService
} from "../../_shared/_mercadeiro/_ng/_services";
//#endregion

//#region stores
import { AppCarrinhoStore } from "../../stores";
import {
  ContasStore,
  LojasStore
} from "../../_shared/_mercadeiro/_ng/_stores";
//#endregion

//#region components
import { CorMatConfirmDialog } from "../../_shared/_core/_ng-ui/_mat";
// import { AppCarrinhoComponent } from "../../components";
//#endregion

@Component({
  selector: "app-carrinho-menu-modal",
  templateUrl: "carrinho-menu.modal.html",
  styleUrls: ["carrinho-menu.modal.scss"]
})
export class AppCarrinhoMenuModal {
  //#region viewchilds
  // @ViewChild(AppCarrinhoComponent) carrinhoRef: AppCarrinhoComponent;
  //#endregion

  //#region inputs
  // idModal
  @Input() idModal: number;
  // isMobile
  @Input() isMobile: boolean = false;
  //#endregion

  //#region publics
  itemSel = signal<IItemCarrinhoMap>(null);
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region privates
  #vm: IVm;
  //#endregion

  //#region injects
  #carrinhoServ = inject(CarrinhoService);
  #appCarrinhoStore = inject(AppCarrinhoStore);
  // #appLojasStore = inject(AppLojasStore);
  #contasStore = inject(ContasStore);
  #lojasStore = inject(LojasStore);
  #modal = inject(MatDialog);
  #produtosServ = inject(ProdutosService);
  #snackBar = inject(MatSnackBar);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.vm$.set(
      combineLatest([
        // this.#appCarrinhoStore.carrinhoStateChanged$,
        this.#appCarrinhoStore.carrinhoItensStateChanged$,
        this.#lojasStore.lojaStateChanged$,
      ])
        .pipe(
          map(
            ([carrinho, loja]) => {
              // console.log(carrinho);

              /* if (!!carrinho) {
                // carrinho = this.#carrinhoServ.fix(carrinho);
                carrinho.__solicitado.itens = (carrinho?.__solicitado?.itens || [])
                  .map(
                    (i: IItemCarrinhoMap) => {
                      return {
                        ...i,
                        produto: !!i?.produto
                          ? fixProdutos(
                            [this.#produtosServ.fix(i?.produto || null)],
                            carrinho
                          )[0]
                          : null,
                      }
                    }
                  );

                (carrinho?.__solicitado?.itens || [])
                  .forEach((i: IItemCarrinhoMap) => {
                    console.log(i?.produto);
                  });
                console.log(carrinho?.__solicitado);
              } else {
                carrinho = null;
              } // else */

              this.#vm = {
                // carrinho: fixCarrinho(carrinho, loja),
                carrinho: !!carrinho ? this.#carrinhoServ.fix(carrinho) : null,
                entregaRetiradaCaption: !!carrinho?.__loja?.isRetirada ? 'retirada' : 'entrega', // !!lojaInfo?.retirada ,
                loja,
                // lojaInfo,
              };
              // console.log(this.#vm);
              return this.#vm;
            }
          )
        )
    );
  }
  //#endregion

  //#region functions
  tid_ite(index: any, item: IItemCarrinhoMap): string { return item?.id || ''; }
  //#endregion

  //#region methods
  onMenuCloseClick() {
    AppService.menuCarrinhoStatus$.next(false);
  }

  onProdutoClick(p: Partial<IProduto> = null) {
    p = p || this.itemSel()?.produto;
    // console.log(p);
    !!p && AppService.produtoDetalhes$.emit(
      {
        produto: this.#produtosServ.fix(p),
        showNav: false
      }
    );
  }

  onQtdeItemChanged(i: IItemCarrinhoMap, step: number) {
    // console.log(i?.__solicitado?.qtde);
    // const ITEM_ID: string = i?.id || '';
    const PRODUTO: IProduto = i?.produto || null;
    // console.log(PRODUTO);
    if (!!i && !!PRODUTO) {
      if (!!this.#contasStore.getState()) {
        const IS_MIN: boolean = !!i?.__solicitado?.qtde?.isMin;
        (!!IS_MIN && step < 0)
          ? this.onItemDelClick(i)
          : this.#appCarrinhoStore.setQtde(PRODUTO, step);
      } else {
        AppService.identificacao$.emit();
      } // else
    } // if
  }

  onItemDelClick(item: IItemCarrinhoMap = null) {
    // console.log(this.itemSel);
    const ITEM: IItemCarrinhoMap = this.itemSel() || item;
    if (!!ITEM) {
      const NOME_PRODUTO: string = ITEM?.produto?.nome || '';
      const QTDE_PRODUTO: number = Number(ITEM?.__solicitado?.qtde?.fixed) || 0;
      const DESCRICAO: string = `${QTDE_PRODUTO} x ${NOME_PRODUTO}`;

      const DIALOG_REF: any = this.#modal.open(
        CorMatConfirmDialog,
        {
          id: 'confirm-del-item',
          panelClass: 'no-border-dialog-container',
          data: {
            title: DESCRICAO,
            cancelCaption: 'Não',
            confirmCaption: 'Sim',
            msg: `Deseja remover esse produto ?`,
          }
        }
      );

      const SUB: Subscription = DIALOG_REF
        .afterClosed()
        .pipe(first(), finalize(() => SUB?.unsubscribe()))
        .pipe(filter((resp: boolean) => !!resp))
        .subscribe(
          (resp: boolean) => {
            // console.log(resp);
            if (!!resp) {
              const CARRINHO: ICarrinhoMap = {
                ...this.#vm?.carrinho,
                __solicitado: {
                  itens: (this.#vm?.carrinho?.__solicitado?.itens || [])
                    .filter((i: IItemCarrinhoMap) => ITEM?.id !== i?.id)
                }
              };
              this.#appCarrinhoStore.setState(CARRINHO);
              this.#snackBar.open(
                `${DESCRICAO} removido.`,
                ''
              );
            } // if
          });

    } // if
  }

  onLimparCarrinhoClick() {
    // console.log(this.#vm?.carrinho);
    const DIALOG_EXISTS = this.#modal.getDialogById('confirm-del-carrinho');
    const QTDE_ITENS: number = Number(this.#vm?.carrinho?.__solicitado?.qtde?.itens) || 0;
    const QTDE_VOLS: number = Number(this.#vm?.carrinho?.__solicitado?.qtde?.volumes) || 0;
    // console.log(QTDE_ITENS, QTDE_VOLS);
    if (!DIALOG_EXISTS) {
      const DESCRICAO: string = `${QTDE_ITENS} ${QTDE_ITENS === 1 ? 'item' : 'items'} e ${this.#vm.carrinho?.__solicitado?.qtde?.volumes} volume${QTDE_VOLS === 1 ? '' : 's'}.`;
      const DIALOG_REF: any = this.#modal.open(
        CorMatConfirmDialog,
        {
          id: 'confirm-del-carrinho',
          panelClass: 'no-border-dialog-container',
          data: {
            title: DESCRICAO,
            cancelCaption: 'Não',
            confirmCaption: 'Sim',
            msg: `Deseja realmente limpar esse carrinho ?`,
          }
        }
      );

      const SUB: Subscription = DIALOG_REF
        .afterClosed()
        .pipe(first(), finalize(() => SUB?.unsubscribe()))
        .pipe(filter((resp: boolean) => !!resp))
        .subscribe(
          async (resp: boolean) => {
            // console.log(resp);
            // console.log(resp);
            const CHANGES: ICarrinhoMap = {
              ...this.#vm?.carrinho,
              __solicitado: {
                itens: []
              },
            };
            // console.log(CHANGES);
            !!resp && this.#appCarrinhoStore.setState(CHANGES);
          });
    } // if
  }
  //#endregion
}
