<swal #loaderSwal
      title="Por favor, aguarde!"
      text="Buscando seus endereços."
      [showConfirmButton]="false"
      [showCancelButton]="false"
      (didOpen)="onSwalLoaderDidOpen()">
</swal>

<ng-container *ngIf="vm$() | async as vm">
  <div class="page">
    <section class="hero pb-3 d-flex flex-column">
      <section class="header py-5">
        <div class="container d-flex justify-content-start"
             [class.justify-content-center]="!!vm?.isMobile">
          <img src="assets/img/logo_mercadeiro.svg"
               class="header__logo img-fluid o-60"
               alt="">
        </div>
      </section>

      <div class="d-flex container p-4 m-auto"
           [class.flex-column-reverse]="vm?.isMobile">
        <div class="flex-1 d-flex flex-column">
          <div class="m-auto">
            <div class="no-border d-flex flex-column ms-2 m-auto w-100"
                 [class.pt-4]="!!vm?.isMobile">

              <div class="search-area no-border d-flex ms-2 m-auto w-100">
                <input type="text"
                       mask="00000-000"
                       [showMaskTyped]="false"
                       class="form-control cep flex-1 text-center rem-13"
                       [ngModel]="cep()"
                       (ngModelChange)="cep.set($event)"
                       [select]="cepFocusEvent$()"
                       placeholder="Qual seu CEP ?">

                <button mat-button
                        matTooltip="Limpa procura"
                        *ngIf="!!cep()"
                        (click)="onLimpaProcuraClick()">
                  <div class="icon-b-cancel me-2 o-70"></div>
                </button>

                <button mat-button
                        matTooltip="Consulta cep"
                        (click)="onConsultaCep(cep())"
                        *ngIf="(cep() || '').length === 8">
                  <div class="icon-r-search rem-12 me-2 o-70"></div>
                </button>
              </div>

              <!-- <input type="text"
                     mask="00000-000"
                     [showMaskTyped]="false"
                     class="form-control cep flex-1 text-center rem-13"
                     [ngModel]="cep()"
                     (ngModelChange)="cep.set($event)"
                     [select]="cepFocusEvent$()"
                     placeholder="Qual seu CEP ?"> -->

              <div class="mt-2 text-center">
                <a href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                   target="_blank"
                   class="btn btn-link-light o-70"
                   role="button"
                   aria-disabled="true">
                  NÃO SEI O CEP!
                </a>

                <section class="conta">
                  <div class="row mt-2">
                    <div class="col"
                         *ngIf="!conta; else logout">
                      <div class="d-flex flex-column">
                        <div>
                          Já tem conta?
                        </div>

                        <button mat-button
                                (click)="onEntrarClick()">
                          <span class="light tint o-70">
                            ENTRAR
                          </span>
                        </button>
                      </div>
                    </div>
                    <ng-template #logout>
                      <div class="col d-flex flex-column">
                        <div>
                          <span class="o-70">Olá,</span>&nbsp;<strong>{{ conta?.nome?.nome }}</strong>
                        </div>

                        <button mat-button
                                class="mt-2 sel-endereco-btn wiggle"
                                (click)="onSelecionarEnderecoClick()">
                          <span class="light tint o-70">
                            Selecionar endereço
                          </span>
                        </button>

                        <button mat-button
                                (click)="onLogoutClick()">
                          <span class="light tint o-70">
                            Sair
                          </span>
                        </button>
                      </div>
                    </ng-template>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>

        <div class="flex-2 text-center mb-4">
          <img src="assets/img/banner_mercadeiro.svg"
               class="hero__img"
               alt="">
        </div>
      </div>
    </section>

    <div class="container">
      <div class="d-flex flex-column">
        <div class="perspective-text m-auto">
          <div class="perspective-line">
            <p *ngIf="!vm?.isMobile"></p>
            <p>Sua loja online</p>
          </div>

          <div class="perspective-line">
            <p class="o-70"
               *ngIf="!vm?.isMobile">Sua loja online</p>
            <p class="o-70">descomplicada.</p>
          </div>

          <div class="perspective-line"
               *ngIf="!vm?.isMobile">
            <p>descomplicada.</p>
            <p></p>
          </div>
        </div>

        <section class="o-cards d-flex pb-5"
                 [class.justify-content-around]="!vm?.isMobile"
                 [class.flex-column]="!!vm?.isMobile">
          <div class="o-card d-flex flex-column"
               [class.mx-auto]="!!vm?.isMobile">
            <div class="icon icon-b-shopping-cart rem-50 o-30"></div>

            <h2 class="rem-14 o-60">COMERCIANTE</h2>

            <div class="flex-1 d-flex">
              <p class="m-auto rem-12">
                Você quer vender <strong>online</strong> integrando sua <strong>loja virtual</strong> com seu
                <strong>sistema
                  gerencial</strong> (ERP) atual?
              </p>
            </div>

            <button type="button"
                    class="btn btn-link p-3 mt-3">
              <div class="caption"
                   routerLink="/redirect"
                   [queryParams]="{ url: 'https://sobre.mercadeiro.com.br/comerciante' }">
                Quero saber mais...
              </div>
            </button>
          </div>

          <div class="o-card d-flex flex-column"
               [class.mx-auto]="!!vm?.isMobile"
               [class.my-4]="!!vm?.isMobile">
            <div class="icon icon-o-truck rem-50 o-30"></div>

            <h2 class="rem-14 o-60">DISTRIBUIDORA</h2>

            <div class="flex-1 d-flex">
              <p class="m-auto rem-12">
                Você quer criar uma <strong>loja online</strong> para <strong>complementar</strong> o trabalho de seus
                <strong>representantes</strong> permitindo apenas
                <strong>clientes</strong> devidamente <strong>credenciados</strong>?
              </p>
            </div>
          </div>

          <div class="o-card d-flex flex-column"
               [class.mx-auto]="!!vm?.isMobile">
            <div class="icon icon-b-terminal rem-50 o-30"></div>

            <h2 class="rem-14 o-60">DESENVOLVEDOR</h2>

            <div class="flex-1 d-flex">
              <p class="m-auto rem-12">
                Seus clientes querem <strong>vender online integrados</strong> com seu <strong>ERP</strong>?
              </p>
            </div>

            <button type="button"
                    class="btn btn-link p-3 mt-3">
              <div class="caption"
                   routerLink="/redirect"
                   [queryParams]="{ url: 'https://sobre.mercadeiro.com.br/desenvolvedor' }">
                Quero saber mais...
              </div>
            </button>
          </div>
        </section>
      </div>
    </div>
  </div>
</ng-container>