<div class="e404">
  <section class="ht p-relative d-flex">
    <mat-toolbar class="d-flex flex-column">
      <div class="m-auto">
        <img src="assets/img/logo_mercadeiro.svg"
             class="img-fluid">
      </div>
    </mat-toolbar>

    <span class="code text-center">404</span>
  </section>

  <section class="hb d-flex">
    <div class="m-auto text-center">
      <div class="msg o-50"> Página não encontrada !!!</div>

      <div class="banner px-4">
        <div class="url"> {{ url() }}</div>
      </div>

      <button mat-flat-button
              class="px-4 p-2 mt-5"
              (click)="onGotoHome()">
        <span class="icon-o-home me-2 o-50"></span> <span class="caption">Início</span>
      </button>
    </div>
  </section>
</div>

