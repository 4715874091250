//#region ng
import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes
} from '@angular/router';
//#endregion

//#region 3rd
import {
  AuthGuard,
  // canActivate,
  // redirectLoggedInTo,
  redirectUnauthorizedTo
} from '@angular/fire/auth-guard';
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo([HOME_ROUTE]);
// const redirectLoggedInToHome = () => redirectLoggedInTo([HOME_ROUTE]);
//#endregion

//#region models
import { HOME_ROUTE } from './models/consts';
import {
  AcessoNegadoPage,
  CarrinhoPendentePage,
  NotFoundPage
} from './pages';
const ROUTES: Routes = [
  {
    path: '',
    redirectTo: HOME_ROUTE,
    pathMatch: 'full'
  },
  {
    path: 'acessoNegado',
    // canActivate: [AuthGuard],
    // data: { authGuardPipe: redirectUnauthorizedToLogin },
    component: AcessoNegadoPage,
  },
  {
    path: 'carrinhoPendente',
    canActivate: [CarrinhoPendenteGuard],
    component: CarrinhoPendentePage,
  },
  {
    path: 'departamentos',
    canActivate: [CarrinhoLojaGuard],
    loadChildren: () => import('./pages/departamentos/departamentos.module').then(m => m.DepartamentosPageModule)
  },
  {
    path: 'lojas',
    canActivate: [LocalGuard],
    loadChildren: () => import('./pages/lojas/lojas.module').then(m => m.LojasPageModule)
  },
  {
    path: 'onde',
    // canActivate: [LojaGuardService],
    loadChildren: () => import('./pages/onde/onde.module').then(m => m.OndePageModule)
  },
  {
    path: 'pedido',
    canActivate: [
      CarrinhoLojaGuard,
      AuthGuard, // ContaGuard
    ],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () => import('./pages/pedido/pedido.module').then(m => m.PedidoPageModule)
  },
  {
    path: 'procura',
    canActivate: [CarrinhoLojaGuard],
    loadChildren: () => import('./pages/procura/procura.module').then(m => m.ProcuraPageModule)
  },
  {
    path: 'suaConta',
    // canActivate: [ContaGuard],
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () => import('./pages/sua-conta/sua-conta.module').then(m => m.SuaContaPageModule)
  },
  {
    path: 'loja/:id/:retirada',
    canActivate: [IdLojaGuard],
    loadChildren: () => import('./pages/loja/loja.module').then(m => m.LojaPageModule)
  },

  // Not Found page with external link redirection
  { path: 'not-found', component: NotFoundPage, canActivate: [CorRedirectService] },
  { path: '**', redirectTo: 'not-found' }
];
//#endregion

//#region services
import { CorRedirectService } from './_shared/_core/_ng/_services';
import {
  CarrinhoLojaGuard,
  CarrinhoPendenteGuard,
  IdLojaGuard,
  LocalGuard
} from './guards';
//#endregion

@NgModule({
  imports: [
    RouterModule.forRoot(
      ROUTES,
      {
        scrollPositionRestoration: 'disabled',
        useHash: true
      }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
