<ng-container *ngIf="vm$() | async as vm">
  <!-- <p>vm {{ vm | json }}</p> -->

  <div class="page"
       [class.no-footer]="!isMobile()">
    <div class="page-header">
      <mat-toolbar color="primary">
        <div class="container d-flex">
          <div class="icon-area my-auto">
            <button mat-icon-button
                    matTooltip="Mudar endereço"
                    (click)="onGotoOnde()">
              <div class="icon-r-chevron-l"></div>
            </button>
          </div>

          <div class="m-auto flex-1 text-center">
            <img src="assets/img/logo_mercadeiro.svg"
                 class="logo"
                 alt="">
          </div>
        </div>
      </mat-toolbar>
      <cor-mat-messages [isMain]="true" />
    </div>

    <div class="container page-content">
      <div class="row">
        <div class="col-12 col-sm-6 offset-sm-3"
             [class.mt-3]="!!isMobile()">
          <mat-form-field appearance="outline"
                          class="w-100">
            <button mat-button
                    matPrefix
                    mat-icon-button>
              <mat-icon class="o-50">search</mat-icon>
            </button>
            <mat-label>Qual loja você está procurando?</mat-label>
            <input matInput
                   type="text"
                   [focus]="searchFocusEvent$()"
                   [ngModel]="vm?.searchTerm"
                   (ngModelChange)="searchTermAction$()?.next($event)">
            <button mat-button
                    *ngIf="!!vm?.searchTerm"
                    matSuffix
                    mat-icon-button
                    (click)="searchTermAction$()?.next('')">
              <mat-icon class="o-50">close</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col">
          <div class="d-flex"
               [class.flex-column]="!!isMobile()"
               [class.justify-content-between]="!isMobile()">
            <div [class.flex-1]="!!isMobile()">
              <div class="segment d-flex"
                   *ngIf="!!vm?.locId && !!vm?.redeId">
                <ul class="nav nav-pills d-flex mx-auto">
                  <li class="nav-item flex-1"
                      *ngIf="!!vm?.locId">
                    <a class="nav-link text-center pointer"
                       [class.active]="vm?.origem === 'localidade'"
                       (click)="origemAction$().next('localidade')">
                      Localidade
                    </a>
                  </li>

                  <li class="nav-item flex-1"
                      *ngIf="!!vm?.redeId">
                    <a class="nav-link text-center pointer"
                       [class.active]="vm?.origem === 'rede'"
                       (click)="origemAction$().next('rede')">
                      Rede
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div [class.flex-1]="!!isMobile()">
              <select class="form-select"
                      [class.w-100]="!!isMobile()"
                      [class.mt-4]="!!isMobile()"
                      [ngModel]="vm?.entregaRetirada"
                      (ngModelChange)="entregaRetiradaAction$()?.next($event)"
                      name="entregaRetirada">
                <option value="ER">Entrega + Retirada</option>
                <option value="E">Entrega</option>
                <option value="R">Retirada</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div *ngIf="!!vm?.lojas; else carregandoLojas">
            <ul class="lojas mx-2">
              <li class="lojas__loja d-flex flex-column"
                  *ngFor="let l of vm?.lojas | lojasLfixes | async; trackBy:tid_loj">
                <div class="flex-1 d-flex">
                  <div class="lojas__loja__logo">
                    <img [src]="l?.rede?.__logo"
                         alt="">
                  </div>

                  <div class="lojas__loja__text flex-1">
                    <p>
                      <strong>
                        {{ l?.nome }}
                      </strong>
                    </p>

                    <p>
                      <small *ngIf="!!l?.endereco"
                             class="endereco">
                        <p class="o-50">
                          {{ l?.tipo?._nome }} &bullet; {{ l?.endereco?.bairro }}
                        </p>
                        <p>
                          {{ l?.endereco?.logradouro }}, {{ l?.endereco?.nro }} {{ l?.endereco?.complemento }}
                        </p>
                      </small>
                    </p>
                  </div>
                </div>

                <div class="d-flex footer">
                  <button mat-stroked-button
                          class="flex-1 me-1"
                          routerDirection="root"
                          [routerLink]="['/loja', l?.id, true]"
                          *ngIf="['ER', 'R'].includes(l?.entrega?.status)">
                    <span class="icon-o-shopping-basket o-30"></span>&nbsp;Retirada
                  </button>

                  <button mat-stroked-button
                          class="flex-1 ms-1"
                          routerDirection="root"
                          [routerLink]="['/loja', l?.id, false]"
                          [disabled]="!l?.entrega?.__atendido"
                          *ngIf="['ER', 'E'].includes(l?.entrega?.status)">
                    <div *ngIf="l?.entrega?.__atendido; else bairroNaoAtendido">
                      <span class="icon-o-truck o-30"></span>&nbsp;Entrega
                    </div>
                    <ng-template #bairroNaoAtendido>
                      Fora de rota
                    </ng-template>
                  </button>
                </div>
              </li>
            </ul>

            <div class="text-center"
                 *ngIf="!vm?.lojas?.length">
              <span class="icon-r-search warn-icon"></span>
              <br>
              <p>Nenhuma loja</p>
            </div>
          </div>
          <ng-template #carregandoLojas>
            <ul class="lojas">
              <li class="lojas__loja d-flex align-items-center"
                  *ngFor="let dummy of [].constructor(30)">
                <div class="logo"
                     style="padding-right: 20px;">
                  <ngx-skeleton-loader [theme]="{
                  'border-radius': '10px',
                  'height': '50px',
                  'width': '50px',
                  'background-color': 'whitesmoke'
                }">
                  </ngx-skeleton-loader>
                </div>
                <div class="text flex-1">
                  <p>
                    <ngx-skeleton-loader count="2"></ngx-skeleton-loader>
                  </p>
                </div>
              </li>
            </ul>
          </ng-template>
        </div>
      </div>
    </div>

    <div class="page-footer">
      <mat-toolbar color="primary">
        <div class="container">
          <div class="row">
            <div class="col pt-3 text-center d-flex justify-content-between">
              <div class="icon-area my-auto"></div>

              <small class="my-auto">
                <strong>{{ vm?.endereco?.bairro }}</strong>
                <br />
                {{ vm?.endereco?.logradouro }}, {{ vm?.endereco?.nro }} {{ vm?.endereco?.complemento }}
              </small>

              <div class="icon-area my-auto">
                <button mat-icon-button
                        matTooltip="Mudar endereço"
                        (click)="onGotoOnde()">
                  <div class="icon-b-edit"></div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </mat-toolbar>
    </div>
  </div>
</ng-container>