//#region ng
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
//#endregion

//#region mat
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
//#endregion

//#region modules
import { CorPipesModule } from '../../_shared/_core/_ng/_pipes/cor-pipes.module';
import { CorMatComponentsModule } from '../../_shared/_core/_ng-ui/_mat/cor-mat-components.module';
import { MeMatComponentsModule } from '../../_shared/_mercadeiro/_ng-ui/_mat/me-mat-components.module';
import { AppComponentsModule } from '../../components/components.module';
import { CorBsComponentsModule } from '../../_shared/_core/_ng-ui/_bs/cor-bs-components.module';
//#endregion

//#region components
import { AppCompletarCadastroModal } from '..';
//#endregion

@NgModule({
  imports: [
    AppComponentsModule,
    CommonModule,
    CorBsComponentsModule,
    CorMatComponentsModule,
    CorPipesModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatStepperModule,
    MatToolbarModule,
    MeMatComponentsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    AppCompletarCadastroModal,
  ],
  exports: [
    AppCompletarCadastroModal,
  ],
})
export class AppCompletarCadastroModalModule { }
